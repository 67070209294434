import { Component, OnInit,  Input,  Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { WidgetService } from '../../@core/backend/common/services/widget.service';

@Component({
  selector: 'ngx-widget-notifiche',
  templateUrl: './/widget-notifiche.component.html',
  styleUrls: ['./widget-notifiche.component.scss'],
})

export class WidgetNotificheComponent implements OnInit {

  listFormatted :any;
  number: number;
  class = '';

  constructor(private router: Router,  private widgetService: WidgetService) {

  }

  ngOnInit() {

   this.listFormatted = this.widgetService.getOpt();

  }

  returnZero() {
    return 0;
  }

  route(msg) {
    this.router.navigate([msg]);
  }


}
