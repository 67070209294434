import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';



@Injectable()
export class RelationsService {
    private readonly apiController: string = 'relations';
   
    constructor(private api: HttpService) { }

    delete(nameDomain: string = '', id: number): Observable<any> {
        nameDomain=nameDomain.replace(/"/g,'').replace("Map_","");
        return this.api.delete(`${this.apiController}/${nameDomain}/${id}`);
    }

    updatebatch(nameDomain: string = '',  data: any): Observable<any> {
        nameDomain=nameDomain.replace(/"/g,'').replace("Map_","");
        return this.api.post(`${this.apiController}/${nameDomain}/updatebatch`,data);
    }


    updateResponseChecklist(data: any): Observable<any> {

        return this.api.post(`${this.apiController}/updateResponseChecklist`,data);
    }
}
