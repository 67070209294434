import { CRS, icon, latLng, Map, MapOptions, marker, tileLayer } from 'leaflet';
import { fromEvent, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  Directive, Component, ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import L from 'leaflet';
import '@bepo65/leaflet.fullscreen';
import { GeocodeLeafletService } from '../../@core/backend/common/services/geocode.leaflet.service';
import { FieldType } from '@ngx-formly/core';



///////////////////////////////////////////////////////////////////////
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngxAppDelayedInput]',
})
export class DelayedInputDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() delayTime = 500;
  @Output() delayedInput = new EventEmitter<Event>();
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
  }

  ngOnInit() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        debounce(() => timer(this.delayTime)),
        distinctUntilChanged(
          null,
          (event: Event) => (event.target as HTMLInputElement).value,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => { console.log(e); this.delayedInput.emit(e) });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'ngx-map-field',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent  extends FieldType  implements OnInit {

  isCollapsed = true;
  map: any;
  MapOptions: MapOptions = {};
  lastLayer: any;
  searchResults = [];
  results: NominatimResponse[] = [];
  loading = false;
  _mapPoint = {
    name: '',
    latitude: 0,
    longitude: 0,
    epsg: "4326",
    address: null
  };
  indirizzoSelezionato: any;
  get mapPoint(): any {
    return this._mapPoint;
  }
  @Input() set mapPoint(value: any) {
    if (value && value.latitude && value.longitude) {
      this.updateMapPoint(value.latitude, value.longitude, value.name);
    }
  }
  @ViewChild(NgSelectComponent, { static: true }) ngSelectComponent: NgSelectComponent;
  @Output() locationSelected = new EventEmitter();
  @ViewChild('mapWrapper', { static: true }) mapWrapper: ElementRef;



  constructor(public geocedeService: GeocodeLeafletService) {
    super();
   }

  ngOnInit() {
    this.initializeMapOptions();
  }



  initializeMap(map: Map) {
    L.control.fullscreen({
      position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
      title: 'Visualizza schermo intero', // change the title of the button, default Full Screen
      titleCancel: 'Esci da schermo intero', // change the title of the button when fullscreen is on, default Exit Full Screen
      fullscreenElement: this.mapWrapper.nativeElement
    }).addTo(map);
    this.map = map;

    const mapIcon = this.getDefaultIcon();
    this.map.on('click', e => {
      this._mapPoint.latitude = e.latlng.lat;
      this._mapPoint.longitude = e.latlng.lng;
      this.createMarker();
      this.searchResults = [];
      this.geocedeService.reverseLookup(this._mapPoint.latitude, this._mapPoint.longitude).subscribe(res => {
        let correctAddress = this.normalizzaIndirizzo(res.address);

        let nameDisplay = correctAddress.Strada + (correctAddress.Civico ? ', ' + correctAddress.Civico : '') +
          (correctAddress.Cap ? ' - ' + correctAddress.Cap : '') +
          (correctAddress.Citta ? ' ' + correctAddress.Citta : '') + (correctAddress.Provincia ? ' (' + correctAddress.Provincia + ')' : '')

        this.updateMapPoint(this._mapPoint.latitude, this._mapPoint.longitude, nameDisplay, "4326", correctAddress);

      });

    });

    if (this._mapPoint.latitude && this._mapPoint.longitude) {
      this.createMarker();
    }

  }


  private initializeMapOptions() {
    this.MapOptions = {
      zoom: 8,
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'OSM' })
      ],
      crs: CRS.EPSG3857,
      center: latLng(this._mapPoint.latitude, this._mapPoint.longitude),

    };
  }


  addressLookup(search: any) {
    this.searchResults = [];
    this.isCollapsed = true;
    const address = search.value;
    if (address.length > 2) {
      this.geocedeService.addressLookup(address).subscribe(results => {
        Object.keys(results).forEach((key) => {
          this.searchResults.push(results[key]);
          this.isCollapsed = false;
        });
        //  this.searchResults = results;
        console.log(this.searchResults);
      });
    } else {
      this.searchResults = [];
    }
  }


  private normalizzaIndirizzo(address) {
    let obj = {
      Civico: address.house_number,
      Cap: address.postcode,
      Citta: address.municipality || address.city || address.town || address.village,
      Strada: address.road,
      Provincia: address.county,
      Regione: address.state,
      Paese: address.country
    }
    if (!obj.Strada) obj.Strada = obj.Citta;
    return obj

  }


  private updateMapPoint(latitude: number, longitude: number, name?: string, epsg?: string, address?: any) {

    this._mapPoint = {
      latitude: latitude,
      longitude: longitude,
      name: name ? name : '',
      epsg: epsg ? epsg : "4326",
      address: address
    };

    if (!this._mapPoint.name) {
      this.geocedeService.reverseLookup(this._mapPoint.latitude, this._mapPoint.longitude).subscribe(res => {
        let correctAddress = this.normalizzaIndirizzo(res.address);
        let nameDisplay = correctAddress.Strada + (correctAddress.Civico ? ', ' + correctAddress.Civico : '') +
          (correctAddress.Cap ? ' - ' + correctAddress.Cap : '') +
          (correctAddress.Citta ? ' ' + correctAddress.Citta : '') + (correctAddress.Provincia ? ' (' + correctAddress.Provincia + ')' : '')
        this._mapPoint.name = nameDisplay;
        this._mapPoint.address = correctAddress;
        this.locationSelected.emit(this._mapPoint);
      })
    } else {
      this.locationSelected.emit(this._mapPoint);
    }
  }


  private createMarker() {
    this.clearMap();
    const mapIcon = this.getDefaultIcon();
    const coordinates = latLng([this._mapPoint.latitude, this._mapPoint.longitude]);
    this.lastLayer = marker(coordinates).setIcon(mapIcon).addTo(this.map);
    this.map.setView(coordinates, 15);
  }


  private getDefaultIcon() {
    return icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: './node_modules/leaflet/dist/images/marker-icon.png',
      shadowUrl: './node_modules/leaflet/dist/images/marker-shadow.png'
    });
  }


  private clearMap() {
    if (this.map.hasLayer(this.lastLayer)) this.map.removeLayer(this.lastLayer);
  }


  searchEvent(e) {
    this.loading = true;
    this.isCollapsed = true;
    const address = e;
    if (address.length > 2) {
      this.geocedeService.addressLookup(address).subscribe(results => {
        this.searchResults = [];
        this.loading = false;
        Object.keys(results).forEach((key) => {
          this.searchResults.push(results[key]);
        });
        //  this.searchResults = results;
        console.log(this.searchResults);
      });
    } else {
      this.searchResults = [];
      this.loading = false;
    }
  }


  clearAddress() {
    this.searchResults = [];
  }


  onChange() {
    if (this.indirizzoSelezionato) {
      let correctAddress = this.normalizzaIndirizzo(this.indirizzoSelezionato.address);
      this.indirizzoSelezionato.address = correctAddress;
      let nameDisplay = correctAddress.Strada + (correctAddress.Civico ? ', ' + correctAddress.Civico : '') +
        (correctAddress.Cap ? ' - ' + correctAddress.Cap : '') +
        (correctAddress.Citta ? ' ' + correctAddress.Citta : '') + (correctAddress.Provincia ? ' (' + correctAddress.Provincia + ')' : '')

      this.updateMapPoint(this.indirizzoSelezionato.lat, this.indirizzoSelezionato.lon, nameDisplay, "4326", this.indirizzoSelezionato.address);
      this.createMarker();

      this.indirizzoSelezionato = null;
      // this.ngSelectComponent.handleClearClick();
    }

  }


}

export class NominatimResponse {
  constructor(
    public latitude: number,
    public longitude: number,
    public displayName: string
  ) { }
}
