import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';
import { AppSettingsService } from './appsettings.service';

@Injectable()
export class TaskNifiService {
    private readonly apiController: string = 'import';
    
    constructor(private api: HttpService, private appSettingsService: AppSettingsService) {
        
    }
    get(id: number): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    delete(id: number): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    post(item: any): Observable<any> {
        return this.api.post(`${this.apiController}`, item);
    }

    put(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    getDataSource() {
        return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    }


}
