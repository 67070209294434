import { FormClassComponent } from './../../../@components/form-class/form-class.component';
import { ModalDetailComponent } from './modal-detail/modal-detail.component';
import { ListComponent } from './list/list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegnalazioniRoutingModule } from './segnalazioni-routing.module';
import { SegnalazioniComponent } from './segnalazioni.component';
import { NbCardModule, NbDialogService, NbSpinnerModule } from '@nebular/theme';
import { DxDataGridModule } from 'devextreme-angular';
import {
  DxTabPanelModule,
  DxCheckBoxModule,
  DxTemplateModule,
  DxFormModule,
  DxTextAreaModule,
  DxRadioGroupModule
} from 'devextreme-angular';
import { ComponentsModule } from '../../../@components/components.module';
import { StatisticheModule } from './statistiche/statistiche.module';



@NgModule({
  declarations: [SegnalazioniComponent, ListComponent, ModalDetailComponent],
  imports: [
    CommonModule,
    SegnalazioniRoutingModule,
    NbCardModule,
    DxDataGridModule,
    NbSpinnerModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxTemplateModule,
    DxFormModule,
    DxTextAreaModule,
    DxRadioGroupModule,
    ComponentsModule,
    StatisticheModule
  ],
  providers: [
    NbDialogService,
  ],
  exports: [StatisticheModule]
})
export class SegnalazioniModule { }
