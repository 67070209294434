import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-empty-layout',
  styleUrls: ['./empty.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-column  [ngStyle]="{'background':background}" >
        <ng-content style="height:100%"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class EmptyLayoutComponent {
  @Input() background: any;
}
