import { Injectable } from '@angular/core';
import { NbAuthService, NbAuthOAuth2JWTToken } from '@nebular/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppSettingsService } from '../@core/backend/common/services/appsettings.service';
import { UsersService } from '../@core/backend/common/services/users.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private appSettingService: AppSettingsService,
    private userService:UsersService,
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('AUTH GUARD');


    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            const url: string = state.url;
            this.appSettingService.setUrl(url);
            this.router.navigate(['auth/login']);
          } else {
            this.authService.getToken()
              .subscribe((token: NbAuthOAuth2JWTToken) => {
                if (!token.isValid()) this.router.navigate(['auth/login']);
                const payload = token.getAccessTokenPayload();
                if (!payload['role']) this.router.navigate(['auth/login']);
                console.log("AUTH=",payload)

                if (payload['role']=='fornitore') {
                  this.router.navigate(['fornitori']);
                }

              });
          }
        }),
      );

  }
}
