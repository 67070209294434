import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from '../../../../../@core/backend/common/api/http.service';
import { GenericClassService } from '../../../../../@core/backend/common/services/genericClass.service';

@Component({
  selector: 'ngx-widget-statistiche-settori',
  templateUrl: './widget-statistiche-settori.component.html',
  styleUrls: ['./widget-statistiche-settori.component.scss']
})
export class WidgetStatisticheSettoriComponent implements OnInit {
  filter:any = [];
  dataSource:any;
  store:any;
  constructor(private classService:GenericClassService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.store=this.classService.getDataSourceDevExpFromView("Segnalazioni","vw_widget-settori-statistiche",this.filter,false);
    this.store.on("loaded",this.loaded.bind(this));
    this.store.load();
  }

  loaded(res)
  {
    res.data.forEach((element,i) => {
      element.Id = i;
    });
    this.dataSource=res.data;
    this.spinner.hide();
  }

}
