import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `<span><i class="far fa-copyright"></i> {{currentYear}}</span>`,
})
export class FooterComponent {
  get currentYear(): number {
    return new Date().getFullYear();
  }
}
