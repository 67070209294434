import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NbAccessChecker, NbRoleProvider } from '@nebular/security';
import { ROLES } from './roles';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private roleProvider: NbRoleProvider,private accessChecker: NbAccessChecker,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.roleProvider.getRole()
      .pipe(map(role => {
        const roles = role instanceof Array ? role : [role];
        console.log(roles);
        const isAdmin= roles.some(x => x && x.toLowerCase() === ROLES.ADMIN);

        if (isAdmin) {
          return true
        } else {
          // Controllo permessi speciali
          if (this.accessChecker.isGranted("view","module_mappe")) return true;
          if (this.accessChecker.isGranted("view","module_schedeVerifica")) return true;
          return false
        }
      }));
  }
}
