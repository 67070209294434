import { NbAuthOAuth2JWTToken } from '@nebular/auth';


export const socialLinks = [
  {
    url: 'https://github.com/xxxx/xxxxx',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/xxxx/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/xxxx',
    target: '_blank',
    icon: 'twitter',
  },
];

export const authStrategy = {
  name: 'email',
  baseEndpoint: '',
  token: {
    class: NbAuthOAuth2JWTToken,
    key: 'token',
  },
  login: {
    endpoint: '/auth/login',
    method: 'post',
    redirect: {
      success: '/pages',
      failure: null, // stay on the same page
    },
    defaultErrors: ['Accesso non autorizzato, riprovare.'],
   defaultMessages: ['Accesso effettuato con successo.'],
  },
  register: {
    endpoint: '/auth/sign-up',
    method: 'post',

  },
  logout: {
    endpoint: '/auth/sign-out',
    method: 'post',

  },
  requestPass: {
    endpoint: '/auth/request-pass',
    method: 'post',
  },
  resetPass: {
    endpoint: '/auth/reset-pass',
    method: 'post',
  },
  refreshToken: {
    endpoint: '/auth/refresh-token',
    method: 'post',
  },
};

