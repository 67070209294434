import { GenericClassService } from './../../@core/backend/common/services/genericClass.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetService } from '../../@core/backend/common/services/widget.service';

interface DrillInfo {
  text: string;
  node?: any;
}

@Component({
  selector: 'ngx-widget-ubicazioni',
  templateUrl: './widget-ubicazioni.component.html',
  styleUrls: ['./widget-ubicazioni.component.scss'],
})
export class WidgetUbicazioniComponent implements OnInit, OnChanges {
  @Input() maxlevel: number = 300;
  @Input() tipo: string = '';
  nodes: any = [];
  nodesDrill: any = [];
  drillInfos: DrillInfo[] = [];
  @Input() className: string;
  @Input() listClassAlias: any[] = [];
  tipoOrizzontale: boolean = true;
  constructor(private widgetService: WidgetService, private classService: GenericClassService) {}
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {
    this.tipoOrizzontale = true;
    if (this.className) {
      const store = this.classService.getDataSourceDevExp(
        this.className,
        null,
        true,
      );
      store.load().then((data) => {
        this.nodes = data;
        const nodeFirst = this.changeChildren(data, this.maxlevel);

        this.nodes = [nodeFirst];
      });
    } else this.loadData();
  }

  loadData() {
    this.widgetService.getUbicazioniTree().subscribe((res) => {
      const nodeFirst = this.changeChildren(res, this.maxlevel);

      this.nodes = [nodeFirst];
      this.nodesDrill = nodeFirst.childs;
      while (this.nodesDrill && this.nodesDrill.length == 1) {
        this.nodesDrill = this.nodesDrill[0].childs;
      }
      this.nodesDrill = this.nodesDrill.map(x => {
        x.idParent = 0;
        return x;
      });
    });
  }

  changeChildren(node, maxlevel) {
    if (node.level > maxlevel) return;
    const newNode = node.value;
    newNode.childs = node.children;

    if (!newNode.name) newNode.name = newNode.description;
    if (!newNode.title) newNode.title = newNode.other && newNode.other._Tipo_Description ? newNode.other._Tipo_Description : (this.listClassAlias[newNode.other.IdClass] ? this.listClassAlias[newNode.other.IdClass].alias : newNode.other.IdClass);
    if (newNode.other && newNode.other._Tipo_TextColor) {
      newNode.cssClass = newNode.other._Tipo_TextColor;
      newNode.cssClass = newNode.cssClass.replace('#', 'bgcolor-');
    } else if (newNode.other && this.listClassAlias[newNode.other.IdClass]) {
      newNode.cssClass = 'bgcolor-' + this.listClassAlias[newNode.other.IdClass].cssClass;
    }
    delete newNode.value;
    delete newNode.children;
    newNode.value = 10;
    newNode.childs = newNode.childs.map((x) => {
      return this.changeChildren(x, maxlevel);
    });
    newNode.items = newNode.childs;
    if (newNode.childs && newNode.childs.length > 0 && !newNode.childs[0]) {
      delete newNode.childs;
      delete newNode.items;
    }

    return newNode;
  }

  nodeClick(e) {
    e.node.drillDown();
  }

  drill(e) {
    this.drillInfos = [];
    for (let node = e.node.getParent(); node; node = node.getParent()) {
      this.drillInfos.unshift({
        text: node.label() || 'Tutte',
        node: node,
      });
    }
    if (this.drillInfos.length) {
      this.drillInfos.push({
        text: e.node.label(),
      });
    }
  }
  drillInfoClick(node) {
    if (node) {
      node.drillDown();
    }
  }
}
