/// <reference types='leaflet-sidebar-v2' />
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { tileLayer, latLng, Map, geoJSON, icon, marker, CRS, point, FeatureGroup } from 'leaflet'

import draw_it from './draw-locale-it';
import * as L from 'leaflet';
import cloneLayer from 'leaflet-clonelayer';

import '../../../alter_npm_modules/Leaflet.Coordinates-0.1.5.min.js'

import '@bepo65/leaflet.fullscreen';
import '../../../../node_modules/leaflet-search/dist/leaflet-search.min.js';

import { AppSettingsService } from '../../@core/backend/common/services/appsettings.service'
import { GisService } from '../../@core/backend/common/services/gis.service'
import { NgxSpinnerService } from 'ngx-spinner';

import data from "devextreme/data/array_store";
import { NgxSidebarControlComponent } from '@runette/ngx-leaflet-sidebar';
import { DxRadioGroupComponent } from 'devextreme-angular';

@Component({
  selector: 'ngx-map-class',
  templateUrl: './map-class.component.html',
  styleUrls: ['./map-class.component.scss']
})
export class MapClassComponent implements OnInit, OnChanges {

  loading: boolean = false;

  @Input() className: string = ''
  @Input() searchFeatureId: any;
  @Input() editFeatureId: any;
  @Input() mode: string = "VIEW";
  subModeEdit:string='';
  selectFeatureId = null;
  // Define our base layers so we can reference them multiple times
  streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  })
  wMaps = tileLayer('http://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  })
  highlightStyle = {
    weight: 1,
    color: '#888',
    fillColor: '#f90',
    dashArray: '',
    fillOpacity: 1,
    radius: 10
  };
  drawLocal: any = draw_it;

  layers: any = []
  layersControl: any;
  layersControlOptions: any;
  options: any;

  // FORM BINDING
  zoom = 15
  center = latLng([0, 0])

  settings: any
  activeLayer: any[];
  map: Map;

  sidebarOptions: L.SidebarOptions;

  drawItems: FeatureGroup
  drawOptions: any;
  drawControl: any;
  enableEdit: boolean = false;
  @Input() card: any;
  @ViewChild('mapWrapper', { static: true }) mapWrapper: ElementRef;
  @ViewChild(NgxSidebarControlComponent, { static: false }) sidebarComponent: NgxSidebarControlComponent;
  geometryTypes: any = [];
  selectGeometryTypes:any=undefined;
  @ViewChild(DxRadioGroupComponent, { static: false }) radioGeometryTypes: DxRadioGroupComponent;
  currentEditId: any;
  dirty:boolean=false;


  constructor(
    protected appSettingsService: AppSettingsService,
    protected gisService: GisService,
    protected spinner: NgxSpinnerService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.drawOptions) {
      this.enableEdit = false
    }

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'searchFeatureId': {
            this.searchByFeatureID(changes[propName].currentValue)
            break;
          }
          case 'editFeatureId': {
            this.editByFeatureID(changes[propName].currentValue)
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.init();
  }

  getOptions(activeLayer = []) {
    return {
      layers: [this.streetMaps, ...activeLayer],
      zoom: this.zoom,
      center: latLng(this.center),
      cursor: true,

    }
  }
  getLayerControls(layers) {
    return {
      baseLayers: {
        'Street Maps': this.streetMaps,
        'Wikimedia Maps': this.wMaps
      },
      overlays: layers,

      options: {
        position: 'topleft'
      }
    }
  }
  init() {
    this.loading = true;
    this.spinner.show();
    // Localizzazione plugin draw
    L.drawLocal.draw = this.drawLocal.draw;
    L.drawLocal.edit = this.drawLocal.edit;


    // Configurazione plugin draw
    this.drawItems = new FeatureGroup();
    this.drawOptions = {
      draw: false,
      edit: {
        edit: false,
        remove: false,
        featureGroup: this.drawItems
      }
    };

    // configurazione leaflet
    this.options = null;
    this.geometryTypes = [];


    this.layersControlOptions = { 'position': 'topleft' }
    this.appSettingsService.getSettings()
      .subscribe(settings => {
        this.settings = settings

        this.gisService.getAttributeList(this.className).subscribe((res) => {
          // Creazione GEOJson
          this.activeLayer = [];
          res.data.forEach(layer => {
            // STILE feature
            let myStyle = JSON.parse(layer.layer.MapStyle);
            myStyle = Object.assign({}, myStyle, {
              'color': myStyle.strokeColor,
              'weight': myStyle.strokeWidth,
              'radius': myStyle.pointRadius,
              'opacity': myStyle.strokeOpacity
            });

            let resData = layer.data
            const code = layer.layer.Code;
            const group = layer.layer.Description;
            let features = resData.map(element => {
              const properties = JSON.parse(element.properties);
              properties.code = code;
              properties.group = group;
              properties.style = myStyle;
              let objGeoJSON = {
                type: "Feature",
                properties: properties,
                geometry: JSON.parse(element.geometry)
              }
              return objGeoJSON
            });

            // CREAZIONE LAYER
            this.layers[layer.layer.Description] = geoJSON(features, {

              coordsToLatLng: function (coords) {
                var mypoint = point(coords[0], coords[1]);
                var latLng = CRS.EPSG900913.unproject(mypoint);
                return latLng;
              },
              pointToLayer: function (feature, latlng) {
                // TO DO: gestire icone custom invece di cerchi
                return L.circleMarker(latlng);
              },
              onEachFeature: this.onEachFeature,
            });

            if (layer.layer.owner == `\"${this.className}\"`) {
              this.geometryTypes.push(layer.layer);

              this.activeLayer.push(this.layers[layer.layer.Description]);
            }

          });

          this.options = this.getOptions(this.activeLayer);
          this.layersControl = this.getLayerControls(this.layers);

        })

      });
    this.sidebarOptions = {
      position: 'right',
      autopan: true,
      closeButton: true,
      container: 'sidebar',
    };

  }
  onEachFeature(feature, layer) {
    layer.setStyle(feature.properties.style);

    layer.bindPopup('<span class="badge badge-primary" style="color: #fff;background-color:' + layer.options.fillColor + ';">' + feature.properties.group + '</span><br><b>' + (feature.properties.Description ? feature.properties.Description : '') + '</b>')
  }
  onMapReady(map: Map) {

    //plugin coordinates
    L.control.coordinates({
      enableUserInput: false,
      position: "bottomleft",
      useLatLngOrder: true,
    }).addTo(map);

    map.on('overlayadd', onOverlayAdd);
    map.on('overlayremove', onOverlayRemove);

    function onOverlayAdd(e) {
      console.log("AGGIUNTO LAYER:", e);
    }
    function onOverlayRemove(e) {
      console.log("RIMOSSO LAYER:", e);
    }
    map.on(L.Draw.Event.CREATED, (e) => {
      e.layer.setStyle(this.highlightStyle);
      this.map.addLayer(e.layer);
    });
    map.addLayer(this.drawItems);
    setTimeout(() => {
      this.zoom = parseInt(this.settings.mapZoomInitial)
      this.center = latLng(
        parseFloat(this.settings.mapCenterInitialLat),
        parseFloat(this.settings.mapCenterInitialLng)
      )
      // create a fullscreen button and add it to the map
      L.control.fullscreen({
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
        title: 'Visualizza schermo intero', // change the title of the button, default Full Screen
        titleCancel: 'Esci da schermo intero', // change the title of the button when fullscreen is on, default Exit Full Screen
        fullscreenElement: this.mapWrapper.nativeElement
      }).addTo(map);
      this.map = map;

      this.loading = false;
      this.spinner.hide();


    })


  }

  onDrawReady(drawControl) {
    this.drawControl = drawControl;
  }
  onDrawCreated(e) {
    this.dirty=true;
  }
  onDrawEdited(e) {
    console.log(e);
    this.dirty=true;
  }
  onDrawDeleted(e) {
    var layers = e.layers;

    layers.eachLayer(function (feature) {
      this.map.eachLayer(function (mapLayer) {
        if (mapLayer.hasLayer) {
          mapLayer.removeLayer(feature);
        }
      });
    }.bind(this));
    this.dirty=true;
  }
  onDrawStart(e){
    if (this.drawItems.getLayers().length>0) {
      this.drawControl._toolbars['draw'].disable()
    }
  }

  searchByFeatureID(searchValue, update = true) {
    if (!searchValue) return;

    if (false && this.dirty) {
      bootbox.confirm("Modifiche non salvate ! ATTENZIONE: le modifiche non salvate saranno perse.",(result)=>{
        if (!result) {
          return false;
        } else {
          this.searchByFeatureID_do(searchValue,update);
          return true;
        }
      })
    } else {
      this.searchByFeatureID_do(searchValue,update);
      return true;
    }


  }
  searchByFeatureID_do(searchValue, update = true) {
    this.mode = null;
    let foundLayer = L.featureGroup();
    Object.keys(this.layers).forEach(key => {
      const lay = this.layers[key];
      lay.getLayers().forEach(element => {
        element.setStyle(element.feature.properties.style);
        if (element.feature.properties.Id === searchValue) {

          element.setStyle(this.highlightStyle);
          this.selectFeatureId = searchValue;
          foundLayer.addLayer(element);
        }
      });
    });
    if (foundLayer.getLayers().length > 0) {
      this.fitBound(foundLayer);
    }
    this.sidebarComponent.sidebar.close();
    if (update) {

      this.mode = "VIEW";
    }
  }
  editByFeatureID(editValue) {
    if (!editValue) return;

    if (! this.searchByFeatureID(editValue, false)) {
      return
    };
    this.mode = null;
    this.currentEditId=editValue;
    this.dirty=false;
    this.geometryTypes.forEach(element => {
      element.layer = null;
    });
    Object.keys(this.layers).forEach(key => {
      const lay = this.layers[key];
      lay.getLayers().forEach(element => {
        if (element.feature.properties.Id === editValue) {
          const layerCode = element.feature.properties.code;
          const geometryType = this.geometryTypes.find(x => x.Code == layerCode);

          if (geometryType) {
            geometryType.layer = element;
          }

        }

      });
    });
    setTimeout(() => {
      this.mode = "EDIT";
      if (this.radioGeometryTypes && this.radioGeometryTypes.instance) {
        this.radioGeometryTypes.instance.reset();
        if (this.geometryTypes && this.geometryTypes.length==1) {
          this.radioGeometryTypes.value=this.geometryTypes[0]
        }

      }
    });

  }
  resetAllStyle() {
    this.selectFeatureId = null;
    Object.keys(this.layers).forEach(key => {
      const lay = this.layers[key];
      lay.getLayers().forEach(element => {
        element.setStyle(element.feature.properties.style);
      });
    });
  }

  fitBound(group: FeatureGroup) {
    this.map.fitBounds(group.getBounds());
  }
  onGeometryTypeChanged(e) {
    console.log("CHANGE GEOMETRIA");
    const data = e.value;
   // this.resetAllStyle();
    this.enableEdit = false;


    if (!data) return;
     // azzero precedenti
     if (this.drawItems.getLayers().length > 0) {

      this.drawItems.eachLayer(
        (l) => {
          this.drawItems.removeLayer(l);
        });
    }
    //this.drawItems.setStyle(this.highlightStyle);
    if (data.layer) {

      //const cloned=cloneLayer(data.layer);
      this.drawItems.addLayer(data.layer);
    } else {
      // layer non presente modalità inserimento


      }
    setTimeout(() => {


      this.drawOptions.draw = {
        polyline:this.selectGeometryTypes.Type==='LINE'?true:false,
        polygon: this.selectGeometryTypes.Type==='POLYGON'?true:false,
        rectangle:false,
        circle:false,
        marker:false,
        circlemarker:this.selectGeometryTypes.Type==='POINT'?true:false
      };

      this.drawOptions.edit.edit = true;
      this.drawOptions.edit.remove = true;
      this.enableEdit = true;
    });
  }
}
