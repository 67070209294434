

import { Component } from '@angular/core';

@Component({
  selector: 'ngx-segnalazioni',
  template: `<router-outlet></router-outlet>`,
})
export class SegnalazioniComponent {
}
