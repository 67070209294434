import { ServerDataSource } from 'ng2-smart-table';
import { HttpParams } from '@angular/common/http';

export class CustomServerDataSource extends ServerDataSource {
  protected addFilterRequestParams(httpParams: HttpParams): HttpParams {

    if (this.filterConf.filters) {
      console.log("FILTER:***********",this.filterConf)
      this.filterConf.filters.forEach((fieldConf: any) => {
        if (!fieldConf['operator']) fieldConf['operator']="search";
        if (fieldConf['search']) {
         
          let filter = this.conf.filterFieldKey
            .replace('#field#', fieldConf['field'])
            .replace('#operator#', fieldConf['operator'])
            .replace('#search#', fieldConf['search']);
          httpParams = httpParams.append("filter", filter);
        
        } else if (fieldConf['operator'] == "null" || fieldConf['operator'] == "not_null") {
          let filter = this.conf.filterFieldKey
            .replace('#field#', fieldConf['field'])
            .replace('#operator#', fieldConf['operator'])
            .replace('#search#', '');
          httpParams = httpParams.append("filter", filter);
         
        }
      });
    }


    return httpParams;
  }

  protected resetFilterGeneric() {
    if (this.filterConf.filters) {

      this.filterConf.filters = this.filterConf.filters.filter(function (obj) {
        return obj.field !== '_generic_';
      });
      this.emitOnChanged('filter');
    }

  }
  protected resetFilterSpecific() {
    if (this.filterConf.filters) {

      this.filterConf.filters = this.filterConf.filters.filter(function (obj) {
        return obj.field === '_generic_';
      });
      this.emitOnChanged('filter');
    }

  }
  protected resetFilter() {
    this.filterConf.filters = [];
    this.emitOnChanged('filter');
  }
}