import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';
import { EmailRequest } from '../../../interfaces/common/emailRequest';

@Injectable()
export class EmailService {
    private readonly apiController: string = 'emailTemplate';


    constructor(private api: HttpService) { }

    createFromTemplate(obj: EmailRequest): Observable<any> {

        return this.api.post(`${this.apiController}/create`, obj);
    }



}
