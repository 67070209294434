import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { FieldType } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { GenericClassService } from '../@core/backend/common/services/genericClass.service';
import { ModalImageComponent } from './modal-image/modal-image.component';

@Component({
  selector: 'formly-field-previewallegati',
  template: `
  <div class="form-group" [class.has-error]="showError">
    <ngx-spinner size="small" bdColor="rgba(0, 0, 0, 0)" color="#f00808" type="ball-atom"
        [fullScreen]="false">
        <p style="color: black;"> Caricamento anteprima allegati... </p>
      </ngx-spinner>
    <div *ngIf="imagesBlob.length > 0 && !error" class="row">
        <div  *ngFor="let img of imagesBlob" (click)="viewImage(img)" class="thumbnail col-3" >
            <img [src]="img" title="Fai click per visualizzare l'immagine"  alt="Allegato all'asset">
        </div>
    </div>
    <div *ngIf="error"></div>
    <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </div>
   `,
   styles: [`.thumbnail {
    display: block;
    overflow: hidden;
    height: 100px;
    width: 100px;
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #DEE2E6;
    border-radius: 0.25rem;
  }
  .thumbnail img {
   display: block;
   object-fit: contain;
   margin:auto;
   max-height:100%;
  }`]
})

// tslint:disable-next-line: component-class-suffix
export class FormlyFieldPreviewAllegati extends FieldType implements OnInit {
    fileKeys = [];
    requestArray=[];
    imagesBlob=[];
    error = false;
  constructor(private classService:GenericClassService,protected spinner: NgxSpinnerService,private dialogService: NbDialogService,) {
    super();
  }

    ngOnInit(): void {
        this.spinner.show()
       var idCard = this.model.Id;
        this.classService.getAllegati(this.model.IdClass,idCard).subscribe((resAllegati:any)=>{
            resAllegati.data.forEach(allegato => {

                if(allegato.Key) {
                    var isImage=allegato.Mimetype.indexOf('image/')!=-1;
                    if (isImage) this.fileKeys.push(allegato.Key)
                }
            });
            console.log(this.fileKeys)
            this.fileKeys.forEach(key => {
                this.requestArray.push(this.classService.downloadAllegato(key));
            });
            forkJoin(this.requestArray).subscribe((resList:any)=>{
                resList.forEach(img => {
                    var reader = new FileReader();
                    reader.readAsDataURL(img); 
                    reader.onloadend = function() {
                    this.imagesBlob.push(reader.result);                
                    }.bind(this)
                });
                this.spinner.hide();
            },(error)=>{
                this.error = true;
            });
        },(error)=>{
            this.error = true;
        });
    }
    viewImage(img) {
        this.dialogService.open(ModalImageComponent, {
            context: {
              id: 0,
              titleModal: "Anteprima allegato",
              src: img
            },
          }).onClose.subscribe(res => {
          });
      
    }
}
