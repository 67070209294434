import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';


@Injectable()
export class AuthService {

    private readonly apiController: string = 'auth';
    private readonly paramUrl: string = 'reset-pass';

    constructor(private api: HttpService) {

    }

    login(data): Observable<any> {
      return this.api.post(`${this.apiController}/login`, data);
    }

    resetPass(data: any): Observable<any> {
        return this.api.post(`${this.apiController}/${this.paramUrl}`, data);
    }
    setup2FA(): Observable<any> {
      return this.api.get(`users/setup2FA`);
    }





}
