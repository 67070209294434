export class EnvService {
  // API url
  public apiUrl = 'http://localhost:5200/api'; // default value if env.js is not loaded
  public jsReportUrl = 'http://localhost:5844/api'; // default value if env.js is not loaded
  public jsReportAccount = ''; // default value if env.js is not loaded
  public defaultLanguage = 'none'; // default value if env.js is not loaded
  public conductorUrl = 'http://localhost:5000/api/'; // default value if env.js is not loaded
  public idVerificaSchedaSegnalazioni = 9999; // default value if env.js is not loaded

  constructor() {
    // console.log("SERVICE ENVIRONMENT STARTING...");
  }

}
