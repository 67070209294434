import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';



@Injectable()
export class CustomService {
    private readonly apiController: string = 'custom';

    constructor(private api: HttpService) { }

    saveLavorazioni(
        classLavorazione:string,
        lavorazione:any,
        richieste:any,
        lotti:any,
        additivi:any,
    ) {
        let data ={
            lavorazione: lavorazione,
            richieste: richieste,
            lotti: lotti,
            additivi: additivi,
        }
        return this.api.post(`${this.apiController}/saveLavorazione/${classLavorazione}`, data);
    }
}
