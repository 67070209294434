import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { EnvService } from '../services/env.service';

@Injectable({
  providedIn: 'root',
})
export class JsReportServiceService {

  constructor(
    private http: HttpClient,
    private env: EnvService,
  ) { }

  getReport(reportName: string, data: any = null) {

    const params = { 'template': { 'name': reportName }, 'data': data };
    let headers = new HttpHeaders();
    if (this.env.jsReportAccount) {
      headers = new HttpHeaders().set('Authorization',  `Basic ${btoa(this.env.jsReportAccount)}`);
    }

    const server = this.env.jsReportUrl;
    return this.http.post(`${server}/api/report`, params, {headers: headers , responseType: 'blob' });
  }

  save(blob: Blob, fileName: string) {
    const file = new Blob([blob]);
    FileSaver.saveAs(file, `${fileName}`);
  }

  saveAsPDF(blob: Blob, fileName: string) {
    const file = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(file, `${fileName}.pdf`);
  }

  openPDF(blob: Blob, fileName: string) {
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const html = '<html>' +
      '<title>' + fileName + '</title>' +
      '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
      '<body>' +
      '<iframe type="application/pdf" src="' + fileURL + '" download="' + fileName + '"></iframe>' +
      '</body></html>';
    const a = window.open('about:blank');
    a.document.write(html);
    a.document.close();
  }

}
