import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  NbDialogRef,
  NbDialogService,
  NbIconLibraries,
  NbToastrService,
} from '@nebular/theme';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { AppSettingsService } from '../../@core/backend/common/services/appsettings.service';
import { FileUploadService } from '../../@core/backend/common/services/file-upload.service';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { JsReportServiceService } from '../../@core/backend/common/services/js-report-service.service';
import { RelationsService } from '../../@core/backend/common/services/relations.service';
import { Allegato, CategoriaAllegati } from '../../@core/interfaces/allegati';
import Utils from '../../@core/utils/utils';
import { FormClassComponent } from '../form-class/form-class.component';
import { ModalChecklistComponentComponent } from '../modal-checklist-component/modal-checklist-component.component';
import { ModalClassEditComponent } from '../modal-class-edit/modal-class-edit.component';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalRelationSelectComponent } from '../modal-relation-select/modal-relation-select.component';
import { SchedularClassComponent } from '../schedular-class/schedular-class.component';

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';
import { UserStore } from '../../@core/stores/user.store';
import { ModalIdoneitaComponent } from '../modal-idoneita/modal-idoneita.component';
import { element } from 'protractor';
import { ModalMappaComponent } from '../modal-mappa/modal-mappa.component';

@Component({
  selector: 'ngx-modal-asset-details',
  templateUrl: './modal-asset-details.component.html',
  styleUrls: ['./modal-asset-details.component.scss'],
})
export class ModalAssetDetailsComponent implements OnInit {
  @Input('context.id') id: number;
  @Input('context.title') title: string;
  @Input('context.mode') mode: string; // modalità con cui si apre la modale ( CREATE | EDIT )
  @Input('context.className') className: string; // nome classe da gestire
  @Input('context.cardId') cardId: number; // idScheda da aprire
  @Input('context.selfServiceView') selfServiceView:boolean = false; //Disabilita il resize e blocca la modale a tutto schermo
  progress:any;
  @ViewChild(FormClassComponent, { static: true })
  public cardComponent: FormClassComponent;

  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>;
  @ViewChild('scheduler', { static: true })
  public scheduler: SchedularClassComponent;

  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;
  titleCodeId: string;
  nameAsset: string;
  color: string = '#005ca9';
  colorActive: { color: string; 'background-color': string; padding: string };
  colorDefault: any;
  // Tinymce
  configTinymce: any = {
    height: '100%',
    menubar: false,
    branding: false,
    elementpath: false,
    plugins:
      'print preview fullpage searchreplace  directionality visualblocks visualchars codesample  charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount colorpicker textpattern',
    toolbar:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | print',
    language_url: '/assets/tinymce/language/it.js',
  };

  // Formly
  model: any = {};
  modelNote: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = null;

  createform = new FormGroup({});
  noteForm = new FormGroup({});

  // menuRelazioniAdd
  itemsMenuRelazioniAdd = null;

  public tabsContentRef!: ElementRef;
  @ViewChild('cardRefElement', { static: false }) cardRefElement;
  classRelationShowLimit: any;

  // NAVIGAZIONE RELAZIONI
  navigation: any[] = [];
  reports: any;
  itemsMasterDetail: any;
  itemsMasterDetailInLine: any;
  currentEditGrid: any;
  isLoadPanelVisible: boolean;
  displayUser: any;
  modifyDate: any;
  allegati: CategoriaAllegati[];
  idCardScheduler: number;
  cardAlert: boolean;
  verifiche: any;
  filterAllegati: any;
  edit:any;
  card: any;
  popupPreviewVisible: boolean;
  popupPreviewBase64: any;
  dataSourceGallery: string[] = [];
  reportFields: any;
  showPopUpReportFields: boolean;
  formlyReport = new FormGroup({});
  modelReportFields = {};
  selectReport: any;
  confirmOnSave: any;
  mappeList: any;
  reference:string=null;
  referenceModel:any=null;
  referenceId:number=null;

  constructor(
    protected ref: NbDialogRef<ModalAssetDetailsComponent>,
    iconsLibrary: NbIconLibraries,
    private ngZone: NgZone,
    private router: Router,
    private classService: GenericClassService,
    private relationsService: RelationsService,
    private fileUploadService: FileUploadService,
    public san: DomSanitizer,
    private toasterService: NbToastrService,
    private appSettingsService: AppSettingsService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
    private reportService: JsReportServiceService,
    public translate: TranslateService,
    private utenteCorrente: UserStore,
  ) {
    // this.router.url.split('/')[1] == 'admin'
    //   ? (this.color = '#117094')
    //   : (this.color = '#005ca9');

    this.colorActive = {
      color: this.color,
      'background-color': 'white',
      padding: '12px',
    };
    this.colorDefault = { color: '#e4e9f2', padding: '12px' };

    iconsLibrary.registerFontPack('font-awesome', {
      packClass: 'far',
      iconClassPrefix: 'fa',
    });
    iconsLibrary.registerFontPack('font-awesome', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });

    this.appSettingsService.getSettings().subscribe((settings) => {
      this.classRelationShowLimit = settings.classRelationShowLimit;
    });

    this.actionView = this.actionView.bind(this);
    this.editDetail = this.editDetail.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
  }
  print() {
    this.tabChange(this.tabs[0]);
    setTimeout(() => {
      const data = document.getElementById('bodyMasterDetailInLine');
      html2canvas(data).then((canvas) => {
        // Few necessary setting options
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF

        pdf.text(this.titleModal, 1, 10);
        pdf.addImage(contentDataURL, 'PNG', 0, 20, imgWidth, imgHeight);
        pdf.save(this.titleModal + '.pdf'); // Generated PDF;
      });
    }, 100);
  }
  ngOnInit() {
    this.itemsMasterDetailInLine = [];
    this.itemsMenuRelazioniAdd = [];
    this.itemsMasterDetail = [];
    this.saveHistory(this.cardId, this.className, this.mode);
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = () => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    if(this.selfServiceView) {
      this.resize()
      this.tabs.forEach((tab:any)=>{
        if(tab.key != 1 && tab.key != 2 && tab.key != 6) {
          tab.hidden = true;
        }
      })
    }
  }
  calculateValue(row) {
    return {
      unitaPr:
        this.cardComponent &&
        this.cardComponent.model &&
        this.cardComponent.model.parentClass.some((k) =>
          ['Asset', 'Azienda'].includes(k),
        )
          ? this.cardComponent.model.UnitaPr
          : null,
      idAsset:
        row.Asset ||
        row['ObjValue.Asset'] ||
        row.Azienda ||
        row['ObjValue.Azienda'],
    };
  }
  lookup_calculateCellValue(row) {

    const column = this as any;
    row = row.ObjValue ? row.ObjValue : row;
    const dataField = column.dataField.replace('ObjValue.', '');

    const fieldName = dataField.replace('_Description', '').replace('_', '').replace('ObjValue.', '');
    let label = '';
    if (row[dataField]) {
      if (row['_' + fieldName + '_IconFont']) {
        if (row['_' + fieldName + '_IconColor']) {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" style="color:` +
            row['_' + fieldName + '_IconColor'] +
            `"></span>&nbsp;&nbsp;`;
        } else {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" ></span>&nbsp;&nbsp;`;
        }
      }
      if (row['_' + fieldName + '_TextColor']) {
        label +=
          `<span style="color:` +
          row['_' + fieldName + '_TextColor'] +
          `" >${row[dataField]}</span>`;
      } else {
        label += `<span>${row[dataField]}</span>`;
      }
      column.encodeHtml = false;
    }
    return label;
  }
  calculateCellValueDefault(row) {
    const column = this as any;
    return column.defaultCalculateCellValue(row);
  }
  // Preserve original property order
  originalOrder = (): number => {
    return 0;
  }
  loadRelation(exclude = null) {
    this.isLoadPanelVisible = true;
    this.spinner.show('loadingRelation');
    this.tabs[2].loading = true;
    this.tabs[4].loading = true;
    this.loadData().subscribe(([domains]) => {
      if (domains) {
        if (exclude && exclude.length > 0) {
          console.log('ESCLUSE RELAZIONI CON CAMPO REFERENCE:', exclude);
          domains.data = domains.data.filter((x) => !exclude.includes(x.name));
        }

        console.log('DOMAINS LOADED');
        if (domains.data && domains.data.length > 0) {
          // sistemazione viewColum per nested ObjValue

          this.itemsMenuRelazioniAdd = domains.data.map((x) => {
            console.log(x.description);
            x.icon = x.cardinality == '1:N' ? 'far fa-file' : 'far fa-copy';
            // Normalizza lookup e reference
            const viewColumns = x.viewColumns;
            const newKeyValues = Object.keys(viewColumns).map((key) => {
              if (viewColumns[key].lookup || viewColumns[key].reference) {
                let newKey = key;
                if (key.startsWith('ObjValue.')) {
                  newKey =
                    'ObjValue._' +
                    key.replace('ObjValue.', '') +
                    '_Description';
                } else {
                  newKey = '_' + key + '_Description';
                }
                const newValue = viewColumns[key];

                return { [newKey]: newValue };
              } else {
                return { [key]: viewColumns[key] };
              }
            });
            x.viewColumns = Object.assign({}, ...newKeyValues);

            // definizione tabella
            x.settings = {
              mode: 'external',
              hideSubHeader: true,
              columns: x.viewColumns,
              summary: x.summary,
              actions: {
                columnTitle: '',
                position: 'right',
                add: false,
                edit: false,
                delete: false,
                custom: [
                  {
                    name: 'viewAction',
                    title:
                      '<i class="fas fa-external-link-alt iconAwesomeAction" title="Vai a scheda relazionata" ></i>',
                  },
                  {
                    name: 'editAction',
                    title:
                      '<i class="fas fa-edit iconAwesomeAction" title="Modifica scheda relazionata" ></i>',
                  },
                  {
                    name: 'deleteAction',
                    title:
                      '<i class="fas fa-trash-alt iconAwesomeActionRel" title="Elimina relazione"></i>',
                  },
                ],
              },
            };
            if (
              (x.source == 'Verifica' && x.direction == 'inverse') ||
              (x.destination == 'Verifica' && x.direction == 'direct')
            ) {
              console.log('Aggiunta azione di verifica');

              const column = {
                title: '',
                type: 'buttons',
                allowFiltering: false,
                width: '90',
                alignment: 'center',
                fixed: true,
                fixedPosition: 'right',
                template: 'templateAvviaVerifica',
              };
              x.settings.columns['customColumn'] = column;
            }
            if (
              (x.source == 'Mappe' && x.direction == 'inverse') ||
              (x.destination == 'Mappe' && x.direction == 'direct')
            ) {
              console.log('Aggiunta bottone visualizza mappa');

              const column = {
                title: '',
                type: 'buttons',
                allowFiltering: false,
                width: '90',
                alignment: 'center',
                fixed: true,
                fixedPosition: 'right',
                template: 'templateVisualizzaMappa',
              };
              x.settings.columns['customColumn'] = column;
            }

            this.classService
              .getRelations(this.className, this.cardId, 'Map_' + x.name)
              .subscribe(
                (res) => {
                  res.data = res.data.map((z) => {
                    z.TypeClassAlias =
                      this.translate.instant(z.TypeClass + '.className') ||
                      z.TypeClassAlias;
                    return z;
                  });

                  x.store = res.data;

                  x.expanded = res.data.length < this.classRelationShowLimit;
                },
                () => {},
                () => {},
              );
            return x;
          });

          //Nota di Ciccio : Qui si crea il DropDown del bottone Gestione Relazione
          this.itemsMenuRelazioniAdd = this.itemsMenuRelazioniAdd.map((x) => {
            const descrizione =
              x.direction == 'direct'
                ? this.translate.instant(x.name + '.descriptionDirect') ||
                  x.viewDescr
                : this.translate.instant(x.name + '.descriptionInverse') ||
                  x.viewDescr;
            x.classAlias =
              this.translate.instant(x.viewClass + '.className') || x.viewClass;
            x.descriptionTitle =
              descrizione != '-' ? descrizione : x.classAlias;

            var splitStringIntoArray: any[] = [];

            if (x.name !== 'Gestione_RuoloNCG' && x.name !== 'Gestione_RuoloNC') {
              splitStringIntoArray = x.classAlias.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g)
            } else {
              splitStringIntoArray.push(x.descriptionDirect);
            }

            let title = '';
            for(var i = 0; i < splitStringIntoArray.length; i++) {
              title = title + ' ' + splitStringIntoArray[i];
            };
            x.viewTitle = title.trim();
              // descrizione != '-'
              //   ? descrizione + ' (' + x.classAlias + ')'
              //   : x.classAlias;

            // x.viewTitle='Inserisci '+x.classAlias;
            splitStringIntoArray = [];
            return x;
          });

          this.itemsMenuRelazioniAdd.sort((a, b) =>
            a.viewTitle.toLowerCase() > b.viewTitle.toLowerCase() ? 1 : -1,
          );

          //Rendo uniche le voci del menù Gestione Relazioni
          const itemMenuRelazioniUnique = this.itemsMenuRelazioniAdd.filter((v, i, a) => a.findIndex(
            t => t.viewTitle === v.viewTitle) === i
          );

          this.itemsMenuRelazioniAdd = itemMenuRelazioniUnique;

          // divido le relazioni nelle tabs

          this.itemsMasterDetail = this.itemsMenuRelazioniAdd.filter(
            (x) =>
              x.isMasterDetatils &&
              !x.isMasterDetatilsInLine &&
              x.showMasterDetails,
          );
          this.itemsMasterDetailInLine = this.itemsMenuRelazioniAdd.filter(
            (x) =>
              x.isMasterDetatils &&
              x.isMasterDetatilsInLine &&
              x.showMasterDetails,
          );
          this.itemsMenuRelazioniAdd = this.itemsMenuRelazioniAdd.filter(
            (x) => !x.isMasterDetatils || !x.showMasterDetails,
          );

          this.itemsMasterDetail.forEach(function (v) {
            delete v.settings.columns.TypeClass;
          });
          this.itemsMasterDetailInLine.forEach(function (v) {
            delete v.settings.columns.TypeClass;
          });

          if (
            this.itemsMenuRelazioniAdd &&
            this.itemsMenuRelazioniAdd.length > 0
          )
            this.tabs[2].disabled = false;
          if (this.itemsMasterDetail && this.itemsMasterDetail.length > 0)
            this.tabs[4].disabled = false;
        }
        setTimeout(() => {
          this.tabs[2].loading = false;
          this.tabs[4].loading = false;
          this.isLoadPanelVisible = false;
          this.spinner.hide('loadingRelation');
        }, 500);
      }
    });
  }

  contentReady($event) {
    // inserisce nel model i summary
    // content ready griglie relazioni ********************************
    const summaryColumn = $event.component
      .instance()
      .option('summary.totalItems');
    if (summaryColumn && summaryColumn.length > 0) {
      summaryColumn.forEach((summaryItem) => {
        try {
          this.cardComponent.addSummary({
            name: summaryItem.name,
            value: $event.component
              .instance()
              .getTotalSummaryValue(summaryItem.name),
          });
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  map(row) {
    this.dialogService
      .open(ModalMappaComponent, {
        context: {
          idMappa:row.Id||row.ObjValue.Id,
          idOggetto:this.cardId
        },
        hasBackdrop: true,
      })
      .onClose.subscribe(() => { });
  }
  mappeLoaded(res){

    res.data.forEach(element => {
      delete element.MasterId
    });
    this.mappeList=[...new Map(res.data.map(item =>
      [item['Id'], item])).values()];

  }
  onCardEndLoading(result) {
    console.log('endLoading !!!!!!!!!!!!!!!!!!!');
    const card = result.model;
    this.card = card;
    if (this.card.parentClass && (this.card.parentClass[0] == "Asset" || this.card.parentClass[0] == "Ubicazione")) {
      this.tabs[7].hidden = false;
    }
    this.confirmOnSave = result.classData.confirm?JSON.parse(result.classData.confirm):null;

    this.filterAllegati = result.classData.filterAllegati
      ? JSON.parse(result.classData.filterAllegati)
      : null;
    this.title =
      this.translate.instant(result.classData.className + '.className') ||
      result.classData.label;
    if (card && Object.keys(card).length > 0) {
      this.titleCodeId = card.Description || '';
      this.displayUser = card.DisplayUser;
      this.modifyDate = card.BeginDate;
      this.modelNote.Notes = card.Notes;
      this.cardId = card.Id;
      this.titleModal =
        this.tabs[0].name + ' ' + this.title + ` (${this.titleCodeId}) `;
      this.tabs[0].disabled = false;
      this.tabs[1].disabled = false;
      const exclude = [];
      exclude.push(...result.referenceFields.map((x) => x.reference));
      this.loadRelation(exclude);

      this.tabs[3].loading = true;
      this.classService.getReportClass(this.className).subscribe((res) => {
        this.tabs[3].loading = false;

        if (res.data && res.data.length > 0) {
          this.reports = res.data;
          this.tabs[3].disabled = false;
        } else {
          this.tabs[3].disabled = true;
        }
        this.tabs[3].disabled = false;
      });
      console.log('START LOADING CATEGORIE ALLEGATI');

      this.classService
        .getAllegati2Class(this.className, card, this.filterAllegati)
        .subscribe((res) => {
          this.allegati = new Array();
          if (res.data && res.data.length > 0) {
            const allegati = res.data;

            allegati.forEach((element) => {
              // creo categorie
              if (!this.allegati[element.Categoria]) {
                this.allegati[element.Categoria] = {
                  description:
                    element.Categoria == 'FREE'
                      ? 'Allegati liberi'
                      : element.Categoria,
                  canAddAllegati: element.FreeAttachment,
                  items: [],
                };
              }
              // aggiungo file default categoria
              if (element.Code && element.Code != 'FREE' ) {
                const allegato: Allegato = {
                  Id: element.Id,
                  Code: element.Code,
                  Description: element.Description,
                  IsRequired: element.Required,
                  Categoria: element.Categoria,
                  File: {
                    IdFile: 0,
                    Name: '',
                    Size: 0,
                    DownloadUrl: '',
                    Base64: null,
                  },
                };
                this.allegati[element.Categoria].items.push(allegato);
              }
            });
            // RIMUOVE CATEGORIE SENZA ALLEGATI LIBERI VUOTE
            for (let index = 0; index < this.allegati.length; index++) {
              const element = this.allegati[index];
              if (!element.canAddAllegati && element.items.length == 0) {
                delete this.allegati[index];
              }
            }
            console.log('START LOADING ALLEGATI....');
            // this.tabs[5].disabled = false;
          }
          this.classService
            .getAllegati(this.className, this.cardId)
            .subscribe((resAll) => {
              if (resAll.status == 'success') {
                const list = resAll.data;
                console.log('LISTA ALLEGATI', list);

                list.forEach((element) => {
                  if (element.Code != 'categoria') {
                    // allegato obbligatorio
                    if (element.Categoria && !this.allegati[element.Categoria]) {
                      this.allegati[element.Categoria] = {
                        description:
                          element.Categoria == 'FREE'
                            ? 'Allegati liberi'
                            : element.Categoria,
                        canAddAllegati: element.FreeAttachment,
                        items: [],
                      };
                    }


                      const findElement = this.allegati[
                        element.Categoria
                      ].items.find((x) => x.Code == element.Code);

                      if (element.base64) {
                        this.dataSourceGallery.push(element.base64);
                      }
                      if (findElement && element.Code != 'FREE') {

                        findElement.File = {
                          IdFile: element.Id,
                          Name: element.Filename,
                          Size: element.Filesize,
                          DownloadUrl: element.Key,
                          Base64: element.base64,
                          Validita: element.Validita,
                          Disabled: element.Disabled
                        };
                        findElement.DataRiferimento = element.DataRiferimento;
                        findElement.DataScadenza = element.DataScadenza;
                      } else if (element.Code == 'FREE') {
                        this.allegati[element.Categoria].items.push({
                          Id:element.Id,
                          Code: element.Code,
                          Description: element.Description,
                          IsRequired: true,
                          Categoria: element.Categoria,
                          DataRiferimento: element.DataRiferimento,
                          DataScadenza: element.DataScadenza,
                          File: {
                            IdFile: element.Id,
                            Name: element.Filename,
                            Size: element.Filesize,
                            DownloadUrl: element.Key,
                            Base64: element.base64,
                            Validita: element.Validita,
                            Disabled: element.Disabled
                          },
                        });
                      }
                  }
                });
                console.log(this.allegati)
              }
            });
          // else {
          //   this.tabs[5].disabled = true;
          // }
        });

      console.log('START LOADING MAPPE');
      const storeMappe=this.classService.getDataSourceDevExpFromView("Mappe","vwMappe",
      [
        ["MasterId","=",this.cardId],
        "or",
        ["Ubicazione","=",this.cardId]
      ]
      );
      storeMappe.on("loaded",this.mappeLoaded.bind(this))
      storeMappe.load();

      this.verifica();

      this.idCardScheduler = this.cardId;
    } else {
      this.titleModal = 'Creazione ' + this.title;
    }

    console.log(this.card);
    this.cardRefElement.nativeElement
      .querySelector('nb-card-body')
      .scrollTo(0, 0);
  }
  previewPopUp(base64) {
    this.popupPreviewBase64 = base64;
    this.popupPreviewVisible = true;
  }
  classScadenza(data) {
    if (moment(data) <= moment()) return 'text-danger';
    return '';
  }
  verifica() {
    return this.classService
      .verificaScheda(this.className, this.cardId)
      .subscribe((res) => {
        if (res.status == 'success') {
          const data = res.data;

          this.verifiche = data;

          this.tabs[5].alert = false;
          this.tabs[6].alert = false;
          this.cardAlert = false;

          if (data.scadenze && data.scadenze.length > 0) {
            this.tabs[6].alert = true;
          }
          if (data.defaultAllegati && data.defaultAllegati.length > 0) {
            this.tabs[5].alert = true;
            this.cardAlert = true;
          }
          if (data.allegatiScaduti && data.allegatiScaduti.length > 0) {
            this.tabs[5].alert = true;
            this.cardAlert = true;
          }
          if (data.scadenzeNonInserite && data.scadenzeNonInserite.length > 0) {
            this.tabs[6].alert = true;
            this.cardAlert = true;
          }

          if (
            data.verificheNonProgrammate &&
            data.verificheScadute.length > 0
          ) {
            this.cardAlert = true;
          }
        }
      });
  }

  endLoadingScadenze(listSize) {
    /* if (listSize && listSize>0) {
      this.tabs[6].disabled=false
    } else {
      this.tabs[6].disabled=true
    } */
  }

  prepare(e:any)
  {
    if (e.data && e.data.File && e.data.File.Disabled == true) {
      e.rowElement.style.backgroundColor = '#d7d7d430';
      e.rowElement.style.color = '#989898';
    }
  }

  changeScadenze(e) {
    this.verifica();
  }

  isEditIconVisible(e)
  {
    return !e.row.data.File.Disabled;
  }

  // Compilazione checklist
  compiledCheckList() {
    // this.refreshDataGrid();
  }
  // Tracking ProduzioneLotti
  selectLavorazione(data) {
    this.title = this.className = data.LavType;
    this.cardId = data.LavId;
    this.titleCodeId = '';

    this.ngOnInit();
    this.tabChange(this.tabs[0]);
  }
  saveHistory(idCard, className, action) {
    this.navigation.push({
      idCard: idCard,
      className: className,
      action: action,
    });
    console.log('saved state history', this.navigation);
  }
  goBack() {
    let back = this.navigation.pop();
    back = this.navigation.pop();

    this.className = back.className;
    this.cardId = back.idCard;
    this.titleCodeId = '';
    this.ngOnInit();
    this.tabChange(this.tabs[0]);
  }
  actionView(e) {
    e.event.preventDefault();

    this.className = e.row.data['ObjClass'];
    this.cardId = e.row.data['ObjValue'].Id;
    this.titleCodeId = '';

    this.ngOnInit();
    this.tabChange(this.tabs[0]);
  }

  onCustomActionRelazioni(event) {
    if (event.action == 'checklistAction') {
      this.openChecklistForm(event.data);
    } else if (event.action == 'viewAction' || event.action == 'editAction') {
      this.className = event.data['ObjClass'];
      this.cardId = event.data['ObjValue.Id'];
      this.titleCodeId = '';

      this.ngOnInit();
      this.tabChange(this.tabs[0]);
    } else {
      this.deleteRelation(event.data);
    }
  }

  public loadData(): Observable<any[]> {
    const request: any[] = [];
    if (this.mode != 'CREATE') {
      request.push(this.classService.getDomains(this.className));
    }

    return forkJoin(request);
  }

  cancel() {
    this.ref.close();
  }

  submit_note(closeModal = false) {
    this.submit(closeModal, true);
  }
  submit(closeModal = false, updateOnlyNote = false) {
    if (this.confirmOnSave && !updateOnlyNote) {
      if (!this.confirmOnSave.condition) {
        this.confirmOnSave.condition=true;
      }
      // valuta condizione
      const cond=this.calculateExpression(this.confirmOnSave.condition,this.cardComponent.model)
      if (cond) {
        this.confirmOnSave.bootbox.callback=(r) => {
          if (r == true) {
            this.do_submit(closeModal,updateOnlyNote);
          }
        };
        bootbox.confirm(this.confirmOnSave.bootbox);

      } else {
        this.do_submit(closeModal,updateOnlyNote)
      }

    } else {
      this.do_submit(closeModal,updateOnlyNote)
    }
  }
  calculateExpression(f, o) {
    'use strict';
    const model = o;
    const m = o;

    return eval(f);
  }
  do_submit(closeModal = false, updateOnlyNote = false) {
    let myModel = null;
    myModel = { Notes: this.modelNote.Notes };

    this.cardComponent.save(myModel).subscribe((ret) => {
      if (ret !== false) {
        this.mode = 'EDIT';
        if (closeModal) this.ref.close(ret);
      }
    });
  }

  selectedUserTab = 1;
  tabs = [
    {
      name: 'Scheda',
      key: 1,
      active: true,
      disabled: true,
      icona: 'fas fa-table fa-2x',
      index: 0,
      alert: false,
      loading: false,
      hidden: false
    },
    {
      name: 'Note',
      key: 2,
      active: false,
      disabled: true,
      icona: 'fas fa-comment-alt fa-2x',
      index: 1,
      alert: false,
      loading: false,
      hidden: false
    },
    {
      name: 'Relazioni',
      key: 3,
      active: false,
      disabled: true,
      icona: 'fas fa-link fa-2x',
      index: 3,
      alert: false,
      loading: false,
      hidden: this.selfServiceView
    },
    {
      name: 'Stampe',
      key: 4,
      active: false,
      disabled: true,
      icona: 'fas fa-print fa-2x',
      index: 4,
      alert: false,
      loading: false,
      hidden: this.selfServiceView
    },
    {
      name: 'Dettagli',
      key: 5,
      active: false,
      disabled: true,
      icona: 'fa fa-list-ul fa-2x',
      index: 2,
      alert: false,
      loading: false,
      hidden: this.selfServiceView
    },
    {
      name: 'Allegati',
      key: 6,
      active: false,
      disabled: false,
      icona: 'fa fa-paperclip fa-2x',
      index: 6,
      alert: false,
      loading: false,
      hidden: false
    },
    {
      name: 'Scadenze',
      key: 7,
      active: false,
      disabled: false,
      icona: 'fas fa-calendar-alt fa-2x',
      index: 7,
      alert: false,
      loading: false,
      hidden: this.selfServiceView
    },
    {
      name: 'Mappe',
      key: 8,
      active: false,
      disabled: false,
      icona: 'fa fa-map fa-2x',
      index: 8,
      alert: false,
      loading: false,
      hidden: true
    },
    {
      name: 'Validazione',
      key: 999,
      active: false,
      disabled: false,
      icona: 'fas fa-calendar-alt fa-2x',
      index: 999,
      alert: false,
      loading: false,
      hidden: false
    },
  ];
  tabsOrdered() {
    const prop = 'index';
    let newArray = [...this.tabs];
    newArray = newArray.filter((x) => x.key != 999);
    const order = newArray.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1,
    );

    return order;
  }
  tabChange(selectedTab) {
    if (this.mode == 'CREATE') return;
    this.titleModal =
      this.tabs[0].name +
      ' ' +
      this.title +
      ` (${this.titleCodeId}) ` +
      (selectedTab.name != this.tabs[0].name ? ' - ' + selectedTab.name : '');
    this.selectedUserTab = selectedTab.key;

    for (const tab of this.tabs) {
      if (tab.key === selectedTab.key) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    }
    this.cardRefElement.nativeElement
      .querySelector('nb-card-body')
      .scrollTo(0, 0);

    setTimeout(() => {
      this.dataGrids.forEach((element) => {
        element.instance.updateDimensions();
      });
    }, 1);

    if (selectedTab.name == 'Scadenze') {
      // AGGIORNO DXSCHEDULER repaint()

      this.scheduler.repaint();
    }
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
    setTimeout(() => {
      this.dataGrids.forEach((element) => {
        element.instance.updateDimensions();
      });
    }, 1);
  }

  styleMax(): object {
    return (this.style = {
      width: this.width + 'px',
      height: this.height + 'px',
    });
  }
  styleNormal(): object {
    return (this.style = {
      width: this.width * 0.8 + 'px',
      height: this.height * 0.8 + 'px',
    });
  }

  refresh() {}

  // AZIONI RELAZIONI***********************************************
  addDetail(event, item) {
    event.preventDefault();
    event.stopPropagation();

    const selectedClass =
      item.direction == 'direct' ? item.destination : item.source;
    const description =
      item.direction == 'direct' ? item.destination : item.source;
    const referenceId = this.cardId;
    const referenceDomain = item.name;

    this.dialogService
      .open(ModalClassEditComponent, {
        context: {
          titleModal: 'Crea ' + description,
          className: selectedClass,
          cardId: null,
          reference: referenceDomain,
          referenceId: referenceId,
          referenceModel: this.card,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          if (item) {
            this.classService
              .getRelations(this.className, this.cardId, item.name)
              .subscribe((res) => {
                let currentRow = this.itemsMasterDetail.find(
                  (x) => x.name == item.name.replace('Map_', ''),
                );
                if (currentRow) currentRow.store = res.data;
                currentRow = this.itemsMasterDetailInLine.find(
                  (x) => x.name == item.name.replace('Map_', ''),
                );
                if (currentRow) currentRow.store = res.data;
              });
          }
        }
      });
  }
  editDetail(e) {
    e.event.preventDefault();
    const item = e.row.data;

    const selectedClass = item.TypeClass;
    const description = item.TypeClass;
    const referenceId = this.cardId;
    const referenceDomain = item.IdDomain.replace('Map_', '');

    this.dialogService
      .open(ModalClassEditComponent, {
        context: {
          titleModal: 'Modifica ' + description,
          className: selectedClass,
          cardId: item.ObjValue.Id,
          reference: referenceDomain,
          referenceId: referenceId,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          if (item) {
            this.classService
              .getRelations(this.className, this.cardId, item.IdDomain)
              .subscribe((res) => {
                let currentRow = this.itemsMasterDetail.find(
                  (x) => x.name == item.IdDomain.replace('Map_', ''),
                );
                if (currentRow) currentRow.store = res.data;
                currentRow = this.itemsMasterDetailInLine.find(
                  (x) => x.name == item.IdDomain.replace('Map_', ''),
                );
                if (currentRow) currentRow.store = res.data;
              });
          }
        }
      });
  }

  deleteDetail(e) {
    e.event.preventDefault();
    const item = e.row.data;
    this.dialogService
      .open(ModalConfirmComponent, {
        context: { titleModal: 'Confermi la cancellazione del\'elemento?' },
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          this.classService
            .deleteScheda(item.ObjClass, item.ObjValue.Id)
            .subscribe(
              (res) => {
                if (res.status === 'success') {
                  this.toasterService.success(
                    '',
                    `Elemento eliminato con successo.`,
                  );
                  if (item) {
                    this.classService
                      .getRelations(this.className, this.cardId, item.IdDomain)
                      .subscribe((resRel) => {
                        let currentRow = this.itemsMasterDetail.find(
                          (x) => x.name == item.IdDomain.replace('Map_', ''),
                        );
                        if (currentRow) currentRow.store = resRel.data;
                        currentRow = this.itemsMasterDetailInLine.find(
                          (x) => x.name == item.IdDomain.replace('Map_', ''),
                        );
                        if (currentRow) currentRow.store = resRel.data;
                      });
                  }
                } else {
                  this.toasterService.danger('', `ERRORE!`);
                }
              },
              (error) => {
                if (error.error) {
                  this.toasterService.danger(
                    '',
                    `ERRORE: ${error.error.status} - ${error.error.data}`,
                  );
                } else {
                  this.toasterService.danger(
                    '',
                    `ERRORE! ${error.status}  ${error.statusText}`,
                  );
                }
              },
            );
        }
      });
  }

  createRelation(event, data = null) {
    console.log('--> Create relation');
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }

    const selectItem = event.itemData ? event.itemData : data;
    const referenceId = this.cardId;
    const referenceDomain = selectItem.name;

    this.dialogService
      .open(ModalRelationSelectComponent, {
        context: {
          titleModal: selectItem.viewTitle,
          columns: selectItem,
          className: this.className,
          originId: this.cardId,
          selectDomain: selectItem,
          reference: referenceDomain,
          referenceId: referenceId,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          this.classService
            .getRelations(this.className, this.cardId, selectItem.name)
            .subscribe((res) => {
              let currentRow = this.itemsMenuRelazioniAdd.find(
                (x) => x.name == selectItem.name.replace('Map_', ''),
              );
              if (!currentRow) {
                currentRow = this.itemsMasterDetailInLine.find(
                  (x) => x.name == selectItem.name.replace('Map_', ''),
                );
              }
              if (!currentRow) {
                currentRow = this.itemsMasterDetail.find(
                  (x) => x.name == selectItem.name.replace('Map_', ''),
                );
              }
              currentRow.store = res.data;
            });
        }
      });
  }
  openEditForm(data) {
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('**********', data);
    this.dialogService
      .open(ModalClassEditComponent, {
        context: {
          titleModal: 'Modifica',
          className: data.ObjClass,
          cardId: data['ObjValue.Id'],
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          if (data) {
            this.classService
              .getRelations(this.className, this.cardId, data.IdDomain)
              .subscribe((res) => {
                if (res.data.length) {
                  res.data = Utils.flattenArray(res.data);
                }
                const currentRow = this.itemsMenuRelazioniAdd.find(
                  (x) => x.name == data.IdDomain.replace('Map_', ''),
                );
                currentRow.sourcedata = res.data;
              });
          }
        }
      });
  }
  openChecklistForm(data) {
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('**** OPEN CHECKLIST******', data);
    this.dialogService
      .open(ModalChecklistComponentComponent, {
        context: {
          titleModal: 'Checklist',
          verificaId: data['ObjValue.Scheda'],
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          if (data) {
            this.classService
              .getRelations(this.className, this.cardId, data.IdDomain)
              .subscribe((res) => {
                if (res.data.length) {
                  res.data = Utils.flattenArray(res.data);
                }
                const currentRow = this.itemsMenuRelazioniAdd.find(
                  (x) => x.name == data.IdDomain.replace('Map_', ''),
                );
                currentRow.sourcedata = res.data;
              });
          }
        }
      });
  }
  deleteRelation(event) {
    this.dialogService
      .open(ModalConfirmComponent, {
        context: { titleModal: 'Confermi la cancellazione della relazione?' },
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          this.relationsService.delete(event.IdDomain, event.Id).subscribe(
            (res) => {
              if (res.status === 'success') {
                this.toasterService.success(
                  '',
                  `Relazione eliminata con successo.`,
                );
                this.ngOnInit();
              } else {
                this.toasterService.danger('', `ERRORE!`);
              }
            },
            (error) => {
              if (error.error) {
                this.toasterService.danger(
                  '',
                  `ERRORE: ${error.error.status} - ${error.error.data}`,
                );
              } else {
                this.toasterService.danger(
                  '',
                  `ERRORE! ${error.status}  ${error.statusText}`,
                );
              }
            },
          );
        }
      });
  }

  avviaReport(report) {
    this.selectReport = report;

    this.modelReportFields = {};
    if (report.ReportInputFormly) {
      this.reportFields = JSON.parse(report.ReportInputFormly);
      this.showPopUpReportFields = true;
    } else {
      this.startReport();
    }
  }

  startReport() {
    this.showPopUpReportFields = false;
    const report = this.selectReport;
    report.loading = true;

    const input = report.ReportInputFormly ? this.modelReportFields : {};
    const params = {...this.cardComponent.model};
    params.params = {...input};
    const currentUser=this.utenteCorrente.getUser();

    if (report.ReportServiceName) {
      this.classService.getService(report.ReportServiceName, params).subscribe((res) => {
        this.doReport({...params, ...res.data,'utente':currentUser});
      }, (error) => {report.loading = false; });
    } else {
      this.doReport(params);
    }
  }
  doReport(params) {
    const report = this.selectReport;
    this.reportService
      .getReport(report.ReportName, params)
      .subscribe(
        (res) => {
          report.loading = false;
          const ext = report.ReportFilename.split('.').pop();
          if (ext == 'pdf') {
            this.reportService.openPDF(res, report.ReportFilename);
          } else {
            this.reportService.save(res, report.ReportFilename);
          }
        },
        (err) => {
          console.log(err);
          report.loading = false;
        },
        () => {
          report.loading = false;
        },
      );
  }

  startReadFile(e, cell) {
    cell.data.File.FilePicked = null;
  }
  rowFileValid(e) {
    if (
      !e.newData.File ||
      !e.newData.File.File ||
      !e.newData.File.File.FilePicked
    ) {
      e.isValid = false;
    }
  }
  readFile(e, cell, categoria) {
    const data = { ...cell.data };

    data.File.FilePicked = e;
    data.File.Name = e.name;
    data.File.Size = e.size;
    data.File.DownloadUrl = null;

    cell.setValue(data);
  }

  onRowUpdating(e) {
    this.progress = 0;
    let scadenza;
    if( e.newData.DataScadenza == null && e.newData.File.File.Validita > 0)
    {
       scadenza =  moment(e.newData.DataRiferimento ? e.newData.DataRiferimento :e.newData.File.DataRiferimento).add(e.newData.File.File.Validita,'days').format();
    } else if (e.newData.DataScadenza != null) {
      scadenza = moment(e.newData.DataScadenza).format();
    }


    console.log(scadenza)
    let riferimento =  moment(e.newData.DataRiferimento).format();
    e.newData.DataScadenza = scadenza;
    e.newData.DataRiferimento = riferimento;

    if (moment(scadenza ).format("YYYY-MM-DD") <= moment(riferimento).format("YYYY-MM-DD")) {
      scadenza =  null;
      alert("Non è possibile inserire la data di scadenza uguale o precedente alla data di riferimento.")

      e.cancel = true

      return
    }


    const idFile = e.newData.File.File.IdFile;
    const code = e.newData.File.Code;
    const description = e.newData.File.Description;
    const file = e.newData.File ? e.newData.File.File : null;
    const DataRiferimento =  riferimento ;
    const DataScadenza = scadenza;
    e.cancel = new Promise((resolve, reject) => {
      this.fileUploadService
        .addClassAllegato(
          this.cardId,
          idFile,
          code,
          description,
          e.newData.File.Categoria,
          file,
          DataRiferimento,
          DataScadenza,
        )
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('1. Request has been made!');
                this.progress = 1;
                break;
              case HttpEventType.ResponseHeader:
                console.log('2. Response header has been received!');
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(
                  (event.loaded / event.total) * 100,
                );

                break;

              case HttpEventType.Response:
                e.newData.File.progress = 0;
                console.log('Finish - Successfully created!', event.body);

                if (event.body.status == 'success') {
                  e.newData.File.DownloadUrl = event.body.data.key;
                  e.newData.File.IdFile = event.body.data.idFile;
                  this.progress = 0;
                  if(e.oldData.Categoria == "Visite" && this.className!="PersonaleEsterno")
                  {
                    this.openModalIdoneita();
                  }
                }

                resolve(false);
            }
          },

          (error) => {
            this.progress = 0;

            reject(error);
          },
        );
    });
  }

  openModalIdoneita()
  {
      this.dialogService
        .open(ModalIdoneitaComponent,{
          context: {
            idCard: this.cardId,
          },
          hasBackdrop: true,
        })
        .onClose.subscribe((res) => {
          if (res) {
            this.classService
            .getRelations(this.className, this.cardId, "Personale")
            .subscribe((res) => {
              let currentRow = this.itemsMasterDetail.find(
                (x) => x.name == 'Personale',
              );
              if (currentRow) currentRow.store = res.data;
              currentRow = this.itemsMasterDetailInLine.find(
                (x) => x.name == 'Personale',
              );
              if (currentRow) currentRow.store = res.data;
            });
          }
        });
  }

  onEditStart(e:any)
  {
    console.log(e);
    if(e.data.File.Validita > 0)
    {
      if (e.data.Categoria=="Visite") {
        e.component.columnOption("Scadenza", "allowEditing", true);
      } else {
        e.component.columnOption("Scadenza", "allowEditing", false);
      }

    } else {
      e.component.columnOption("Scadenza", "allowEditing", true);
    }
  }


  onEditorPreparing(e: any) {
    if (
      e.parentType == 'dataRow' &&
      e.dataField == 'Description' &&
      !e.row.inserted
    )
      if (e.row.data.Code != 'categoria') {
        e.editorOptions.disabled = true;
        e.component.focus(e.component.getCellElement(e.row.rowIndex, 2));
      }
  }
  logEvent(eventName, e) {
    console.log(eventName, e);
  }

  downloadAllegato(e, cell) {
    e.preventDefault();
    e.stopPropagation();
    this.classService.downloadAllegato(cell.DownloadUrl).subscribe((data) => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = cell.Name;
      a.href = URL.createObjectURL(data);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }),
      (error) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
  isDeleteIconVisible(e) {


    return !e.row.isEditing && e.row.data.Code == '';
  }

}
