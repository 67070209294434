import { Injectable } from '@angular/core';

@Injectable()
export class SmartTableSettingsServices {
    public setting = {
        mode: 'external',
        add: false,
        hideSubHeader: true,
        actions: {
            columnTitle: '',
        },
        edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
        },
        delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: true,
        },
        columns: {
        },
    };
}
