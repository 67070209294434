import { Component, OnInit, Injectable, Input, NgZone, HostBinding, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbIconLibraries } from '@nebular/theme';
import { CoreExtension } from '@ngx-formly/core/lib/extensions/core/core';
import { FormClassComponent } from '../../../../@components/form-class/form-class.component';
import { GenericClassService } from '../../../../@core/backend/common/services/genericClass.service';
import notify from 'devextreme/ui/notify';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { FileUploadService } from '../../../../@core/backend/common/services/file-upload.service';
import { EnvService } from '../../../../@core/backend/common/services/env.service';

@Component({
  selector: 'app-modal-detail',
  templateUrl: './modal-detail.component.html',
  styleUrls: ['./modal-detail.component.scss']
})
export class ModalDetailComponent implements OnInit {

  @ViewChild('card', { static: true }) cardComponent: FormClassComponent;
  @Input('context.id') id: any;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  allegati: any;

  assetGestioneForm = new FormGroup({});
  modelAssetGestione: any = {};
  optionsAssetGestione: FormlyFormOptions = {};
  fieldsAssetGestione: FormlyFieldConfig[];
  defaultTipoEsecutore = 366914;
  codiceSegnalazione: string;
  oldGestione: any;

  constructor(
    private dialogService: NbDialogService,
    private env: EnvService,
    public iconsLibrary: NbIconLibraries,
    private classService: GenericClassService,
    private fileUploadService: FileUploadService,
    public ref: NbDialogRef<ModalDetailComponent>,
    public ngZone: NgZone) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }
  mapFields() {
    console.log("MapFielsd")
    const f = [
      {
        wrappers: ['panelgroup'],
        templateOptions: {
          label: 'Lavorazione',
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                fieldGroupClassName: null,
                key: 'TipoEsecutore',
                type: 'typeahead',
                className: 'col',
                wrappers: null,
                defaultValue: this.defaultTipoEsecutore,
                templateOptions: {
                  type: '',
                  label: 'Tipo esecutore',
                  placeholder: 'inserire tipo esecutore',

                  lookup: 'TipoEsecutore',
                  reference: null,
                  classReference: null,
                  originalClass: 'Verifica',
                },
                fieldGroup: null,
                template: null,
                hideExpression: '(formState[\'hiddenTipoEsecutore\']===true)',
                expressionProperties: {
                  'templateOptions.disabled':
                    'field.templateOptions.readonly||formState.disabled||formState[\'disableTipoEsecutore\']',
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'TipoEsecutore\']:[]',
                },
              },
            ],
            template: null,
            hideExpression: null,
            expressionProperties: null,
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'Deposito',
                type: 'typeahead',
                className: 'col',

                templateOptions: {

                  setFirstAsDefault: false,
                  type: '',
                  label: 'Deposito',
                  placeholder: 'inserire deposito',
                  reference: 'Verifica_Deposito',
                  classReference: 'Deposito',
                  originalClass: 'Verifica',
                },
                hideExpression: '(formState[\'hiddenAssetDeposito\']===true) || (!model.isRifiuti)',
                expressionProperties: {
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'Deposito\']:[]',
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'AssetGestione',
                type: 'typeahead',
                className: 'col',

                templateOptions: {
                  setFirstAsDefault: false,
                  type: '',
                  label: 'Gestione',

                  classReference: 'Gestione',
                  originalClass: 'AssetGestione',
                  reference: "AssetGest_Gestione",

                },

                hideExpression: '(formState[\'hiddenAssetGestione\']===true)',
                expressionProperties: {
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'AssetGestione\']:[]',
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'Funzione',
                type: 'typeahead',
                className: 'col',

                templateOptions: {
                  setFirstAsDefault: false,
                  type: '',
                  label: 'Funzione del responsabile',
                  reference: 'Verifica_Responsab',
                  classReference: 'Funzione',
                  originalClass: 'Verifica',
                  selectFilterReference: ["_Tipo_Code", "=", "F"],
                  disabled: true
                }
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            key: null,
            type: null,
            className: null,
            wrappers: null,
            templateOptions: null,
            fieldGroup: [
              {
                fieldGroupClassName: null,
                key: 'Responsabile',
                type: 'typeahead',
                className: 'col-12',
                wrappers: null,
                templateOptions: {
                  type: '',
                  label: 'Responsabile',
                  placeholder: 'inserire responsabile',
                  disabled: null,
                  rows: null,
                  cols: null,
                  description: null,
                  hidden: null,
                  max: null,
                  min: null,
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  lookup: null,
                  reference: 'Verifica_Responsab',
                  classReference: 'Funzione',
                  originalClass: 'Verifica',
                  required: false,
                  tabindex: null,
                  readonly: null,
                  valueProp: null,
                  labelProp: null,
                  filterReference: null,
                  selectFilterReference: ['_Tipo_Code', '=', 'R'],
                },
                fieldGroup: null,
                template: null,
                hideExpression: '(formState[\'hiddenResponsabile\']===true)',
                expressionProperties: {
                  'templateOptions.disabled':
                    'field.templateOptions.readonly||formState.disabled||formState[\'disableResponsabile\']',
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'Responsabile\']:[]',
                },
              },
              {
                fieldGroupClassName: null,
                key: 'Vigilanza',
                type: 'typeahead',
                className: 'col-12',
                wrappers: null,
                templateOptions: {
                  type: '',
                  label: 'Vigilanza',
                  placeholder: 'inserire vigilanza',
                  disabled: null,
                  rows: null,
                  cols: null,
                  description: null,
                  hidden: null,
                  max: null,
                  min: null,
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  lookup: null,
                  reference: 'Verifica_Vigilanza',
                  classReference: 'Funzione',
                  originalClass: 'Verifica',
                  required: false,
                  tabindex: null,
                  readonly: null,
                  valueProp: null,
                  labelProp: null,
                  filterReference: null,
                  selectFilterReference: ['_Tipo_Code', '=', 'R'],
                },
                fieldGroup: null,
                template: null,
                hideExpression: '(formState[\'hiddenVigilanza\']===true || (model.isRifiuti))',
                expressionProperties: {
                  'templateOptions.disabled':
                    'field.templateOptions.readonly||formState.disabled||formState[\'disableVigilanza\']',
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'Vigilanza\']:[]',
                },
              },

              {
                fieldGroupClassName: null,
                key: 'Esecutore',
                type: 'typeahead',
                className: 'col-12',
                wrappers: null,
                templateOptions: {
                  type: '',
                  label: 'Esecutore',
                  placeholder: 'inserire esecutore',
                  disabled: null,
                  rows: null,
                  cols: null,
                  description: null,
                  hidden: null,
                  max: null,
                  min: null,
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  lookup: null,
                  reference: 'Verifica_Esecutore',
                  classReference: 'Funzione',
                  originalClass: 'Verifica',
                  required: false,
                  tabindex: null,
                  readonly: null,
                  valueProp: null,
                  labelProp: null,
                  filterReference: null,
                  selectFilterReference: ['_Tipo_Code', '=', 'R'],
                },
                fieldGroup: null,
                template: null,
                hideExpression:
                  '(formState[\'hiddenEsecutore\']===true)||(model.Lookup && model.Lookup.TipoEsecutore?model.Lookup.TipoEsecutore.Code!=\'I\':false)',
                expressionProperties: {
                  'templateOptions.disabled':
                    'field.templateOptions.readonly||formState.disabled||formState[\'disableEsecutore\']',
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'Esecutore\']:[]',
                },
              },
            ],
            template: null,
            hideExpression: null,
            expressionProperties: null,
          },
          {
            fieldGroupClassName: 'row',
            key: null,
            type: null,
            className: null,
            wrappers: null,
            templateOptions: null,
            fieldGroup: [
              {
                fieldGroupClassName: null,
                key: 'EsecutoreEsterno',
                type: 'typeahead',
                className: 'col',
                wrappers: null,
                templateOptions: {
                  type: '',
                  label: 'Esecutore esterno',
                  placeholder: 'inserire esecutore esterno',
                  disabled: null,
                  rows: null,
                  cols: null,
                  description: null,
                  hidden: null,
                  max: null,
                  min: null,
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  lookup: null,
                  reference: 'Verifica_Azienda',
                  classReference: 'Azienda',
                  originalClass: 'Verifica',
                  required: false,
                  tabindex: null,
                  readonly: null,
                  valueProp: null,
                  labelProp: null,
                  filterReference: ['UserName', '<>', ''],
                },
                fieldGroup: null,
                template: null,
                hideExpression:
                  '(formState[\'hiddenEsecutoreEsterno\']===true)||(model.Lookup && model.Lookup.TipoEsecutore?model.Lookup.TipoEsecutore.Code!=\'E\':true)',
                expressionProperties: {
                  'templateOptions.disabled':
                    'field.templateOptions.readonly||formState.disabled||formState[\'disableEsecutoreEsterno\']',
                  'templateOptions.options':
                    'formState.selectOptionsData?formState.selectOptionsData[\'EsecutoreEsterno\']:[]',
                },
              },
            ],
            template: null,
            hideExpression: null,
            expressionProperties: null,
          },
          {
            fieldGroupClassName: 'row',
            key: null,
            type: null,
            className: null,
            wrappers: null,
            templateOptions: null,
            fieldGroup: [
              {
                fieldGroupClassName: null,
                key: 'Domanda',
                type: 'input',
                className: 'col',
                wrappers: null,
                templateOptions: {
                  type: '',
                  label: 'Testo per esecutore',

                  required: false
                }

              },
            ],
            template: null,
            hideExpression: null,
            expressionProperties: null,
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {

                "key": "Peso",
                "type": "typeahead",
                "className": "col classPeso",
                "defaultValue": 519,
                "templateOptions": {

                  "type": "",
                  "label": "Peso",
                  "lookup": "Peso",

                  "originalClass": "VerificaRiga",
                  "required": false,

                },
                "expressionProperties": {
                  "templateOptions.disabled": "(false)||field.templateOptions.readonly||formState.disabled||formState['disablePeso']",
                  "templateOptions.options": "formState.selectOptionsData?formState.selectOptionsData['Peso']:[]"
                }
              }
            ]
          },
        ]
      },
    ];

    return f;

  }
  ngOnInit() {

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.fieldsAssetGestione = this.mapFields();
    console.log(this.fieldsAssetGestione)
    this.loadData();

    this.classService.getAllegati("Segnalazioni", this.id).subscribe((res) => {
      this.allegati = res.data
      console.log(this.allegati)
    });


  }

  autorizza(autorizzata) {
    if (autorizzata && (!this.cardComponent.model.Categoria)) {
      bootbox.alert("Attenzione categoria non selezionata")
      return;
    }
    bootbox.confirm("Sei sicuro di voler effettuare l'azione? Non sarà possibile cambiarla successivamente", (result) => {
      if (result) {
        this.cardComponent.model.Valid = autorizzata;

        if (autorizzata) {
          this.classService.getCardFiltered("Gestione", "CodeSegnalazione", this.cardComponent.model.Reference.Categoria.Code).subscribe((res: any) => {

            if (res.data.list.length > 0) {
              // SALVA SEGNALAZIONE
              const object = {
                Asset: this.cardComponent.cardId,
                Gestione: res.data.list[0].Id,
                Description: "Gestione segnalazione #" + this.cardComponent.cardId
              };
              this.classService.updateDettagliScheda('AssetGestione', object).subscribe((resAssetGest: any) => {
                this.classService.updateDettagliScheda("Segnalazioni", {
                  AssetGestione: resAssetGest.data.Id,
                  Id: this.cardComponent.cardId,
                  Valid: autorizzata
                }).subscribe((resSegnazione: any) => {
                  this.cancel(true);
                });

              });





            } else {
              //bootbox.alert("Nessuna gestione associata alla categoria della segnalazione");
              this.classService.updateDettagliScheda("Segnalazioni", {
                Id: this.cardComponent.cardId,
                Valid: autorizzata
              }).subscribe((resSegnazione: any) => {
                this.cancel(true);
              });
              return
            }

          });
        } else {
          this.classService.updateDettagliScheda("Segnalazioni", {
            Id: this.cardComponent.cardId,
            Valid: autorizzata
          }).subscribe((res: any) => {
            this.cancel(true)
          })
        }


      }
    })
  }

  setGestione() {
    this.codiceSegnalazione = "Codice: #" + this.cardComponent.model.Id;
    setTimeout(() => {

      this.classService.getAssetGestione(this.cardComponent.model.Id).subscribe((resAssetGestione) => {
        if(resAssetGestione.data.length > 0) {
          let assetGestione = resAssetGestione.data[0];
          this.oldGestione = null;
          this.modelAssetGestione.AssetGestione = assetGestione.IdGestione;
          this.assetGestioneForm.get("AssetGestione").setValue(assetGestione.IdGestione);

          if (assetGestione.Ruoli) {
            this.modelAssetGestione.Reference = {
              AssetGestione: {
                Ruolo3: assetGestione.Ruoli.esecuzione.Id,
                Ruolo2: assetGestione.Ruoli.vigilanza.Id,
                Ruolo1: assetGestione.Ruoli.responsabile.Id,
              }
            }
          }
        }
      })

    }, 100);

    //
  }
  formlyModelChange(e) {

    if (this.oldGestione != this.modelAssetGestione.AssetGestione) {
      // CAMBIO GESTIONE
      if (this.modelAssetGestione && this.modelAssetGestione.Reference) {

        this.modelAssetGestione.Esecutore = this.modelAssetGestione.Reference.AssetGestione.Ruolo3;
        this.assetGestioneForm.get("Esecutore").setValue(this.modelAssetGestione.Reference.AssetGestione.Ruolo3);

        this.modelAssetGestione.Vigilanza = this.modelAssetGestione.Reference.AssetGestione.Ruolo2;
        this.assetGestioneForm.get("Vigilanza").setValue(this.modelAssetGestione.Reference.AssetGestione.Ruolo2);

        this.modelAssetGestione.Responsabile = this.modelAssetGestione.Reference.AssetGestione.Ruolo1;
        this.assetGestioneForm.get("Responsabile").setValue(this.modelAssetGestione.Reference.AssetGestione.Ruolo1);
      }
    }
    this.oldGestione = this.modelAssetGestione.AssetGestione;

    this.classService.getBreadcrumb("Funzione", this.modelAssetGestione.Responsabile, "Tipo").subscribe((res) => {
      console.log(res.data);
      let funzioni = res.data.filter(x => x.labelClass == "Funzione");
      let primaFunzione = funzioni[0];

      this.modelAssetGestione.Funzione = primaFunzione.node.Id;
      this.assetGestioneForm.get("Funzione").setValue(primaFunzione.node.Id);
    });



  }

  concludi(p) {
    console.log(this.allegati);
    this.loading = true;
    const model = {
      Vigilanza: this.modelAssetGestione.Vigilanza,
      Responsabile: this.modelAssetGestione.Responsabile,
      Esecutore: this.modelAssetGestione.Esecutore ? this.modelAssetGestione.Esecutore : null,
      EsecutoreEsterno: this.modelAssetGestione.EsecutoreEsterno ? this.modelAssetGestione.EsecutoreEsterno : null,
      TipoEsecutore: this.modelAssetGestione.TipoEsecutore,
      Peso: this.modelAssetGestione.Peso,
      MessaggioEsecutore: this.modelAssetGestione.Domanda,
      Lavorazione:true
    }
    this.cardComponent.save(model).subscribe((res: any) => {
      const domanda = {
        'Code': '',
        'Description': this.cardComponent.model.Descrizione + (this.modelAssetGestione.Domanda ? " / " + this.modelAssetGestione.Domanda : ""),
        'Status': 'A',
        'Peso': model.Peso,
        'Target': null,
        'Scheda': 0,
        'Order': 1,
      };
      const data = {
        'data': {
          'Description': "Verifica segnalazione #" + this.cardComponent.cardId,
          'TipoTemplate': 2,
        },
        'relations_insert': {
          'Verifica_Domande': [domanda],

        },
        'relations_duplicate': {
          'Verifica_Domande': {},
        },
      };

      this.classService.duplicate('VerificaScheda', this.env.idVerificaSchedaSegnalazioni, data).subscribe((resDuplicate) => {
        console.log(resDuplicate);
        const dataVerifica = {
          Description: resDuplicate.data.Description,
          Asset: this.cardComponent.cardId,
          Azienda: null,
          Scheda: resDuplicate.data.Id,
          Data: new Date(),
          periodicity_type: null,
          periodicity: null,
          Vigilanza: this.modelAssetGestione.Vigilanza,
          Responsabile: this.modelAssetGestione.Responsabile,
          Esecutore: this.modelAssetGestione.Esecutore ? this.modelAssetGestione.Esecutore : null,
          EsecutoreEsterno: this.modelAssetGestione.EsecutoreEsterno ? this.modelAssetGestione.EsecutoreEsterno : null,
          TipoEsecutore: this.modelAssetGestione.TipoEsecutore,
          AssetGestione: this.cardComponent.model.AssetGestione,
          Tipo: 844050,
          Ambito: 627756,
          Cantiere: null,
        };
        this.classService.updateDettagliScheda('Verifica', dataVerifica).subscribe((resVerifica: any) => {
          if (this.allegati && this.allegati.length>0) {
            this.allegati.forEach(element => {
              this.classService.downloadAllegato(element.Key).subscribe((resDownload: any) => {
                var reader = new FileReader();
                var value: any;
                reader.readAsDataURL(resDownload);
                reader.onload = () => {
                  value = {
                    File: {
                      IdFile: -1,
                      Name: "AllegatoSegnalazione" + element.Extension,
                      Size: resDownload.size,
                      FilePicked: { content: reader.result },
                      Base64: reader.result
                    }
                  };
                  this.fileUploadService.addClassAllegato(resVerifica.data.Id, 0, "FREE", "Allegato segnalazione", "FREE", value.File, null, null, false).subscribe((resUpload) => {
                    console.log(resUpload);
                    this.loading = false;
                    this.cancel(true);
                  });
                }
              });
            });
          } else {
            this.loading = false;
            this.cancel(true);
          }

        });
      });

    });
  }

  cancel(t = false) {
    this.ref.close(t)
  }

  submit() {
    let myModel = this.cardComponent.model
    delete myModel.Reference;
    delete myModel.Lookup;
    delete myModel.Summary;
    this.classService.updateDettagliScheda("Segnalazioni", myModel).subscribe((response) => {
      if (response.status == 'success') {
        notify(
          'Associazione effettuata',
          'succes',
        ); console.log(response);
      } else {
        notify('Errore nel salvataggio', 'error');
        console.log(response);
      }
    });
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + "px", 'height': this.height + "px" };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + "px", 'height': (this.height * 0.8) + "px" };
  }

  loadData(): void {
  }


  refresh() {

  }


  downloadAllegato(data) {
    this.classService.downloadAllegato(data.key.Key).subscribe((res) => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = data.key.Filename;
      a.href = URL.createObjectURL(res);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }),
      (error) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  // anteprimaImmagine(data)
  // {
  //   console.log(data);
  //   this.dialogService
  //     .open(ModalImageComponent, {
  //       context: {

  //       },
  //     })
  //     .onClose.subscribe((res) => {
  //     });

  // }


}
