import { filter, last } from 'rxjs/operators';
import { UsersService } from './../../@core/backend/common/services/users.service';
import { Component, OnInit, Input } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbDialogService } from '@nebular/theme';
import { ModalAssetDetailsComponent } from '../modal-asset-details/modal-asset-details.component';
import { FormGroup, FormBuilder, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ModalChecklistComponentComponent } from '../modal-checklist-component/modal-checklist-component.component';
import Utils from '../../@core/utils/utils';
import { Allegato, CategoriaAllegati } from '../../@core/interfaces/allegati';
import { SettingsService } from '../../@core/backend/common/services/settings.service';
import { ModalImageComponent } from '../modal-image/modal-image.component';


@Component({
  selector: 'ngx-form-checklist-component',
  templateUrl: './form-checklist-component.component.html',
  styleUrls: ['./form-checklist-component.component.scss'],
})
export class FormChecklistComponentComponent implements OnInit {
  @Input() verificaId: number = 0;
  @Input() schedaId: any = 0;
  @Input() mode: string = 'create';
  @Input() addItems: boolean = false;
  @Input() isPresaIncarico: boolean = false;
  Arr = Array;
  config: any;
  rowCounter = 0;
  peso: any = [];
  gruppoForm: FormGroup;
  cfgOptionsQualita: any[];
  cfgOptionsCheck: any[];
  templateDomanda: any = {
    idDomanda: 0,
    domanda: "",
    peso: 2,
    level: 1,
    isLeaf: true,
    ParentId: 0,
    alertQualita: {
      code: 0,
      description: ""
    },
    isCustom: true
  }
  optionValueChanges$;
  cfgBlocchi: any[];
  modelNote: any = {};
  configTinymce: any = {
    height: '100%',
    menubar: false,
    branding: false,
    elementpath: false,
    plugins:
      'print preview fullpage searchreplace  directionality visualblocks visualchars codesample  charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount colorpicker textpattern',
    toolbar:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | print',
    language_url: '/assets/tinymce/language/it.js',
  };
  tabs: any[] = [
    {
      id: 0,
      text: 'Verifica',
      icon: 'comment',
    },
    {
      id: 1,
      text: 'Allegati',
      icon: 'link',
    },
    {
      id: 2,
      text: 'Note',
      icon: 'edit',
    },
  ];
  tabContent: any;
  files: any[] = [];
  files2: any[] = [];
  allegatiVerifiche: Allegato[];
  accessDenied: boolean;
  cfgHeader: any[];
  cfFooter: any[];
  scheda: any;
  ptGroup: any[];
  errorVerificaPersonale: any;
  errorCantiere: any;

  popupPreviewBase64: any;
  popupPreviewVisible: boolean;
  dataSourceGallery: string[] = [];
  isRifiuti: boolean = false;
  isIdoneita: boolean = false;
  asset: any;

  logVerifica:any = {
    mansioni: []
  }
  erroreBozza: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private classService: GenericClassService,
    private dialogService: NbDialogService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private settingService: SettingsService,
  ) {

  }

  ngOnInit() {
    console.log('INIT VERIFICA');
    this.spinner.show('cardSpinner');
    this.scheda = { data: null };
    let y = {};
    this.classService.lookupPesi().subscribe((res: any) => {
      res.data.list.forEach((x: any) => {
        y = {
          Code: parseInt(x.Code),
          Description: x.Description
        };
        this.peso.push(y);
      });
      this.peso.sort((a: any, b: any) => (a.Code - b.Code));

    });

    if (this.schedaId) {
      this.classService
        .getDettagliScheda('Verifica', this.schedaId)
        .subscribe((schedaRes) => {
          this.scheda = schedaRes;
          this.modelNote.Notes = schedaRes.data.Notes;
          this.loadData(schedaRes.data.Scheda);
        });
    } else {
      this.loadData(this.verificaId);
    }
  }
  loadData(verificaId) {
    this.usersService.getCurrentUser().subscribe((currentUser) => {

      if (!currentUser['data'] || !currentUser['data']['persona']) {
        this.accessDenied = true;
        this.spinner.show('noaccess');

        return;
      }

      this.verificaId = verificaId;
      if (verificaId) {
        this.tabContent = 0;

        this.config = {};
        forkJoin(
          this.classService.getDettagliScheda('VerificaScheda', verificaId),
          of(this.scheda),
          this.classService.getDomande(
            (this.scheda && this.scheda.data.Eseguita) || (this.scheda.data.Reference.Scheda.Code == '99-Rifiuti')
              ? this.schedaId
              : this.verificaId,
          ),
          this.classService.getRelations(
            'VerificaScheda',
            this.verificaId,
            'Map_VerificaSchedaBlocco',
          ),
          this.classService.getHeaderFooterScheda(this.schedaId),
          this.classService.verificaScheda(currentUser['data']['persona']['IdClass'], currentUser['data']['persona']['Id']),
          this.classService.getAllegati("Verifica", this.schedaId),
          this.scheda.data.Reference && this.scheda.data.Reference.Asset && this.scheda.data.Reference.Asset.IdClass ? this.classService.getDettagliScheda(this.scheda.data.Reference.Asset.IdClass, this.scheda.data.Reference.Asset.Id) : of(null)
        ).subscribe(
          ([
            verificaRes,
            schedaRes,
            domandaRes,
            blocchiRes,
            headerFooterRes,
            verificaPersonaleRes,
            allegatiRes,
            assetRes
          ]) => {
            this.logVerifica.verifica = verificaRes.data.Description
            console.log('CARICATI DATI ESTERNI....');
            this.asset = assetRes ? assetRes.data : null;

            if (verificaRes && verificaRes.verifica && verificaRes.verifica.Code == "99-Rifiuti") {
              this.isRifiuti = true;
            } else if (verificaRes && verificaRes.data && verificaRes.data.Code == "99-Rifiuti") {
              this.isRifiuti = true;
            }

            if(verificaRes && verificaRes.data && verificaRes.data.Persona != null) {
              this.isIdoneita = true;
            }

            // ACCESSO SCHEDA VERIFICA
            const rolesAdmin = currentUser['data'].roles.filter(x => x.role.code == 'admin');
            const isAdmin = rolesAdmin && rolesAdmin.length > 0;
            this.errorVerificaPersonale = null;
            this.errorCantiere = null;
            schedaRes = schedaRes.data;
            if (!isAdmin) {
              if (schedaRes.EsecutoreEsterno) {
                // UTENTE ESTERNO
                const a1 = schedaRes.Reference.EsecutoreEsterno.UserName; // username esecutore esterno
                const a2 = currentUser['data']['email']; // username utente loggato
                this.logVerifica.anagrafica = currentUser['data']['persona'];
                this.logVerifica.sistema = currentUser['data']['roles'];
                this.logVerifica.TipoUtente = "Utente esterno"
                this.logVerifica.EmailSistema = currentUser['data']['email'];
                if (a1 != a2) {
                  var foundMansioni;
                  var ruoli = [schedaRes.Vigilanza, schedaRes.Responsabile, schedaRes.Esecutore];
                  if (this.isRifiuti) {
                    ruoli.push(schedaRes.Reference.Vigilanza.Id)
                  }
                  let foundMansioniAll = [];
                  ruoli.forEach(ruolo => {

                    const mansioni = currentUser['data']['persona']['mansioni'];

                    // ************************************************************************************************
                    // abilita accesso al personale esterno
                    // simulando una mansione con ruolo e unità pr null
                    if (currentUser['data']['persona']['RuoloApplicativo']) {
                      let mansionePersonaEsterna={
                        IdRuolo: currentUser['data']['persona']['RuoloApplicativo'],
                        IdUnitaPr:null,
                        validUbicazioni:[]
                      }
                      mansioni.push(mansionePersonaEsterna);
                    }
                    // ************************************************************************************************

                    let idUbicazione = null;
                    if (schedaRes && schedaRes.Reference && schedaRes.Reference.Asset) {
                      idUbicazione = schedaRes.Reference.Asset.Ubicazione;
                    }
                    if (mansioni.length > 0) {
                      foundMansioni = mansioni.filter(
                        (x) => (x.IdRuolo == ruolo && x.validUbicazioni.includes(idUbicazione)) || (x.IdRuolo == ruolo && x.IdUnitaPr === null),
                      );
                      if (foundMansioni && foundMansioni.length > 0) {
                        foundMansioniAll.push(foundMansioni);
                        this.logVerifica.mansioni.push(foundMansioni[0]);
                      }
                    }

                  });
                  if (foundMansioniAll.length == 0) {
                    this.accessDenied = true;
                    this.spinner.show('noaccess');

                    return;
                  }
                }

              } else {
                // UTENTE INTERNO
                var ruoli = [schedaRes.Vigilanza, schedaRes.Responsabile, schedaRes.Esecutore];
                if (this.isRifiuti) {
                  ruoli.push(schedaRes.Reference.Vigilanza.Id)
                }
                let foundMansioni = null;
                let foundMansioniAll = [];
                this.logVerifica.anagrafica = currentUser['data']['persona'];
                this.logVerifica.sistema = currentUser['data']['roles']
                this.logVerifica.TipoUtente = "Utente interno"
                this.logVerifica.EmailSistema = currentUser['data']['email'];
                ruoli.forEach(ruolo => {
                  //const ruolo = schedaRes.Esecutore;
                  let idUbicazione = null;
                  if (schedaRes && schedaRes.Reference && schedaRes.Reference.Asset) {
                    idUbicazione = schedaRes.Reference.Asset.Ubicazione;
                  }
                  const mansioni = currentUser['data']['persona']['mansioni'];
                  // ************************************************************************************************
                    // abilita accesso al personale esterno
                    // simulando una mansione con ruolo e unità pr null
                    if (currentUser['data']['persona']['RuoloApplicativo']) {
                      let mansionePersonaEsterna={
                        IdRuolo: currentUser['data']['persona']['RuoloApplicativo'],
                        IdUnitaPr:null,
                        validUbicazioni:[]
                      }
                      mansioni.push(mansionePersonaEsterna);
                    }

                    // ************************************************************************************************

                  if (mansioni && mansioni.length > 0) {
                    foundMansioni = mansioni.filter(
                      (x) => (x.IdRuolo == ruolo && x.validUbicazioni.includes(idUbicazione)) || (x.IdRuolo == ruolo && x.IdUnitaPr === null),
                    );
                    if (foundMansioni && foundMansioni.length > 0) {
                      foundMansioniAll.push(foundMansioni);
                      this.logVerifica.mansioni.push(foundMansioni[0]);
                    }
                  }
                });
                var isResponsabileAmbientale = false;
                this.settingService.getApp().subscribe((resSettings) => {
                  console.log(resSettings)
                  const emailRespAmbientale = resSettings.data.responsabileAmbientale;
                  this.usersService.getCurrentUser().subscribe((resUser: any) => {
                    var emailLoggedUser = null;
                    if (resUser.data && resUser.data.persona) {
                      emailLoggedUser = resUser.data.persona.Email;
                    }

                    if (emailRespAmbientale == emailLoggedUser) {
                      isResponsabileAmbientale = true;
                    }

                    if ((!foundMansioniAll || foundMansioniAll.length == 0) && !isResponsabileAmbientale) {
                      this.accessDenied = true;
                      this.spinner.show('noaccess');
                      return;
                    }
                    const isBozza = schedaRes.IdPersonaEsecutore ? true : false;
                    const idRuoloEsecutore = schedaRes.IdRuoloEsecutore;
                    const idRuoloUtente = currentUser['data']['roles'][0]['roleId'];
                    const idPersonaEsecutore = schedaRes.IdPersonaEsecutore;
                    const idPersonaUtente = currentUser['data']['persona']['Id'];
                    if(!schedaRes.Eseguita && isBozza && idRuoloEsecutore == idRuoloUtente && idPersonaEsecutore != idPersonaUtente) {
                      this.accessDenied = true;
                      this.erroreBozza = true;
                      this.spinner.show('noaccess');
                      return;
                    }



                  });
                });




                //CONTROLLO ALLEGATI SU PERSONALE INTERNO
                if (this.mode == "create" && verificaPersonaleRes.data && (verificaPersonaleRes.data.allegatiScaduti.length > 0 || verificaPersonaleRes.data.defaultAllegati.length > 0)) {
                  this.accessDenied = true;
                  this.errorVerificaPersonale = {
                    allegatiScaduti: verificaPersonaleRes.data.allegatiScaduti,
                    defaultAllegati: verificaPersonaleRes.data.defaultAllegati
                  };
                  this.spinner.show('noaccess');
                  return;
                }
                //CONTROLLO IDONEITA
                if (this.mode == "create" && verificaPersonaleRes.data.mansioniNonIdonee.length > 0) {
                  // controllo se le mansioni della scheda di verifica sono non idonee
                  var idsNonIdonee = foundMansioni.map(x => x.Id);
                  var nonIdonee = verificaPersonaleRes.data.mansioniNonIdonee.filter(x => idsNonIdonee.includes(x.Id));

                  if (nonIdonee && nonIdonee.length > 0) {
                    this.accessDenied = true;
                    this.errorVerificaPersonale = {
                      mansioniNonIdonee: nonIdonee
                    };
                    this.spinner.show('noaccess');
                    return;
                  }
                }
              }
            }
            // CONTROLLO CANTIERE IN CORSO
            const cantiereAsset = schedaRes.Reference.Asset ? schedaRes.Reference.Asset.Cantiere : null;
            if (cantiereAsset) {
              var dateStart = new Date(schedaRes.Reference.Asset.Reference.Cantiere.DateStart);
              var dateEnd = new Date(schedaRes.Reference.Asset.Reference.Cantiere.DateEnd ? schedaRes.Reference.Asset.Reference.Cantiere.DateEnd : new Date('December 17, 2060 03:24:00'));
            } else {
              var dateStart = new Date('December 17, 1995 03:24:00');
              var dateEnd = new Date('December 17, 2060 03:24:00');
            }
            var today = new Date();
            if (this.mode == "create" && schedaRes.Cantiere != cantiereAsset && schedaRes.Reference.Scheda.Code != '06-Chiusura NC' && (today >= dateStart && today <= dateEnd)) {
              // scheda generata su un cantiere
              this.accessDenied = true;
              this.errorCantiere = "Operazione non consentita durante un Cantiere Attivo";
              if (schedaRes.Cantiere) {
                this.errorCantiere = "Operazione non consentita su un Cantiere Chiuso";
              }
              this.spinner.show('noaccess');
            }

            let responseGestioni;
            let responseRigheOriginal;
            // caricamento allegati
            this.allegatiVerifiche = new Array();
            allegatiRes.data.forEach(element => {
              const allegato: Allegato = {
                Id: element.Id,
                Code: element.Code,
                Description: element.Description,
                IsRequired: element.Required,
                Categoria: element.Categoria,
                File: {
                  IdFile: 0,
                  Name: element.Filename,
                  Size: element.Filesize,
                  DownloadUrl: element.Key,
                  Base64: element.Mimetype,
                }
              }
              this.allegatiVerifiche.push(allegato);
            });

            this.allegatiVerifiche.forEach((allegato: any) => {
              if (allegato.File.Base64.includes("image")) {
                this.classService.downloadAllegato(allegato.File.DownloadUrl).subscribe((resDownload: any) => {
                  var reader = new FileReader();
                  var value: any;
                  reader.readAsDataURL(resDownload);
                  reader.onload = () => {
                    allegato.base64 = reader.result;
                  }
                });
              } else {
                allegato.base64 = "notImg";
              }
            });
            console.log(this.allegatiVerifiche);
            const request = [];

            request.push(this.classService.getDomande(schedaRes.OriginalId || 0));

            const idObject = schedaRes.Asset || schedaRes.Azienda || null;
            if (idObject && schedaRes.Asset) {
              // request 1 - gestioni
              request.push(this.classService.getAssetGestione(idObject));

              // Ciclo domanda per includere dati target
              // request  - se target
              console.log('load data target...');
              domandaRes.data.forEach((domanda) => {
                if (domanda.Target) {
                  if (idObject)
                    request.push(
                      this.classService.getAssetTarget(idObject, domanda.Target),
                    );
                }
              });
            }
            if (idObject && schedaRes.Azienda) {
              // request 1 - gestioni
              request.push(this.classService.getAssetGestioneAzienda(idObject));

              // Ciclo domanda per includere dati target
              // request  - se target
              console.log('load data target azienda...');
              domandaRes.data.forEach((domanda) => {
                if (domanda.Target) {
                  if (idObject)
                    request.push(
                      this.classService.getAssetTargetAzienda(idObject, domanda.Target),
                    );
                }
              });
            }

            // Blocco motivazione
            if (
              verificaRes.data.TipoBase ==
              '08 - Scheda Tipo 8 - Procedura - Metrici e Qualitativi'
            ) {
              const bloccoM = {
                ObjValue: {
                  Code: 'motivazione_NC',
                  Description: 'motivazione non conformità',
                  _Tipo_Code: 'Text',
                  InLine: true,
                  IfNC: true,
                },
              };
              blocchiRes.data.push(bloccoM);
            }
            if ((verificaRes.data.TipoBase == '09 - Scheda Tipo 9 - STRADE') || (verificaRes.data.TipoBase == '10 - Scheda Tipo 10 - STRADE')) {
              this.addItems = true;
            }

            forkJoin(request).subscribe(
              (response) => {
                responseRigheOriginal = response.shift();
                // responseRigheOriginal = responseRigheOriginal.data.filter(x => x.AlertCode > 0);
                responseRigheOriginal = responseRigheOriginal.data;

                if (response.length > 0) {
                  responseGestioni = response.shift();
                  responseGestioni = responseGestioni.data;
                } else {
                  responseGestioni = [];
                }


                const responseTarget = response;
                let count = 0;
                responseTarget.forEach((target: any) => {
                  if (target.data.count) {
                    domandaRes.data[count].target = target.data.list[0];
                  } else {
                    domandaRes.data[count].toDelete = true;
                  }
                  count++;
                });
              },
              () => {
                console.log('ERRORE DATI ESTERNI....');
              },
              () => {
                console.log('ELABORAZIONE DATI ESTERNI....');
                domandaRes.data = domandaRes.data.filter((x) => !x.toDelete);


                //
                if (
                  verificaRes.data.TipoBase == '06 - Scheda Tipo 6 - Chiusura NC' &&
                  responseRigheOriginal &&
                  responseRigheOriginal.length > 0
                ) {
                  const allRisposteOriginal = [...responseRigheOriginal];
                  responseRigheOriginal = responseRigheOriginal.filter(
                    (x) => x.AlertCode > 0,
                  );

                  const domandeOther = [...domandaRes.data];
                  domandaRes.data = [];
                  responseRigheOriginal.forEach((element) => {
                    this.addDomandaWithParent(element, domandaRes, allRisposteOriginal);
                  });

                  // MERGE DOMANDE
                  domandaRes.data.forEach(element => {
                    const foundOther = domandeOther.find(x => x.Id == element.Id);
                    if (foundOther) {
                      element.idrisposta = foundOther.idrisposta;
                      element.risposte = foundOther.risposte;
                    }
                  });
                  //domandaRes.data = [...domandaRes.data, ...domandeOther];



                }

                // domande -> tree
                const treeRoot = Utils.unflatten(domandaRes.data);

                const preorder = Utils.traverseDFS(treeRoot, 'pre');
                preorder.shift();
                domandaRes.data = preorder;

                this.config = {
                  verifica: verificaRes.data,
                  scheda: schedaRes,
                  domanda: domandaRes.data,
                  blocchi: blocchiRes.data,
                  righeOriginal: responseRigheOriginal,
                  gestioni: responseGestioni,
                  headerFooter: headerFooterRes.data,
                };

                this.initGruppoForm();
                console.log(this.config);

                // initialize stream on units
                this.optionValueChanges$ = this.gruppoForm.controls[
                  'domande'
                ].valueChanges;
                // subscribe to the stream so listen to changes on units
                this.optionValueChanges$.subscribe((domande) =>
                  this.updateAlertQualita(domande),
                );

                // force update at loading

                this.updateAlertQualita(this.domande.value);
                console.log(this.domande.controls);
                this.sendLog("view");
                this.spinner.hide('cardSpinner');
              },
            );
          },
        );
      }
    })



  }

  public sendLog(action) {
    this.usersService.getCurrentUser().subscribe((currentUser) => {
      const rolesAdmin = currentUser['data'].roles.filter(x => x.role.code == 'admin');
      const isAdmin = rolesAdmin && rolesAdmin.length > 0;
      if(!isAdmin) {
        const param = {
          Nome: this.logVerifica.anagrafica.Description,
          EmailPersonale: this.logVerifica.anagrafica.Email,
          IdMansione: this.logVerifica.mansioni[0].Id,
          IdRuolo: this.logVerifica.mansioni[0].IdRuolo,
          IdUnitaPr: this.logVerifica.mansioni[0].IdUnitaPr,
          Action: action,
          RuoloLabel: this.logVerifica.mansioni[0].Ruolo,
          UnitaPrLabel: this.logVerifica.mansioni[0].UnitaPr,
          TipoUtente: this.logVerifica.TipoUtente,
          EmailSistema: this.logVerifica.EmailSistema,
          RuoloSistema: this.logVerifica.sistema[0].role.Description,
          IdRuoloSistema: this.logVerifica.sistema[0].roleId,
          IdVerifica: parseInt(this.schedaId),
          VerificaLabel: this.logVerifica.verifica,
          DateTime: new Date()
        }
        this.classService.updateDettagliScheda("VerificheUtenti",param).subscribe((res:any)=>{
          console.log("log saved succefully");
        },(error)=>{
          console.log("log not implemented yet")
        });
      }
    });
  }

  previewImage(src) {
    this.dialogService.open(ModalImageComponent, {
      closeOnEsc: false,
      context: {
        src: src
      },
      hasBackdrop: true,
    })
      .onClose.subscribe(() => { });
  }

  addDomandaWithParent(element: any, domandaRes: any, allDomande: any) {
    const domandaToCopy = {
      Id: element.Id,
      Description: element.Description,
      _Peso_Code: element.Peso,
      ParentId: element.ParentId,
    };

    domandaRes.data.push(domandaToCopy);

    if (domandaToCopy.ParentId) {
      const domandaParent = allDomande.find(x => x.Id == domandaToCopy.ParentId);
      this.addDomandaWithParent(domandaParent, domandaRes, allDomande);
    }
  }
  selectTab(e) {
    this.tabContent = this.tabs[e.itemIndex].id;
  }
  // GESTIONE FORM ***************************************
  addCounter() {
    this.rowCounter += 1;
    return this.rowCounter;
  }
  initGruppoForm() {
    this.gruppoForm = this.fb.group({
      domande: this.fb.array([]),
      summary: {},
    });
    this.initDomande();
    if (this.mode == 'view') {
      this.gruppoForm.disable();
    }
  }
  initDomande() {
    const cfgBlocchi = [];
    const cfgHeader = [];
    const cfFooter = [];
    this.config.blocchi.forEach((bloccoItem) => {
      const obj = bloccoItem.ObjValue;
      const cfgOptions = [];
      if (obj['_Tipo_Code'] == 'Radio' || obj['_Tipo_Code'] == 'Check') {
        // gruppo options
        if (obj.Options) {
          obj.Options.split('|').forEach(function (x) {
            const arr = x.split(':');
            const obj1 = {
              key: arr[0],
              description: arr[1] || '',
              color: arr[2] || 'secondary',
            };
            arr[0] && cfgOptions.push(obj1);
          });
          obj.cfgOptions = cfgOptions;
        }
      } else if (obj['_Tipo_Code'] == 'Text') {
      } else if (obj['_Tipo_Code'] == 'Number') {
      }
      if (!obj['PosizioneScheda']) cfgBlocchi.push(obj);
      else if (obj['_PosizioneScheda_Code'] == 'H') {

        const risposta = this.config.headerFooter.find(
          (x) => x.Chiave == 'header_' + obj.Code,
        );
        if (risposta) {
          obj.valore = obj['_Tipo_Code'] == 'Check' ? JSON.parse(risposta.Valore) : risposta.Valore;
        } else if (obj['_Tipo_Code'] == 'Check') {
          obj.valore = {};
        }
        cfgHeader.push(obj);
      } else if (obj['_PosizioneScheda_Code'] == 'F') {
        const risposta = this.config.headerFooter.find(
          (x) => x.Chiave == 'footer_' + obj.Code,
        );
        if (risposta) {
          obj.valore = obj['_Tipo_Code'] == 'Check' ? JSON.parse(risposta.Valore) : risposta.Valore;
        } else if (obj['_Tipo_Code'] == 'Check') {
          obj.valore = {};
        }
        cfFooter.push(obj);
      }
    });
    this.cfgBlocchi = cfgBlocchi.sort((a, b) =>
      a.Posizione > b.Posizione ? 1 : b.Posizione > a.Posizione ? -1 : 0,
    );
    this.cfgHeader = cfgHeader.sort((a, b) =>
      a.Posizione > b.Posizione ? 1 : b.Posizione > a.Posizione ? -1 : 0,
    );
    this.cfFooter = cfFooter.sort((a, b) =>
      a.Posizione > b.Posizione ? 1 : b.Posizione > a.Posizione ? -1 : 0,
    );



    // creo righe domande
    const controls = [];

    this.config.domanda.forEach((domandaItem) => {
      controls.push(this.newDomanda(domandaItem));
    });
    this.gruppoForm.setControl('domande', this.fb.array(controls));
  }
  get domande(): FormArray {
    return this.gruppoForm.get('domande') as FormArray;
  }
  isRigaValid(gruppoForm: FormGroup) {
    if (gruppoForm) {
      const formRiga = gruppoForm;//.get('alertQualita');
      if (formRiga) {
        const valoriRiga = formRiga.value;

        if (valoriRiga && valoriRiga.valid != undefined) {
          if (!valoriRiga.valid && valoriRiga.invalidFields && valoriRiga.invalidFields.length > 0) {
            // SE NON è VALIDO E HO LISTA DEI CAMPI INVALID
            valoriRiga.invalidFields.forEach(element => {
              gruppoForm.get(element).setErrors({ 'incorrect': true });
              gruppoForm.get(element).markAsTouched();
            });
          } else if (valoriRiga.valid) {
            // SE VALIDO RESETTO STATO TUTTI I CAMPI
            for (const field in gruppoForm.controls) {
              if (gruppoForm.controls.hasOwnProperty(field)) {
                const control = gruppoForm.get(field);

                control.setErrors(null);
                control.markAsTouched();
              }
            }
          }
          return valoriRiga.valid ? null : { errorRigaValid: 'formula non valida' };
        }
      }
    }
    return null;
  }

  newDomanda(classItem, objRiga = null): FormGroup {
    if (!objRiga) {

      objRiga = {
        idDomanda: classItem.Id,
        domanda: ((classItem.Description || '') + (classItem.DescriptionFull || '')) || classItem.Domanda || '',
        peso: +classItem._Peso_Code || classItem.Peso || 0,
        alertQualita: false,
        isLeaf: classItem.isLeaf,
        ParentId: classItem.ParentId,
        level: classItem.level,
      };
    }

    if (objRiga.isLeaf) {
      // GESTIONE BLOCCO TARGET
      let new_blocchi = null;
      this.cfgBlocchi.forEach((blocco) => {
        if (blocco['_Tipo_Code'] == 'TARGET') {
          new_blocchi = [
            {
              Code: 'UM',
              Description: 'U.M.',
              Obbligatorio: null,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'TARGET',
              align: 'left',
            },
            {
              Code: 'Valore',
              Description: 'Obbiettivo',
              Obbligatorio: null,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'TARGET',
              align: 'left',
            },
            {
              Code: 'ValoreRilevato',
              Description: 'Valore rilevato',
              Obbligatorio: true,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'Number',
            },
            {
              Code: 'ValoreMin',
              Description: 'Val.minimo',
              Obbligatorio: null,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'TARGET',
              align: 'right',
            },
            {
              Code: 'ValoreMax',
              Description: 'Val.massimo',
              Obbligatorio: null,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'TARGET',
              align: 'right',
            },
            {
              Code: 'ScostMax',
              Description: 'Scost.accettabile',
              Obbligatorio: null,
              Posizione: 1,
              InLine: null,
              _Tipo_Code: 'TARGET',
              align: 'right',
            },
          ];
        }
      });
      if (new_blocchi) this.cfgBlocchi = new_blocchi;
      // CREAZIONE FORM DA BLOCCHI
      this.cfgBlocchi.forEach((blocco) => {
        if (blocco['_Tipo_Code'] == 'Radio') {
          objRiga['opzioni_' + blocco.Code] = blocco.Options;
          objRiga['valori_' + blocco.Code] = blocco.Options
            ? this.fb.control(
              '',
              blocco.Obbligatorio ? [Validators.required] : null,
            )
            : '';
        } else if (blocco['_Tipo_Code'] == 'Check') {
          objRiga['opzioni_' + blocco.Code] = blocco.Options;
          let checks;
          if (blocco.cfgOptions) {
            checks = {};
            blocco.cfgOptions.forEach((element) => {
              checks[element.key] = this.fb.control(false);
            });
          }
          objRiga['valori_' + blocco.Code] = checks
            ? this.fb.group(
              checks,
              blocco.Obbligatorio ? [Validators.required] : null   ,
            )
            : '';
        } else {
          objRiga['valori_' + blocco.Code] = this.fb.control(
            '',
            blocco.Obbligatorio ? [Validators.required] : null,
          );
        }
      });

      // SE LA DOMANDA HA RISPOSTE CARICO VALORI NEL FORM
      const domandaConfig = this.config.domanda.find(
        (x) => x.Id == objRiga.idDomanda,
      );
      if (domandaConfig && domandaConfig.risposte) {
        domandaConfig.risposte.forEach((element) => {
          if (element.Chiave.startsWith('valori_')) {
            const valore = element.Valore.startsWith('{')
              ? JSON.parse(element.Valore)
              : element.Valore;
            if (objRiga && objRiga[element.Chiave])
              objRiga[element.Chiave].setValue(valore);
          }
        });
      }
    }
    const riga = this.fb.group(objRiga);
    riga.setValidators(this.isRigaValid);
    return riga;
  }
  updateAlertQualita(domande: any) {
    const control = <FormArray>this.gruppoForm.controls['domande'];
    if (control.length == 0) return;

    // tslint:disable-next-line: forin
    for (const i in domande) {
      if (this.config.verifica.alertQualita) {
        // alert
        const expression = this.calculateExpression(
          this.config.verifica.alertQualita,
          domande[i],
        );

        if (control.at(+i).get('alertQualita')) {
          control
            .at(+i)
            .get('alertQualita')
            .setValue(expression, { emitEvent: false });
        }
      }

      // dati target
      const cfgDomanda = this.config.domanda.filter(
        (x) => x.Id == domande[i].idDomanda,
      );

      this.cfgBlocchi.forEach((blocco) => {
        try {
          if (blocco['_Tipo_Code'] == 'TARGET') {
            if (cfgDomanda[0].target && cfgDomanda[0].target[blocco.Code]) {
              control
                .at(+i)
                .get('valori_' + blocco.Code)
                .setValue(cfgDomanda[0].target[blocco.Code], { emitEvent: false });
            } else {
              control
                .at(+i)
                .get('valori_' + blocco.Code)
                .setValue(cfgDomanda[0]['_Target_' + blocco.Code], { emitEvent: false });
            }

          }
        } catch (e) {

        }
      });

      // Summary
      let pt = 0;
      this.ptGroup = [];
      let status = 0;
      this.gruppoForm.value.domande.forEach(x => {
        // CALCOLO PUNTEGGIO
        if (x.alertQualita && x.alertQualita.pt) {
          pt = pt + x.alertQualita.pt;

        }
        // CALCOLO STATUS
        if (x.alertQualita === true) {
          status = 1; // NONCONFORME-INACCETTABILE
        } else if (x.alertQualita && x.alertQualita.code) {
          if (x.alertQualita.code == 1) {
            // NONCONFORME-INACCETTABILE
            status = 1;
          } else if (status != 1) {
            // ACCETTABILE
            status = 2;
          }
        }
        if ((x.alertQualita === true || x.alertQualita.code == 1) && x.ParentId) {
          this.ptGroup[x.ParentId] = (this.ptGroup[x.ParentId] ? this.ptGroup[x.ParentId] : 0) + 1;
        }
      });

      this.gruppoForm.get('summary').setValue({
        alert: this.gruppoForm.value.domande.filter(
          (x) =>
            x.alertQualita === true ||
            (x.alertQualita.code && x.alertQualita.code > 0),
        ).length,
        status: status,
        pt: pt,
        domande: domande.length,
      });
    }
  }
  calculateExpression(f: any, o: any) {
    'use strict';
    const m = o;
    const result = eval(f)
    // prima return result(f);
    return result;
  }
  // BUTTON ACTION ***************************************
  openCard(data = null) {
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }

    console.log(this.config.scheda.Reference.Asset.IdClass)
    this.dialogService
      .open(ModalAssetDetailsComponent, {
        closeOnEsc: false,
        context: {
          mode: 'VIEW',
          className: this.config.scheda.Asset ? (this.config.verifica.Code == 'S1-Segnalazioni' ? 'Segnalazioni' : (this.config.scheda.Reference.Asset.IdClass ? this.config.scheda.Reference.Asset.IdClass : 'Asset') ) : 'Azienda',
          cardId: this.config.scheda.Asset
            ? this.config.scheda.Asset
            : this.config.scheda.Azienda,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe(() => { });
  }
  openCardPersonale(data = null) {
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log(this.config)
    this.dialogService
      .open(ModalAssetDetailsComponent, {
        closeOnEsc: false,
        context: {
          mode: 'VIEW',
          className: 'Personale',
          cardId: this.config.scheda.Persona,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe(() => { });
  }
  openVerificaOriginal(data = null) {
    if (data) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.dialogService
      .open(ModalChecklistComponentComponent, {
        closeOnEsc: false,
        context: {
          titleModal: 'Visualizzazione scheda verifica',
          verificaId: this.config.scheda.OriginalId,
          schedaId: this.config.scheda.OriginalId,
          mode: 'create',
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((res) => { });
  }
  isValid() {
    return this.gruppoForm && this.gruppoForm.valid;
  }
  get checklistForm() {
    const data = { ...this.gruppoForm.value };
    data.domande.forEach(item => {
      if (typeof item.peso === 'object' && item.peso !== null) {
        item.peso = item.peso.Code;
      }
    });
    console.log(data);
    data.config = this.config;
    if (this.cfgHeader.length > 0) {
      data.header = {};
      this.cfgHeader.forEach((x) => {
        data.header[x.Code] = x.valore;
      });
    }
    if (this.cfFooter.length > 0) {
      data.footer = {};
      this.cfFooter.forEach((x) => {
        data.footer[x.Code] = x.valore;
      });
    }
    data.config.scheda.Notes = this.modelNote.Notes;
    return data;
  }
  get allegati() {
    return this.files;
  }

  isObject(val): boolean {
    return typeof val.value === 'object';
  }
  onValueChanged(e) {
    this.files2.forEach((file) => {
      console.log(file)
      if (!file.FilePicked) {
        var value: any;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          value = {
            File: {
              IdFile: -1,
              Name: file.name,
              Size: file.size,
              FilePicked: { content: reader.result },
              Base64: reader.result
            }
          };
          value.size = file.size;
          value.type = file.type;
          value.FilePicked = { content: reader.result };
          value.base64 = reader.result;
          this.files.push(value.File);
          this.allegatiVerifiche.push(value);
        };
      }
    });
    console.log(this.files);
  }

  addNewItem() {
    const domande = this.gruppoForm.get('domande') as FormArray;
    let lastIndex = Object.keys(domande.value).length - 1;
    let lastDomanda = domande.value[lastIndex]
    this.templateDomanda.domanda = "";
    this.templateDomanda.idDomanda = 0;
    this.templateDomanda.idParent = null;
    this.templateDomanda.order = lastIndex + 1;
    this.templateDomanda.scheda = this.verificaId;
    this.templateDomanda.target = null;

    domande.insert(lastIndex + 1, this.newDomanda(null, this.templateDomanda));
  }

  doAddCopyItems(riga, index) {
    const domande = this.gruppoForm.get('domande') as FormArray;
    const newRiga = riga.getRawValue();
    const newConfig = { ...this.config.domanda[index] };

    newRiga.hasDeleteBtn = true;
    newRiga.idDomanda = 0;
    newRiga.idParent = this.config.domanda[index].ParentId;
    newRiga.order = this.config.domanda[index].Order;
    newRiga.scheda = this.config.domanda[index].Scheda;
    newRiga.target = this.config.domanda[index].Target;

    domande.insert(index + 1, this.newDomanda(null, newRiga));
    this.config.domanda.splice(index + 1, 0, newConfig);
  }
  doDeleteItems(riga, index) {
    const domande = this.gruppoForm.get('domande') as FormArray;

    domande.removeAt(index);
    this.config.domanda.splice(index, 1);
  }

  //--- Ciccio : PopUp Preview Image
  previewPopUp(base64) {
    console.log(base64);
    this.popupPreviewBase64 = base64;
    this.popupPreviewVisible = true;
  }

  downloadAllegato(e, cell) {
    e.preventDefault();
    console.log(cell)
    e.stopPropagation();
    this.classService.downloadAllegato(cell.DownloadUrl).subscribe((data) => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = cell.Name;
      a.href = URL.createObjectURL(data);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }),
      (error) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
}
