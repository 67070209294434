import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, APP_INITIALIZER, LOCALE_ID, EventEmitter } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import './localization';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { IonicModule } from '@ionic/angular';

import { AuthModule } from './@auth/auth.module';
import { InitUserService } from './@theme/services/init-user.service';
import { AppSettingsService } from './@core/backend/common/services/appsettings.service';
import { EnvServiceProvider } from './@core/backend/common/services/env.service.provider';
import { NgbdModalContent } from './@theme/components/header/header.component'
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbContextMenuModule,
  NbTreeGridModule,

} from '@nebular/theme';
import { SettingsApi } from './@core/backend/common/api/settings.api';
import { ModalIdoneitaComponent } from './@components/modal-idoneita/modal-idoneita.component';
import { SegnalazioniModule } from './@modules/gms/segnalazioni/segnalazioni.module';


export function init_app(injector: Injector) {
  return () =>
    new Promise<any>((resolve: Function) => {
      const initUserService = injector.get(InitUserService);
      initUserService.initCurrentUser().subscribe(() => { },
        () => resolve(), () => resolve()); // a place for logging error
    });
}

export function init_settings(settingsApp: SettingsApi) {
  return () => settingsApp.getApp();
}

registerLocaleData(localeIt);

@NgModule({
  declarations: [AppComponent,NgbdModalContent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LeafletModule.forRoot(),
    ThemeModule.forRoot(),
    AuthModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    NbTreeGridModule,
    SegnalazioniModule,
    IonicModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  entryComponents: [NgbdModalContent],
  exports: [ NgbdModalContent ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_settings,
      deps: [SettingsApi],
      multi: true,
    },
    AppSettingsService,
    EnvServiceProvider,

  ],

})
export class AppModule {
}
