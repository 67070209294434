import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../../@core/backend/common/api/http.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-widget-statistiche-stati',
  templateUrl: './widget-statistiche-stati.component.html',
  styleUrls: ['./widget-statistiche-stati.component.scss']
})
export class WidgetStatisticheStatiComponent implements OnInit {


  segnalazioniStati:any;
  filter=[]
  stati=[]

  constructor(private service: HttpService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit() {
    this.spinner.show();
    var store=this.service.getDataSourceDevExp("Segnalazioni",this.filter,false,null,null,true);
    store.on("loaded",this.loaded.bind(this));
    store.load();
  }


  loaded(res)
  {
    res.data.forEach(element => {
      if (element.Conclusa) {
        element.stato="Risolta"
        element.tooltip="La segnalazione è stato risolta"
        element.icona="fas fa-check"
      } else {
        if (element.Valid===null) {
          element.stato="Inserita"
          element.tooltip="La segnalazione è in fase di accettazione"
          element.icona="fas fa-folder"
        
        } else if (element.TipoEsecutore) {
          element.stato= "In lavorazione"
          element.tooltip="La segnalazione è in fase di accettazione"
          element.icona="fas fa-cogs"
        } else {
          element.stato= element.Valid?"Accettata":"Non di competenza"
          element.tooltip= element.Valid? "La segnalazione è stata verificata e considerata valida.A breve sarà assegnata ad un gestore per l'esecuzione dei lavori.":"La segnalazione è stata chiusa in quanto non rientrante nelle tipologie presenti nel portale ed è stato aperto un reclamo nel sistem interno dell'URP"  
          element.icona=element.Valid? "fas fa-thumbs-up":"fas fa-ban"
        }
      }
    });
    this.segnalazioniStati=res.data;
    console.log(this.segnalazioniStati)
    this.segnalazioniStati.forEach(element => {
      if(this.check(element.stato))
      {
        this.stati.forEach(stat =>{
          if(stat.stato==element.stato)
          {
            stat.count++;
          }
        });
      }
      else
      {
        this.stati.push({
          stato:element.stato,
          count:1
        })
      }
     
    });
    this.spinner.hide();
  }

  check(stato:string)
  {
    var bool = false;
    if(this.stati.length>0)
    {
      this.stati.forEach(element =>{
        if(element.stato==stato)
        {
          bool = true;
        }
      });
    }
    else
    {
      bool = false;
    }
    return bool
  }


}
