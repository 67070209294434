import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';



@Injectable()
export class GisService {
  private readonly apiController: string = 'gis';

  constructor(private api: HttpService) { }

  getAttributeList(className: string): Observable<any> {
    return this.api.get(`${this.apiController}/getAttributeList/${className}`)
      .pipe(map(data => {
        return { ...data };
      }));
  }

  getGeometry(mapId: number): Observable<any> {

    return this.api.get(`${this.apiController}/getGeometry/${mapId}`);
  }
  updateGeometry(nameClass: string = '', model: any): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');

    return this.api.post(`${this.apiController}/updateGeometry/${nameClass}`, model);
  }

}
