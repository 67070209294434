import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@auth/auth.guard';
import { AdminGuard } from './@auth/admin.guard';
import { FornitoriGuard } from './@auth/fornitori.guard';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
  },
  {
    path: 'cruscotti',
    canActivate: [AuthGuard],
    loadChildren: () => import('./cruscotti/cruscotti.module')
      .then(m => m.CruscottiModule),
  },
  {
    path: 'fornitori',
    canActivate: [FornitoriGuard],
    loadChildren: () => import('./fornitori/fornitori.module')
      .then(m => m.FornitoriModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module')
      .then(m => m.AuthModule),
  },



  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
  { path: 'widgets', loadChildren: () => import('./@modules/gms/widgets/widgets.module').then(m => m.WidgetsModule) },
  { path: 'fornitori', loadChildren: () => import('./fornitori/fornitori.module').then(m => m.FornitoriModule) },
  { path: 'personale', loadChildren: () => import('./fornitori/personale/personale.module').then(m => m.PersonaleModule) },
  { path: 'asset', loadChildren: () => import('./fornitori/asset/asset.module').then(m => m.AssetModule) },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
