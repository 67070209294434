import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-tabs',
  template: `
    <tabset>
        <tab  class="nav-item" *ngFor="let tab of field.fieldGroup; let i = index; let last = last;"
          [heading]=" tab.templateOptions.label  + (!isValid(field.fieldGroup[i])?' *':'')" >
          <br/>
          <formly-field [field]="tab"></formly-field>
        </tab>
    </tabset>
`,
})
// tslint:disable-next-line: component-class-suffix
export class FormlyFieldTabs extends FieldType {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.status!='DISABLED'? field.formControl.valid:true;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }
}
