import { AuthService } from './services/auth.services';

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserData } from '../../interfaces/common/users';
import { UsersService } from './services/users.service';
import { UsersApi } from './api/users.api';
import { HttpService } from './api/http.service';
import { CountryData } from '../../interfaces/common/countries';
import { CountriesService } from './services/countries.service';
import { CountriesApi } from './api/countries.api';
import { SettingsApi } from './api/settings.api';
import { NbAuthModule } from '@nebular/auth';
import { SettingsData } from '../../interfaces/common/settings';
import { SettingsService } from './services/settings.service';
import { SmartTableSettingsServices } from './services/smart-table-settings.services';
import { GruppiService } from './services/gruppi.service';
import { GenericClassService } from './services/genericClass.service';
import { FilterService } from './services/filter.service';
import { IstanceClassMockService } from './services/istanceClass-mock.service';
import { LookupService } from './services/lookup.service';
import { RelationsService } from './services/relations.service';
import { TaskNifiService } from './services/task_nifi.service';
import { ProcessNifiService } from './services/process_nifi.service';
import { WidgetService } from './services/widget.service';
import { EmailService } from './services/email.service';
import { CustomService } from './services/custom.service';
import { FileUploadService } from './services/file-upload.service';
import { ProcessService } from './services/process.service';
import { GisService } from './services/gis.service';


const API = [UsersApi, CountriesApi, SettingsApi, HttpService];

const SERVICES = [
  { provide: UserData, useClass: UsersService },
  { provide: CountryData, useClass: CountriesService },
  { provide: SettingsData, useClass: SettingsService },
  { provide: SmartTableSettingsServices, useClass: SmartTableSettingsServices },
  { provide: GruppiService, useClass: GruppiService },
  { provide: GenericClassService, useClass: GenericClassService },
  { provide: RelationsService, useClass: RelationsService },
  { provide: FilterService, useClass: FilterService },
  { provide: LookupService, useClass: LookupService },
  { provide: IstanceClassMockService, useClass: IstanceClassMockService },
  { provide: LookupService, useClass: LookupService },
  { provide: TaskNifiService, useClass: TaskNifiService},
  { provide: ProcessNifiService, useClass: ProcessNifiService},
  { provide: WidgetService, useClass: WidgetService},
  { provide: EmailService, useClass: EmailService},
  { provide: CustomService, useClass: CustomService},
  { provide: FileUploadService, useClass: FileUploadService},
  { provide: ProcessService, useClass: ProcessService},
  { provide: AuthService, useClass: AuthService},
  { provide: GisService, useClass: GisService},
];

@NgModule({
  imports: [CommonModule, NbAuthModule],
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CommonBackendModule,
      providers: [
        ...API,
        ...SERVICES,
      ],
    };
  }
}
