import { isArray } from 'rxjs/internal/util/isArray';
import { flatten } from '@angular/compiler';

export default class Utils {
  // FUNZIONI TREE

  static traverseDFS(root, type) {
    // if there is no root, return false
    if (root instanceof Array) {
      root = {
        id: 0,
        children: root,
      };
    }
    // make a variable for tree values
    const treeValues = [];
    // current values always starts at root
    const current = root;

    // helper methods go here

    // switch statment to select proper order and start recursive function calls
    switch (type) {
      case 'pre':
        this.preOrderHelper(current, treeValues);
        break;
      case 'post':
        this.postOrderHelper(current, treeValues);
        break;
      case 'in':
        this.inOrderHelper(current, treeValues);
        break;
    }
    // return array
    return treeValues;
  }

  static preOrderHelper = (node, treeValues, parent= null) => {
    // push value onto array FIRST
    const nodeN = { ...node, 'level': (parent ? parent.level + 1 : -1) , 'isLeaf': (!node.children || node.children.length == 0) };
    delete nodeN.children;

    let sortBy = [{
      prop:'Order',
      direction: 1
    },{
      prop:'Code',
      direction: 1
    },{
      prop:'Description',
      direction: 1
    }];

    treeValues.push(nodeN);
    // recursively call function on all node children
    if (node.children.length !== 0) {

      node.children = node.children.sort(function(a,b){
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
          result = sortBy[i].direction*(a[ sortBy[i].prop ] < b[ sortBy[i].prop ] ? -1 : (a[ sortBy[i].prop ] > b[ sortBy[i].prop ] ? 1 : 0));
          i++;
        }
        return result;
      })

      node.children.forEach((child) => {
        Utils.preOrderHelper(child , treeValues, nodeN);
      });
    }
    return true;
  }

  static postOrderHelper = (node, treeValues) => {
    // recursively call function on all node children FIRST
    if (node.children.length !== 0) {
      node.children.forEach((child) => {
        Utils.postOrderHelper(child, treeValues);
      });
    }
    // push value onto array
    const nodeN = { ...node };
    delete nodeN.children;
    treeValues.push(nodeN);
    return true;
  }
  static inOrderHelper = (node, treeValues) => {
    // if node had children, split nodes into left and right halves in case tree is not binary FIRST
    const nodeN = { ...node };
      delete nodeN.children;
    if (node.children.length !== 0) {
      // get halfway point
      const halfway = Math.floor(node.children.length / 2);
      // recursively call function on all node children left of halfway point
      for (let i = 0; i < halfway; i++) {
        Utils.inOrderHelper(node.children[i], treeValues);
      }
      // push parent node value to array

      treeValues.push(nodeN);
      // recursively call function on all node children right of halfway point
      for (let i = halfway; i < node.children.length; i++) {
        Utils.inOrderHelper(node.children[i], treeValues);
      }
      // else push value into array
    } else {
      treeValues.push(nodeN);
    }
    return true;
  }

  static unflatten(items, parentKey = 'ParentId') {
    const tree = [],
      mappedArr = {};

    // Build a hash table and map items to objects
    items.forEach(function (item) {
      const id = item.Id;
      if (!mappedArr.hasOwnProperty(id)) {
        // in case of duplicates
        mappedArr[id] = item; // the extracted id as key, and the item as value
        mappedArr[id].children = []; // under each item, add a key "children" with an empty array as value
      }
    });

    // Loop over hash table
    for (const id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        const mappedElem = mappedArr[id];

        // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
        if (mappedElem[parentKey] && mappedArr[mappedElem[parentKey]]) {
          const parentId = mappedElem[parentKey];
          mappedArr[parentId].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }

    return tree;
  }

  static flatten = function (data) {
    const result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        if (cur.length == 0) result[prop] = [];
        for (let i = 0, l = cur.length; i < l; i++)
          recurse(cur[i], prop + '[' + i + ']');
      } else {
        let isEmpty = true;
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, '');
    return result;
  };
  static flattenArray = function (data) {
    const flatArray = [];
    data.forEach((element) => {
      flatArray.push(this.flatten(element));
    });
    return flatArray;
  };
  static isEmpty = function (obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
}
