import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../stores/appsettings';
import { HttpService } from './http.service';

@Injectable()
export class SettingsApi {
  private readonly apiController: string = 'settings';

  static appSettings: any;

  constructor(private api: HttpService) { }

  getApp() {
    return new Promise<void>((resolve, reject) => {
      this.api.get(`${this.apiController}/app`).toPromise().then((response) => {
        SettingsApi.appSettings = response.data;
        console.log('Config app loading');
        resolve();
      }).catch((response: any) => {
        reject('Could not load the settings');
      })
    })
  }

  getCurrent(): Observable<any> {
    return this.api.get(`${this.apiController}/current`);
  }

  updateCurrent(item: any): Observable<any> {
    return this.api.put(`${this.apiController}/current`, item);
  }

  getPlainApp() {
    return this.api.get(`${this.apiController}/app`);
  }


}
