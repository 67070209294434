import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NB_AUTH_OPTIONS,
  NbAuthService,
  NbAuthResult,
  NbAuthOAuth2JWTToken,
} from '@nebular/auth';
import { getDeepFromObject } from '../../helpers';
import { NbThemeService } from '@nebular/theme';
import { EMAIL_PATTERN } from '../constants';
import { InitUserService } from '../../../@theme/services/init-user.service';
import { AppSettingsService } from '../../../@core/backend/common/services/appsettings.service';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NgxLoginComponent implements OnInit {
  minLength: number = this.getConfigValue('forms.validation.password.minLength');
  maxLength: number = this.getConfigValue('forms.validation.password.maxLength');
  redirectDelay: number = this.getConfigValue('forms.login.redirectDelay');
  showMessages: any = this.getConfigValue('forms.login.showMessages');
  strategy: string = this.getConfigValue('forms.login.strategy');
  rememberMe = this.getConfigValue('forms.login.rememberMe');
  isEmailRequired: boolean = this.getConfigValue('forms.validation.email.required');
  isPasswordRequired: boolean = this.getConfigValue('forms.validation.password.required');


  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  loginForm: FormGroup;
  alive: boolean = true;
  ruoli: any;
  settings: any;

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected themeService: NbThemeService,
    private fb: FormBuilder,
    protected router: Router,
    protected initUserService: InitUserService,
    protected appSettingsService: AppSettingsService,
    protected authService: NbAuthService,
    ) {
      
    }

  ngOnInit(): void {
    this.settings=null;
    this.appSettingsService.getSettings()
      .subscribe((res) => {
        this.settings = res;
        this.cd.markForCheck();
      });

    const emailValidators = [
      Validators.pattern(EMAIL_PATTERN),
    ];
    this.isEmailRequired && emailValidators.push(Validators.required);

    const passwordValidators = [
      Validators.minLength(this.minLength),
      Validators.maxLength(this.maxLength),
    ];
    this.isPasswordRequired && passwordValidators.push(Validators.required);

    const roleValidators = [];
    this.ruoli && roleValidators.push(Validators.required);

    this.loginForm = this.fb.group({
      email: this.fb.control('', [...emailValidators]),
      password: this.fb.control('', [...passwordValidators]),
      rememberMe: this.fb.control(''),
      role: this.fb.control(null, [...roleValidators]),
    });



  }

  login(): void {
    this.user = this.loginForm.value;
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        console.log('LOGIN');
        const payload = result.getToken().getPayload();
        if (payload.availables_roles && !payload.access_token) {
          this.ruoli = payload.availables_roles;
          this.onChanges();
        } else {
          this.messages = result.getMessages();
          this.initUserService.initCurrentUser().subscribe();
        }

      } else {
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();

      if (this.appSettingsService.getUrl()) {
        setTimeout(() => {
          const url = this.appSettingsService.getUrl();
          this.appSettingsService.setUrl(null);
          this.router.navigateByUrl(url);
        }, this.redirectDelay);
      } else if (redirect) {
        setTimeout(() => {
          this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }



      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  onChanges() {
    this.loginForm.get('email').valueChanges
      .subscribe(res => {
        this.loginForm.get('role').patchValue(null);
        this.ruoli = null;
        this.loginForm.get('role').setValidators(null);
        this.user = this.loginForm.value;
      });
    this.loginForm.get('password').valueChanges
      .subscribe(res => {
        this.loginForm.get('role').patchValue(null);
        this.ruoli = null;
        this.loginForm.get('role').setValidators(null);
        this.user = this.loginForm.value;
      });

      this.ruoli &&  this.loginForm.get('role').setValidators([Validators.required]);
      this.loginForm.get('role').updateValueAndValidity();
  }
}
