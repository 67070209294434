import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbTokenLocalStorage, NbAuthToken, NbPasswordAuthStrategy } from '@nebular/auth';
import { AnalyticsService } from './@core/utils/analytics.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { AppSettingsService } from './@core/backend/common/services/appsettings.service';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  mySubscription;
  constructor(private analytics: AnalyticsService,
    private authStrategy: NbPasswordAuthStrategy,
    private tokenStorage: NbTokenLocalStorage,
    private titleService: Title,
    private appSettingsService: AppSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {


    this.appSettingsService.getSettings()
      .subscribe(settings => { this.titleService.setTitle(settings.applicationName); },

      );
    // TODO: REMOVE BEFORE PROD
    // for demo only: init localstorage with token for demo user when login for the first time
    // this.initTestUserToken();

    /*** FORZA RELOAD PAGINA */
    /*
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        }
      });
      */
  }

  ngOnDestroy() {
    console.log('app ngOnDestroy');
    if (this.mySubscription) {
     this.mySubscription.unsubscribe();
    }
  }
  ngOnInit() {
    // this.printpath('', this.router.config);
  }

  printpath(parent: String, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      console.log(parent + '/' + route.path);
      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.printpath(currentPath, route.children);
      }
    }
  }
  initTestUserToken() {
    const demoTokenInitKey = 'demo_token_initialized';
    const demoTokenWasInitialized = localStorage.getItem(demoTokenInitKey);
    const currentToken = this.tokenStorage.get();
    if (!demoTokenWasInitialized && !currentToken.isValid()) {
      // local storage is clear, let's setup demo user token for better demo experience
      this.tokenStorage.set(this.authStrategy.createToken<NbAuthToken>(environment.testUser.token));
      localStorage.setItem(demoTokenInitKey, 'true');
    }
  }
}
