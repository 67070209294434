import {  Component,  OnInit,  Input,  Output,  EventEmitter,  ViewChild,} from '@angular/core';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { NbToastrService } from '@nebular/theme';
import { DxDataGridComponent, DxTreeListComponent } from 'devextreme-angular';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-list-class',
  templateUrl: './list-class.component.html',
  styleUrls: ['./list-class.component.scss'],
})
export class SelectListClassComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })   dataGrid: DxDataGridComponent;
  @ViewChild(DxTreeListComponent, { static: false })  treeGrid: DxTreeListComponent;
  store: any;

  @Input('className') className: string;
  @Input('domainName') domainName: string;
  @Input('otherClass') otherClass: string;
  @Input('title') title: string;
  @Input('baseFilter') baseFilter: any = null;
  @Input('selectFilter') selectFilter: any = null;
  @Input('treeRoot') treeRoot: number = 0;
  @Input('modeselect') modeselect: string = "single";

  expandedRowKeys: any[] = [];
  settings: any;
  firstColumnName: any;

  @Output() onSelected = new EventEmitter<any>();
  filter: any[];
  styleNormal: any;
  okSeleziona: boolean;
  mode: string;
  selctable: any = null;
  data: any;
  listClassAlias: any[];
  listClassColor = [
    '993300',
    '30373D',
    '003300',
    '800000',
    'FF6600',
    '808000',
    '008000',
    '0000FF',
    '666699',
    '808080',
    'FF0000',
    'FF9900',
    '99CC00',
    '339966',
    '33CCCC',
    '3366FF',
    '800080',
    '969696',
    'FF00FF',
    'FFCC00',
    'FFFF00',
    '00FF00',
    '00FFFF',
    '00CCFF',
    '993366',
    'C0C0C0',
    'FF99CC',
    'FFCC99',
    'FFFF99',
    'CCFFCC',
    'CCFFFF',
    '99CCFF',
    'CC99FF',
    'FFFFFF',
  ];
  constructor(
    private classService: GenericClassService,
    private toasterService: NbToastrService,
    private translate: TranslateService,
  ) {

  }

  ngOnInit() {
    this.okSeleziona = false;
    this.selctable = null;
    this.settings = { columns: [] };
    if (!this.className) {
      console.log(this.domainName, this.otherClass);
      this.classService
        .getInverseClass(this.otherClass, this.domainName)
        .subscribe((res) => {
          this.className = res.data;
          this.loadData();
        });
    } else {
      this.loadData();
    }
  }
  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === 'searchPanel') {
        item.location = 'before';
      }
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',

        onClick: this.refreshDataGrid.bind(this),
      },
    });
  }

  // Preserve original property order
  originalOrder = (): number => {
    return 0;
  }

  refreshDataGrid() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    instance.refresh();
  }
  clearFilterDataGrid() {
    this.filter = [];
  }


  loadedSelectable(res) {
    this.selctable = res.data.map((x) => x.Id);
  }
  loaded(res) {
    this.data = res.data;
    const root = this.data.find(x => !x.ParentId);
    this.expandedRowKeys = root ? [root.Id] : [];
  }
  loadData() {
    this.settings.columns = null;
    // CARICA COLONNE
    this.classService.getClass(this.className).subscribe((res) => {
      if (res.status == 'success') {
        // CARICA DATI
        this.store = this.classService.getDataSourceDevExp(
          this.className,
          this.baseFilter, false, this.treeRoot,
        );
        if (res.data.classData.isTree == 'true') {
          this.store.on('loaded', this.loaded.bind(this));
          this.store.load();
        }

        if (this.selectFilter && this.selectFilter.length > 0) {
          const selectableFilter = [].concat(
            [this.baseFilter && this.baseFilter.length > 0 ? this.baseFilter : ['Status', '=', 'A']],
            'and',
            [this.selectFilter],
          );
          const storeSelectFilter = this.classService.getDataSourceDevExp(
            this.className,
            selectableFilter,
          );
          storeSelectFilter.on('loaded', this.loadedSelectable.bind(this));
          storeSelectFilter.load();
        }

        this.mode = res.data.classData.isTree == 'true' ? 'tree' : 'grid';
        if (this.mode == 'tree') {
          this.listClassAlias = [];
          let colorCount = 1;
          res.data.classData.listClass.forEach((element) => {
            const color = this.listClassColor[colorCount];

            console.log(color);
            this.listClassAlias[element.className.replaceAll('"', '')] = {
              alias: element.labelName,
              cssClass: color,
            };
            colorCount++;
          });
          if (!Object.keys(res.data.list).includes('Tipo')) {
            res.data.list['Tipo'] = {
              Metadata: null,
              index: 3,
              lookup: null,
              reference: null,
              referenceClassName: '',
              required: false,
              sortIndex: 0,
              sortOrder: null,
              title: 'Tipo',
              type: 'string',
              template: 'typeClassAlias',
            };
          }
        }
        // Normalizza lookup e reference
        const newKeyValues = Object.keys(res.data.list).map((key) => {
          const newKey =
            res.data.list[key].lookup || res.data.list[key].reference
              ? '_' + key + '_Description'
              : key;
          const newValue = res.data.list[key];
          newValue.title =
            this.translate.instant(this.className + '.' + key) ||
            newValue.title;
          const newObject = { [newKey]: newValue };
          return newObject;
        });

        res.data.list = Object.assign({}, ...newKeyValues);

        // Imposta colonne tabella
        this.settings.columns = res.data.list;
        this.settings['summary'] = res.data.summary;

        const kyz = Object.keys(this.settings.columns);
        this.firstColumnName = this.settings.columns[kyz[0]]['title'];
      } else {
        this.toasterService.danger('', `ERRORE: ${res.status} - ${res.data}`);
      }
    });
  }
  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }
  getParent(node, element, level: number) {
    if (node.ParentId) {
      const parent = this.data.find((x) => x.Id == node.ParentId);
      const tipo = parent.Tipo ? parent._Tipo_Description : parent.IdClass;
      const newNode =  {
        level: level,
        tipo: tipo,
        Id: parent.Id,
        ParentId: node.Id,
        Code: parent.Code,
        Description: parent.Description,
      };
      if (!element.breadcrumb[tipo]) element.breadcrumb[tipo] = newNode;
      element.breadcrumbLevel[level] = newNode;
      level++;
      this.getParent(parent, element, level);
    }
  }

  getBreadcrumb(element) {
    const node = element;
    if (node) {
      node.breadcrumbLevel = {};
      node.breadcrumb = {};
      const tipo = node.Tipo ? node._Tipo_Description : node.IdClass;
      const newNode = {
        level: 0,
        tipo: tipo,
        Id: node.Id,
        ParentId: 0,
        Code: node.Code,
        Description: node.Description,
      };

      if (!element.breadcrumb[tipo]) node.breadcrumb[tipo] = newNode;
      node.breadcrumbLevel[0] = newNode;

      this.getParent(node, element, 1);

    }
  }

  seleziona() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;

    if (this.treeGrid) {
      // SE E' UN ALBERO RECUPERO IL PERCORSI
      instance.getSelectedRowsData().forEach((element) => {
        this.getBreadcrumb(element);
      });
    }

    this.onSelected.emit(instance.getSelectedRowsData());
    //this.ref.close(instance.getSelectedRowsData());
  }


  onSelectionChanged(e) {
    if (this.selctable !== null) {
      this.okSeleziona = false;

      const toDeSelect = e.selectedRowKeys.filter(
        (x) => !this.selctable.includes(x),
      );
      e.component.deselectRows(toDeSelect);
      this.okSeleziona = e.selectedRowKeys.length - toDeSelect.length > 0;
    } else {
      this.okSeleziona = true;
    }
    if (this.okSeleziona) this.seleziona();
  }
  lookup_calculateCellValue(row) {
    const column = this as any;
    const dataField = column.dataField;

    const fieldName = dataField.replace('_Description', '').replace('_', '');
    let label = '';
    if (row[dataField]) {
      if (row['_' + fieldName + '_IconFont']) {
        if (row['_' + fieldName + '_IconColor']) {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" style="color:` +
            row['_' + fieldName + '_IconColor'] +
            `"></span>&nbsp;&nbsp;`;
        } else {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" ></span>&nbsp;&nbsp;`;
        }
      }
      if (row['_' + fieldName + '_TextColor']) {
        label +=
          `<span style="color:` +
          row['_' + fieldName + '_TextColor'] +
          `" >${row[dataField]}</span>`;
      } else {
        label += `<span>${row[dataField]}</span>`;
      }
      column.encodeHtml = false;
    }
    return label;
  }
  calculateCellValueDefault(row) {
    const column = this as any;
    return column.defaultCalculateCellValue(row);
  }
}
