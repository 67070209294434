import { forkJoin, from, Observable } from "rxjs";
import { NbDialogService } from "@nebular/theme";
import { GisService } from "./../../@core/backend/common/services/gis.service";
import { GenericClassService } from "./../../@core/backend/common/services/genericClass.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import * as L from "leaflet";

import "leaflet-draw/dist/leaflet.draw.js";

import drawLocales from "leaflet-draw-locales";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormClassComponent } from "../form-class/form-class.component";
import { ModalListClassComponent } from "../modal-list-class/modal-list-class.component";
import "leaflet-easybutton";
import { ModalAssetDetailsComponent } from "../modal-asset-details/modal-asset-details.component";
declare var bootbox: any;
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: "ngx-mappa",
  templateUrl: "./mappa.component.html",
  styleUrls: ["./mappa.component.scss"],
})
export class MappaComponent implements OnInit {
  @Input() mode = "edit";
  _idMappa: number;
  livelloMinimo: number;
  livelloMassimo: number;
  livelloStartValue: number;
  livelloEndValue: number;
  label: { visible: boolean; format: (value: any) => any; position: string };
  tooltip: {
    enabled: boolean;
    format: (value: any) => any;
    showMode: string;
    position: string;
  };
  loading: boolean = false;
  mapCurrentBaseLayer: any;
  _layerbase: any;
  _layersfondo: any;
  editableLayers: L.FeatureGroup<any>;
  labels: any[] = [];



  private _idOggetto=null;
  private hightlightOggetto: any=null;
  public get idOggetto() {
    return this._idOggetto;
  }
  @Input() public set idOggetto(value) {
    this._idOggetto = value;
  }


  get idMappa() {
    return this._idMappa;
  }
  @Input() set idMappa(value) {
    this._idMappa = value;
    this.classService
      .getDettagliScheda("Mappe", this.idMappa)
      .subscribe((res) => {
        this.recordMappa = res.data;
        this.breadcrumbList.push({
          id: this._idMappa,
          name: this.recordMappa.Code,
        });


        this.loadDataMap().subscribe((res) => {
          this.addLayers();

        });
      });
  }
  @Output() mappaChanged = new EventEmitter<any>();

  map: any;
  recordMappa: any;
  drawControl: any;

  // TO DO: ricavare da servizio *****************
  classType = {
    polyline: [
      {
        text: "Asset",
        value: "Asset",
      },
      {
        text: "Pareti, muri o divisori",
        value: "ObjGeoLinea",
      },
    ],
    polygon: [
      {
        text: "Asset",
        value: "Asset",
      },
      {
        text: "Superfici o aree",
        value: "ObjGeoArea",
      },
      {
        text: "Ubicazione",
        value: "Ubicazione",
      },
    ],
    marker: [
      {
        text: "Asset",
        value: "Asset",
      },
    ],
  };
  layersBase: any = [];
  selectedLayerBase = null;

  layersSfondi: any = [];
  selectedLayerSfondo = null;

  layersTipologieOggetti = [
    { value: "Asset", text: "Asset", checked: true },
    { value: "ObjGeoLinea", text: "Pareti, muri o divisori", checked: true },
    { value: "ObjGeoArea", text: "Superfici o aree", checked: true },
    { value: "Ubicazione", text: "Ubicazioni", checked: true },
  ];
  layersOggetti: any = [];
  posizionamentoAutomatico: boolean = true;
  posizionamentoAutomaticoOggetto: boolean = undefined;
  currentClassToOpen: any = null;
  @ViewChild("modalCreateForm", { static: true })
  modalCreateForm: TemplateRef<any>;
  @ViewChild(FormClassComponent, { static: true })

  private resizeObserver: ResizeObserver;
  public cardComponent: FormClassComponent;
  livelliSelected: any[];
  storeUbicazioni: any;
  ubicazioni: any;
  selectedRowKeysUbicazioni: any[];
  breadcrumbList: any[] = [];
  constructor(
    private classService: GenericClassService,
    private modalService: NgbModal,
    private gisService: GisService,
    private dialogService: NbDialogService
  ) {
    this.label = {
      visible: true,
      format: (value) => this.format(value),
      position: "top",
    };
    this.tooltip = {
      enabled: true,
      format: (value) => this.format(value),
      showMode: "always",
      position: "bottom",
    };
  }
  format(value) {
    return `${value}`;
  }


  resize() {
    setTimeout(() => {
      this.map.invalidateSize();
      this.posizionaMappa();
    }, 10);
  }

  ngOnInit() {
    console.log("INIT COMPONENT Mappa");
    this.initMap();

    this.livelloMinimo = 0;
    this.livelloMassimo = 0;

    const mapContainer = document.getElementById('map-container');
    this.resizeObserver = new ResizeObserver(() => {
       this.map.invalidateSize(); // Aggiorna le dimensioni della mappa Leaflet
       this.posizionaMappa();
    });
    this.resizeObserver.observe(mapContainer);
  }

  openAssetDetail(asset) {
    this.dialogService.open(ModalAssetDetailsComponent, {
      context: {
        title: "Scheda dettaglio asset",
        mode: "EDIT",
        className: asset.NameClass ? asset.NameClass : asset.IdClass,
        cardId: asset.Id,
      },
      hasBackdrop: false,
    });
  }

  private initMap(): void {
    console.log("initMap....");
    if (this.map) {
      try {
        this.map.remove();
      } catch (error) {}
    }
    L.Icon.Default.imagePath = "/assets/img/markers/"
    this.map = L.map("map", {
      zoomControl: false,
      crs: L.CRS.EPSG3857,
    });

    // show the scale bar on the lower left corner
    //L.control.scale({ imperial: true, metric: true }).addTo(this.map);

    // recenter map on base layer change:

    this.map.on(
      "draw:created",
      function (e) {
        var type = e.layerType,
          layer = e.layer;
        const geoJson = layer.toGeoJSON();
        bootbox.prompt({
          title: "Creazione elementi",
          message: "<p>Selezionare il tipo di oggetto:</p>",
          inputType: "radio",
          inputOptions: this.classType[type],
          callback: (result) => {
            this.currentClassToOpen = null;
            if (result) {
              this.currentClassToOpen = result;
              if (result == "Asset") {
                // selezione asset
                this.dialogService
                  .open(ModalListClassComponent, {
                    context: {
                      nameClass: "Asset",
                    },
                    hasBackdrop: true,
                  })
                  .onClose.subscribe((res) => {
                    if (res) {
                      const data = {
                        mapId: this.idMappa,
                        masterId: res[0].Id,
                        masterClass: this.currentClassToOpen,
                        feature: JSON.stringify(geoJson.geometry),
                      };
                      this.gisService
                        .updateGeometry(this.currentClassToOpen, data)
                        .subscribe((res2) => {
                          // aggiorna leaflet
                          this.editableLayers.addLayer(layer);
                        });
                    }
                  });
              } else if (result == "Ubicazione") {
                // selezione ubicazione
                this.dialogService
                  .open(ModalListClassComponent, {
                    context: {
                      nameClass: "Ubicazione",
                    },
                    hasBackdrop: true,
                  })
                  .onClose.subscribe((res) => {
                    if (res) {
                      const data = {
                        mapId: this.idMappa,
                        masterId: res[0].Id,
                        masterClass: this.currentClassToOpen,
                        feature: JSON.stringify(geoJson.geometry),
                      };
                      this.gisService
                        .updateGeometry(this.currentClassToOpen, data)
                        .subscribe((res2) => {
                          // aggiorna leaflet
                          this.editableLayers.addLayer(layer);
                        });
                    }
                  });
              } else {
                // apre form

                this.modalService
                  .open(this.modalCreateForm, {
                    ariaLabelledBy: "modal-basic-title",
                  })
                  .result.then(
                    (result2) => {
                      this.cardComponent.save({}).subscribe((res) => {
                        // salva geometria

                        const data = {
                          mapId: this.idMappa,
                          masterId: res.Id,
                          masterClass: this.currentClassToOpen,
                          feature: JSON.stringify(geoJson.geometry),
                        };
                        this.gisService
                          .updateGeometry(this.currentClassToOpen, data)
                          .subscribe((res2) => {
                            // aggiorna leaflet
                            this.editableLayers.addLayer(layer);
                          });
                      });
                    },
                    (reason) => {
                      console.log(reason);
                    }
                  );
              }
            }
          },
        });
      }.bind(this)
    );
    this.map.on(
      "draw:edited",
      function (e) {
        var layers = e.layers;
        layers.eachLayer((layer) => {
          const geoJson = layer.toGeoJSON();
          const data = {
            mapId: this.idMappa,
            masterId: layer.feature.properties.Id,
            masterClass: layer.feature.properties.MasterClass,
            feature: JSON.stringify(geoJson.geometry),
          };
          this.gisService
            .updateGeometry(layer.feature.properties.MasterClass, data)
            .subscribe((res2) => {
              // aggiorna leaflet
            });
        });
      }.bind(this)
    );
    this.map.on(
      "draw:deleted",
       (e)=> {
        var layers = e.layers;
        layers.eachLayer((layer) => {
          const geoJson = layer.toGeoJSON();
          const data = {
            mapId: this.idMappa,
            masterId: layer.feature.properties.Id,
            masterClass: layer.feature.properties.MasterClass,
            feature: JSON.stringify(geoJson.geometry),
            status: "N",
          };
          this.gisService
            .updateGeometry(layer.feature.properties.MasterClass, data)
            .subscribe((res2) => {
              // Rimuove geometria da oggetti
              this.layersOggetti= this.layersOggetti.filter((item)=> item.feature.properties.Id != layer.feature.properties.Id);
            });
        });
      }
    );

    // POSIZIONE INIZIALE MAPPA

    this.map.setView([37.61064032632874, 14.031515366475677], 8);

    var Zoominfo = L.Control.extend({
      options: {
        position: "topleft",
        styleNS: "leaflet-control-zoominfo",
      },

      onAdd: function (map) {
        this._map = map;
        this._ui = this._createUI();

        map
          .whenReady(this._initInfo, this)
          .whenReady(this._initEvents, this)
          .whenReady(this._updateInfoValue, this)
          .whenReady(this._updateDisabled, this);
        return this._ui.bar;
      },

      onRemove: function (map) {
        map
          .off("zoomlevelschange", this._updateSize, this)
          .off("zoomend zoomlevelschange", this._updateInfoValue, this)
          .off("zoomend zoomlevelschange", this._updateDisabled, this);
      },

      _createUI: function () {
        var ui: any = {};
        var ns = this.options.styleNS;

        ui.bar = L.DomUtil.create("div", ns + " leaflet-bar");
        ui.zoomIn = this._createZoomBtn("in", "top", ui.bar);
        ui.wrap = L.DomUtil.create(
          "div",
          ns + "-wrap leaflet-bar-part",
          ui.bar
        );
        ui.zoomOut = this._createZoomBtn("out", "bottom", ui.bar);
        ui.body = L.DomUtil.create("div", ns + "-body", ui.wrap);
        ui.info = L.DomUtil.create("div", ns + "-info");

        L.DomEvent.disableClickPropagation(ui.bar);
        L.DomEvent.disableClickPropagation(ui.info);

        return ui;
      },
      _createZoomBtn: function (zoomDir, end, container) {
        var classDef =
          this.options.styleNS +
          "-" +
          zoomDir +
          " leaflet-bar-part" +
          " leaflet-bar-part-" +
          end;

        var link: any = L.DomUtil.create("a", classDef, container);

        link.href = "#";
        link.title = "Zoom " + zoomDir;

        L.DomEvent.on(link, "click", L.DomEvent.preventDefault);

        return link;
      },

      _initInfo: function () {
        this._ui.body.appendChild(this._ui.info);
      },
      _initEvents: function () {
        this._map
          .on("zoomend zoomlevelschange", this._updateInfoValue, this)
          .on("zoomend zoomlevelschange", this._updateDisabled, this);

        L.DomEvent.on(this._ui.zoomIn, "click", this._zoomIn, this);
        L.DomEvent.on(this._ui.zoomOut, "click", this._zoomOut, this);
      },

      _zoomIn: function (e) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },
      _zoomOut: function (e) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomLevels: function () {
        var zoomLevels = this._map.getMaxZoom() - this._map.getMinZoom() + 1;
        return zoomLevels < Infinity ? zoomLevels : 0;
      },
      _toZoomLevel: function (value) {
        return value + this._map.getMinZoom();
      },
      _toValue: function (zoomLevel) {
        return zoomLevel - this._map.getMinZoom();
      },

      _updateInfoValue: function () {
        this._ui.info.innerHTML =
          "<strong>" + this._map.getZoom() + "</strong>";
      },
      _updateDisabled: function () {
        var zoomLevel = this._map.getZoom(),
          className = this.options.styleNS + "-disabled";

        L.DomUtil.removeClass(this._ui.zoomIn, className);
        L.DomUtil.removeClass(this._ui.zoomOut, className);

        if (zoomLevel === this._map.getMinZoom()) {
          L.DomUtil.addClass(this._ui.zoomOut, className);
        }
        if (zoomLevel === this._map.getMaxZoom()) {
          L.DomUtil.addClass(this._ui.zoomIn, className);
        }
      },
    });

    var zoomInfoControl = new Zoominfo().addTo(this.map);

    drawLocales("it");

    this.editableLayers = new L.FeatureGroup();
    this.map.addLayer(this.editableLayers);
    var drawPluginOptions: L.Control.DrawConstructorOptions = {
      position: "topright",
      draw: {
        circle: false,
        circlemarker: false,
        rectangle: false,
      },
      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
      },
    };
    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.drawControl = new L.Control.Draw(drawPluginOptions);

    if (this.mode == "edit") {
      var stateChangingButton = L.easyButton({
        states: [
          {
            stateName: "mode-view", // name the state
            icon: "fa-eye fa-lg", // and define its properties
            title: "attiva modalità modifica", // like its title
            onClick: (btn, map) => {
              // and its callback
              map.addControl(this.drawControl);
              this.mode = "edit";
              btn.state("mode-edit"); // change state on click!
            },
          },
          {
            stateName: "mode-edit",
            icon: "fa-edit fa-lg",
            title: "disattiva modalita modifica",
            onClick: (btn, map) => {
              map.removeControl(this.drawControl);
              this.mode = "view";
              btn.state("mode-view");
              this.reloadOggetti()
            },
          },
        ],
      });
      stateChangingButton.addTo(this.map);
    }
  }

  addLayers() {
    if (this.loading) return;

    console.log("addLayers");
    // Rimuove tutti i layer
    this.map.eachLayer((layer) => {
      if (layer instanceof L.TileLayer) {
        this.map.removeLayer(layer);
      } else if (layer instanceof L.FeatureGroup) {
        layer.clearLayers();
      }
    });

    // Layers di base************************************************************************************************

    var layerbase = L.tileLayer(this.selectedLayerBase.url, {
      attribution: this.selectedLayerBase.attribution,
      minZoom: this.selectedLayerBase.minZoom,
      maxZoom: this.selectedLayerBase.maxZoom,
      tms: this.selectedLayerBase.tms || false,
    });

    this._layerbase = this.map.addLayer(layerbase);

    // Layers di sfondo************************************************************************************************
    this._layersfondo = null;
    if (
      this.selectedLayerSfondo &&
      this.selectedLayerSfondo.Livello != -9999999999
    ) {
      var layer = L.tileLayer(this.selectedLayerSfondo.url, {
        tms: this.selectedLayerSfondo.tms,
        minZoom: this.selectedLayerSfondo.minZoom,
        maxZoom: this.selectedLayerSfondo.maxZoom,
      });
      this._layersfondo = this.map.addLayer(layer);
    }

    // Layers oggetti************************************************************************************************

    // FILTRA FEATURES

    let arrayGeoJson: any = [];
    let cloneLayersOggetti = JSON.parse(JSON.stringify(this.layersOggetti));
    cloneLayersOggetti.forEach((geojsonFeature) => {
      if (
        geojsonFeature.properties.Livello == undefined ||
        geojsonFeature.properties.Livello == null
      ) {
        arrayGeoJson.push(geojsonFeature);
      } else {
        if (
          this.isVisibleObject(
            geojsonFeature.properties,
            this.livelloStartValue,
            this.livelloEndValue
          )
        ) {

          arrayGeoJson.push(geojsonFeature);
        }
      }
    });
    // FILTRA PER Tipologia Oggetto
    this.livelliSelected = this.layersTipologieOggetti
      .filter((x) => x.checked)
      .map((x) => x.value);
    let layersOggettiFilter: any = [];
    if (this.livelliSelected && this.livelliSelected.length > 0) {
      layersOggettiFilter = arrayGeoJson.filter((x) =>
        this.livelliSelected.includes(
          x.properties.MasterClass.replaceAll('"', "")
        )
      );
    }

    let layerFilter: any = L.geoJSON(layersOggettiFilter);

    // DRAW OPTIONS
    // Initialise the FeatureGroup to store editable layers

    layerFilter.eachLayer((l) => {
      // Setto l'oggetto da evidenziare
      if (l.feature && l.feature.properties && l.feature.properties.Id == this.idOggetto) {
        this.hightlightOggetto=l;
        if (this.posizionamentoAutomaticoOggetto===undefined) {
          this.posizionamentoAutomaticoOggetto=true;
          this.posizionamentoAutomatico=false;
        }
      }

      if (l.feature && l.feature.properties && l.feature.properties.Color) {
        l.setStyle({
          color: l.feature.properties.Color,
          fillColor: l.feature.properties.Color,
          fillOpacity: 0.5,
        });
      }

      //**** POPUP */

      if (l.feature.properties.MasterClass == "Ubicazione") {
        l.setStyle({
          color: "#ff6200",
          fillColor: "#ff6200",
          fillOpacity: 0.5,
        });
        let htmlPopup = `
        <div class="text-center"><strong>${l.feature.properties.Description||l.feature.properties.Code}</strong></div><br/>
        <div class='text-center select'><button type='button'  class='btn btn-success btn-block'>Visualizza ubicazione</button></div>
        `;

        if (l.feature.properties.Mappe) {
          let mappe = l.feature.properties.Mappe;
          mappe.forEach((mappa) => {
            if (mappa.id) {
              htmlPopup += `
              <div class='text-center selectMappa${
                mappa.id
              } mt-1'><button type='button'  class='btn btn-info  btn-block'>Mappa ${
                mappa.description || mappa.code
              }</button></div>
              `;

              l.bindPopup(htmlPopup).on("popupopen", (a) => {
                var popUp = a.target.getPopup();

                popUp
                  .getElement()
                  .querySelector(`.selectMappa${mappa.id}`)
                  .addEventListener("click", (e: any) => {
                    this.changeMappa(mappa.id);
                  });
              });
            }
          });
        }

        l.bindPopup(htmlPopup).on("popupopen", (a) => {
          var popUp = a.target.getPopup();

          popUp
            .getElement()
            .querySelector(".select")
            .addEventListener("click", (e: any) => {
              this.openAssetDetail(l.feature.properties);
            });
        });
      } else {
        let htmlPopup = `
        <div class="text-center"><strong>${l.feature.properties.Description||l.feature.properties.Code}</strong></div><br/>
        <div class='text-center select'><button type='button'  class='btn btn-success'>Visualizza scheda</button></div>
        `;
        l.bindPopup(htmlPopup).on("popupopen", (a) => {
          var popUp = a.target.getPopup();

          popUp
            .getElement()
            .querySelector(".select")
            .addEventListener("click", (e: any) => {
              this.openAssetDetail(l.feature.properties);
            });
        });
      }

      l.addTo(this.editableLayers);
    });

    // posizionamento mappa ************************************************************************************************
    this.posizionaMappa();
  }

  private posizionaMappa() {
    if (this.hightlightOggetto && this.posizionamentoAutomaticoOggetto) {
      console.log("Evidenzia oggetto ....!!!")
      // Calcola i limiti della geometria
      var bounds = L.geoJSON(this.hightlightOggetto.feature.geometry).getBounds();
      this.map.fitBounds(bounds);
    } else if (this.posizionamentoAutomatico) {

        if (this.editableLayers.getLayers().length > 0) {
          // posiziona la mappa sul box degli oggetti
          this.map.fitBounds(this.editableLayers.getBounds());
        } else if (this._layersfondo) {
          // posiziona la mappa sul box del layer di sfondo
          this.map.fitBounds(this.selectedLayerSfondo.bounds);
        } else {
          if (this.selectedLayerBase && !this.selectedLayerBase.attribution) {
            // posiziona la mappa sul box del layer base
            this.map.fitBounds(this.selectedLayerBase.bounds);
          } else {
            // posiziona la mappa sulla Sicilia e zooma al livello minimo di openstreetmap
            this.map.setView([37.61064032632874, 14.031515366475677], 8);
          }
        }

    }
  }
  changeMappa(idMappa: any) {
    this.layersBase = [];
    this.layersSfondi = [];
    this.livelloMinimo=0;
    this.livelloMassimo=0;
    this.livelloStartValue=0;
    this.livelloEndValue=0;
    this.hightlightOggetto=null;
    this.posizionamentoAutomaticoOggetto=undefined;
    this.posizionamentoAutomatico = true;

    this.idMappa=idMappa;
    //this.mappaChanged.emit(idMappa);
  }

  private isVisibleObject(object: any, minHeight, maxHeight) {
    if (!minHeight && !maxHeight) return true;
    return object.Livello <= maxHeight && object.LivelloMax >= minHeight;
  }

  private loadLayersBase(): Observable<any> {
    return new Observable((observer2) => {
      console.log("subscribe loadLayersBase");
      if (!this.layersBase || this.layersBase.length == 0) {

        if (this.recordMappa.showBaseLayer!=false) {
          this.layersBase.push({
            name: "Predefinita",
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            minZoom: 0,
            maxZoom: 19,
            checked: true,
          });
          this.layersBase.push({
            name: "Satellite",
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            attribution:'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            minZoom: 0,
            maxZoom: 19,
            checked: false,
          });
        }



        if (this.recordMappa && this.recordMappa.MapTiles) {
          fetch(`assets/tiles/${this.recordMappa.MapTiles}/metadata.json`)
            .then((response) => response.json())
            .then((data) => {
              var bounds = new L.LatLngBounds(
                new L.LatLng(data.bounds[1], data.bounds[0]),
                new L.LatLng(data.bounds[3], data.bounds[2])
              );

              this.layersBase.push({
                name: data.name?`Mappa ${data.name}`:"Sfondo personalizzato",
                url: `assets/tiles/${this.recordMappa.MapTiles}/{z}/{x}/{y}.${
                  data.format || "png"
                }`,
                attribution: "",
                tms: false,
                minZoom: data.minzoom || 0,
                maxZoom: data.maxzoom || 19,
                checked: false,
                transparent:"#ffffff", // Imposta il colore bianco come trasparente
                bounds: bounds,
              });
              this.selectedLayerBase = this.layersBase[0];
              observer2.next(true);
              observer2.complete();
            });
        } else {
          this.selectedLayerBase = this.layersBase[0];
          observer2.next(true);
          observer2.complete();
        }
      }
    });
  }
  private loadLayesSfondi(): Observable<any> {
    return new Observable((observer) => {
      if (!this.layersSfondi || this.layersSfondi.length == 0) {
        this.classService
          .getRelations("Mappe", this.idMappa, "Map_LayerSfondiMappe")
          .subscribe((res: any) => {
            let observableArray: any[] = [];
            res.data.forEach((element) => {
              let promise = fetch(
                `assets/tiles/${element.ObjValue.MapTiles}/metadata.json`
              )
                .then((response) => response.json())
                .then((data) => {
                  var bounds = new L.LatLngBounds(
                    new L.LatLng(data.bounds[1], data.bounds[0]),
                    new L.LatLng(data.bounds[3], data.bounds[2])
                  );
                  element.ObjValue.bounds = bounds;
                  element.ObjValue.color=data.color;
                  element.ObjValue.minzoom=data.minzoom;
                  element.ObjValue.maxzoom=data.maxzoom;

                  console.log(`LETTO metadatata ${element.ObjValue.MapTiles}`);
                  return element;
                });
              observableArray.push(from(promise));
            });
            if (observableArray.length == 0) {
              observer.next(true);
              observer.complete();
              return;
            }

            forkJoin(observableArray).subscribe((res) => {
              console.log("TUTTI I METADATA LETTI", res);

              observer.next({ data: res });
              observer.complete();
            });
          });
      }
    });
  }

  private reloadOggetti(){
    this.gisService.getGeometry(this.idMappa).subscribe((res)=>{
      //***** OGGGETTI */
      this.layersOggetti = [];
      res.data.forEach((element) => {
        var geojsonFeature = {
          type: "Feature",
          properties: JSON.parse(element.properties),
          geometry: JSON.parse(element.geometry),
        };
        if (geojsonFeature.properties.Mappe) {
          geojsonFeature.properties.Mappe = JSON.parse(
            geojsonFeature.properties.Mappe
          );
        }
        this.layersOggetti.push(geojsonFeature);
      });

      this.loading = false;
      this.addLayers();
    (err) => {
      console.error(err);
    }
    })
  }
  private loadDataMap(): Observable<any> {
    return new Observable((observer) => {
      this.loading = true;
      console.log("load Map Data......");

      const subscribeArray = [];
      // CARICA LIVELLI DI BASE
      subscribeArray.push(this.loadLayersBase());

      // CARICA OGGETTI MAPPA
      subscribeArray.push(this.gisService.getGeometry(this.idMappa));

      // CARICA LIVELLI SFONDI MAPPA
      subscribeArray.push(this.loadLayesSfondi());

      forkJoin(subscribeArray).subscribe(
        (res: any) => {
          console.log("End fork JOIN");
          let oggetti = res[1].data;
          let sfondi = null;
          if (res.length > 2) {
            sfondi = res[2].data || [];
          }

          //***** SFONDI */
          if (sfondi && sfondi.length > 0) {
            this.livelloMinimo = 0;
            this.livelloMassimo = 0;
            this.layersSfondi = sfondi.map((x) => {
              if (x.ObjValue.Livello < this.livelloMinimo) {
                this.livelloMinimo = x.ObjValue.Livello;
              }
              if (x.ObjValue.Livello > this.livelloMassimo) {
                this.livelloMassimo = x.ObjValue.Livello;
              }

              return {
                text: x.ObjValue.Code,
                url: `assets/tiles/${x.ObjValue.MapTiles}/{z}/{x}/{y}.${
                  x.ObjValue.MapTilesExtention || "png"
                }`,
                tms: false,
                minZoom: x.ObjValue.minzoom || 0,
                maxZoom: x.ObjValue.maxzoom|| 19,
                checked: false,
                Livello: x.ObjValue.Livello,
                bounds: x.ObjValue.bounds,
                transparent: "#ffffff" // Imposta il colore bianco come trasparente
              };
            });

            let distanza = this.calcolaDistanzaMedia(this.layersSfondi);
            this.livelloMassimo = this.livelloMassimo + distanza;

            this.livelloStartValue = this.livelloMinimo;
            this.livelloEndValue = this.livelloMassimo;

            // ordinamento decrescente per livello dei layersSfondi
            this.layersSfondi.sort((a, b) => {
              return b.Livello - a.Livello;
            });
            // aggiungo LivelloMax che corrisponde al Livello del successivo
            this.layersSfondi.forEach((element, index) => {
              if (index > 0) {
                element.LivelloMax = this.layersSfondi[index - 1].Livello;
              } else {
                element.LivelloMax = this.livelloMassimo;
              }
            });

            // aggiungo radio nessuno
            this.layersSfondi.push({
              text: "Nessuno",
              Livello: -9999999999,
            });
            // seleziono l'ultimo elemente dell'array layersSfondi
            this.selectedLayerSfondo =
              this.layersSfondi[this.layersSfondi.length - 1];
          }

          //***** OGGGETTI */
          this.layersOggetti = [];
          oggetti.forEach((element) => {
            var geojsonFeature = {
              type: "Feature",
              properties: JSON.parse(element.properties),
              geometry: JSON.parse(element.geometry),
            };
            if (geojsonFeature.properties.Mappe) {
              geojsonFeature.properties.Mappe = JSON.parse(
                geojsonFeature.properties.Mappe
              );
            }
            this.layersOggetti.push(geojsonFeature);
          });

          this.loading = false;
          observer.next(true);
          observer.complete();
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }
  calcolaDistanzaMedia(valori: any[]): number {
    if (valori.length < 2) {
      return 0;
    }

    let sommaDistanze = 0;

    for (let i = 0; i < valori.length - 1; i++) {
      sommaDistanze += Math.abs(valori[i].Livello - valori[i + 1].Livello);
    }

    return sommaDistanze / (valori.length - 1);
  }

  livelliChanged(e) {
    this.addLayers();
  }

  sfondiChanged(e) {
    if (!e.value || e.value.Livello == -9999999999) {
      this.livelloStartValue = this.livelloMinimo;
      this.livelloEndValue = this.livelloMassimo;
    } else {
      this.livelloStartValue = e.value.Livello;
      this.livelloEndValue = e.value.LivelloMax;
    }
    //this.map.setMaxZoom(e.value.maxzoom);
    this.addLayers();
  }

  layersBaseChanged(e) {
    this.selectedLayerBase = e.value;
    this.addLayers();
  }
  changeBreadcumb(breadcrumbItem) {
    // rimuove dal array breadcrumb la mappa corrente e le successive (se presenti)
    let index = this.breadcrumbList.findIndex((x) => x.id == breadcrumbItem.id);
    if (index > -1) {
      this.breadcrumbList.splice(index);
      this.layersBase = [];
      this.layersSfondi = [];
      this.idMappa = breadcrumbItem.id;
    }
  }

  endLoading(e) {
    this.cardComponent = e;
  }
}
