import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { DxDataGridComponent } from 'devextreme-angular';
import { ModalFilterComponent } from '../modal-filter/modal-filter.component';
import { truncate } from 'fs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-modal-list-class',
  templateUrl: './modal-list-multi-class.component.html',
  styleUrls: ['./modal-list-multi-class.component.scss'],
})
export class ModalListMultiClassComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  store: any;

  @Input('context.nameClass') nameClass: string;
  @Input('context.domainName') domainName: string;
  @Input('context.otherClass') otherClass: string;
  @Input('context.title') title: string;
  @Input('context.baseFilter') baseFilter: any;

  settings: any;
  firstColumnName: any;

  @Output() onSelected = new EventEmitter<any>();
  filter: any[];
  styleNormal: any;
  okSeleziona: boolean;

  constructor(
    private classService: GenericClassService,
    private toasterService: NbToastrService,
    protected ref: NbDialogRef<ModalFilterComponent>,
    private dialogService: NbDialogService,
    private translate: TranslateService,
  ) {
    this.styleNormal =  { 'width': (window.innerWidth * 0.9) + 'px', 'height': (window.innerHeight * 0.9) + 'px' };
  }


  ngOnInit() {
    this.okSeleziona = false;
    this.settings = { 'columns': [] };
    if (!this.nameClass) {
      console.log(this.domainName, this.otherClass);
      this.classService.getInverseClass(this.otherClass, this.domainName).subscribe((res) => {
        this.nameClass = res.data;
        this.loadData();
      });
    } else {
      this.loadData();
    }

  }
  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {

      if (item.name === 'searchPanel') {
        item.location = 'before';
      }
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',

        onClick: this.refreshDataGrid.bind(this),
      },
    });

  }

  // Preserve original property order
  originalOrder = (): number => {
    return 0;
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }
  clearFilterDataGrid() {
    this.filter = [];
  }
  addFilterDataGrid() {
    this.apriModalFilter();

  }
  // FILTRI
  apriModalFilter() {
    // crea fields
    const fields: Array<any> = [];

    // tslint:disable-next-line: forin
    for (const key in this.settings.columns) {
      const element = this.settings.columns[key];
      fields.push({
        caption: element.title,
        dataField: key,
        dataType: element.type,
      });
    }

    this.dialogService.open(ModalFilterComponent, {
      context: {
        fields: fields,
        className: this.nameClass,
        filter: this.filter,
      },
    }).onClose.subscribe(res => {
      if (res) {
        // this.dataGrid.instance.filter(res);
        this.filter = res;
      }
    });

  }

  loadData() {

    this.settings.columns = null;
    // CARICA COLONNE
    this.classService.getClass(this.nameClass).subscribe(res => {
      if (res.status == 'success') {
        // CARICA DATI
        this.store = this.classService.getDataSourceDevExp(this.nameClass, this.baseFilter);

        // Normalizza lookup e reference
        const newKeyValues = Object.keys(res.data.list).map(key => {
          const newKey = res.data.list[key].lookup || res.data.list[key].reference ? '_' + key + '_Description' : key;
          const newValue = res.data.list[key];
          newValue.title = this.translate.instant(this.nameClass + '.' + key) || newValue.title;
          const newObject = { [newKey]: newValue };
          return newObject;
        });
        res.data.list = Object.assign({}, ...newKeyValues);

        // Imposta colonne tabella
        this.settings.columns = res.data.list;
        this.settings['summary'] = res.data.summary;

        const kyz = Object.keys(this.settings.columns);
        this.firstColumnName = this.settings.columns[kyz[0]]['title'];



      } else {
        this.toasterService.danger('', `ERRORE: ${res.status} - ${res.data}`);
      }
    });
  }


  seleziona() {
    this.onSelected.emit(this.dataGrid.instance.getSelectedRowsData());
    this.ref.close(this.dataGrid.instance.getSelectedRowsData());

  }
  close() {
    this.ref.close();
  }

  onSelectionChanged(e) {
    this.okSeleziona = true;
  }
  lookup_calculateCellValue(row) {
    const column = this as any;
    const dataField = column.dataField;

    const fieldName = dataField.replace('_Description', '').replace('_', '');
    let label = '';
    if (row[dataField]) {
      if (row['_' + fieldName + '_IconFont']) {
        if (row['_' + fieldName + '_IconColor']) {
          label += `<span class="` + row['_' + fieldName + '_IconFont'] + `" style="color:` +
            row['_' + fieldName + '_IconColor'] + `"></span>&nbsp;&nbsp;`;
        } else {
          label += `<span class="` + row['_' + fieldName + '_IconFont'] + `" ></span>&nbsp;&nbsp;`;
        }
      }
      if (row['_' + fieldName + '_TextColor']) {
        label += `<span style="color:` + row['_' + fieldName + '_TextColor'] + `" >${row[dataField]}</span>`;
      } else {
        label += `<span>${row[dataField]}</span>`;
      }
      column.encodeHtml = false;
    }
    return label;
  }
  calculateCellValueDefault(row) {
    const column = this as any;
    return column.defaultCalculateCellValue(row);
  }
}
