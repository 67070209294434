import { JsReportServiceService } from './../../../@core/backend/common/services/js-report-service.service';

import { Component, OnInit, Input, Output, EventEmitter, NgZone, ChangeDetectorRef } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ModalChecklistComponentComponent } from '../../modal-checklist-component/modal-checklist-component.component';
import { NbDialogService } from '@nebular/theme';
import { UserStore } from '../../../@core/stores/user.store';
import { GenericClassService } from '../../../@core/backend/common/services/genericClass.service';
import notify from 'devextreme/ui/notify';
import { ProcessService } from '../../../@core/backend/common/services/process.service';
import { forkJoin, of } from 'rxjs';


@Component({
  selector: 'btnCheckList',
  templateUrl: './table-action-open-checklist-component.component.html',
  styleUrls: ['./table-action-open-checklist-component.component.scss'],
})
export class TableActionOpenChecklistComponentComponent implements ViewCell, OnInit {


   @Input() value: any;
   @Input() rowData: any;

   @Output() updateResult = new EventEmitter<any>();
   @Output() compiledCheckList = new EventEmitter<any>();

   user: any;
   canCompile: boolean = false;
  loadingStampa: boolean;

   constructor(
    private ref: ChangeDetectorRef,
    private dialogService: NbDialogService,
    private userStore: UserStore,
    private classService: GenericClassService,
    private processService: ProcessService,
    private reportService: JsReportServiceService,
   ) { }

  ngOnInit() {
    console.log('INIT btnCheckList');
    this.rowData = this.rowData.ObjValue || this.rowData;
     this.user = this.userStore.getUser();
     /*
     if (!this.value.unitaPr) {
      // è il caso in cui non viene passato l'asset al componente ma solo il suo id

      if (!this.value.idAsset) {
        this.value.idAsset = this.rowData.ObjValue ? this.rowData.ObjValue.Asset : null;
      }

      if (false && this.value.idAsset) {
        // carico asset e setto this.value.unitaPr
        this.classService.getRelations('Asset', this.value.idAsset, 'Map_Asset_UnitaPr').subscribe((unitaPr) => {
          this.value.unitaPr = unitaPr.data.length > 0 ? unitaPr.data[0].IdObj2 : null;
          this.canCompile = this.calculateCanCompile();
          this.ref.markForCheck();

        });

      }
    } else {
      this.canCompile = this.calculateCanCompile();
      this.updateResult.emit(this.canCompile);
    }
    */

  }

  calculateCanCompile() {
    return true;
    if (this.rowData) {

      // gia eseguita non posso riconpilare
      if (this.rowData['Eseguita']) return false;
      return true;
      this.rowData['ObjValue.Esecutore'] = this.rowData['ObjValue.Esecutore'] ? this.rowData['ObjValue.Esecutore'] : this.rowData['Esecutore'];
      console.log(this.user.persona.mansioni);
      console.log('Può eseguire ruolo = ' + this.rowData['ObjValue.Esecutore'] + '/' + this.value.unitaPr);

      if (!this.rowData['ObjValue.Esecutore'] ) {
        // SE nell'azione di verifica non c'è l'esecutore -> tutti possono eseguire
        // oppure se non c'è unitaPr su oggetto associato
        return true;
      } else {
        if (!this.value.unitaPr) {
          // è il caso in cui non viene passato l'asset al componente ma solo il suo id
          if (this.value.idAsset) {
            return false;
          } else {
            return false;
          }
        }

        // verifico che la coppia ruolo/unitapr sia una mansione della persona loggata
        if (this.user && this.user.persona && this.user.persona.mansioni) {
          const found = this.user.persona.mansioni.find(x => x.IdRuolo == this.rowData['ObjValue.Esecutore'] && x.IdUnitaPr == this.value.unitaPr);
          console.log('TROVATO', found);
          return found ? true : false;
        }
      }

    }

    return false;
  }
  stampa(data) {
    console.log(data)
    if (data.ObjValue) data = data.ObjValue;
    const schedaId = data.Scheda;
    this.loadingStampa = true;
    forkJoin(
      this.classService.getDettagliScheda('VerificaScheda', schedaId),
      this.classService.getDomande(data.Eseguita||data._Scheda_Code=="99-Rifiuti" ? data.Id : schedaId),

      data.Asset ? this.classService.getDettagliScheda('Asset', data.Asset) : this.classService.getDettagliScheda('Azienda', data.Azienda),
      data.Asset ? this.classService.getRelations(
        'Asset',
        data.Asset,
        'Map_Impatto_Asset',
      ) : of({data: []}),
      this.classService.getHeaderFooterScheda(data.Id),
      this.classService.getData('AziendaCategoria'),
      this.classService.getDettagliScheda('Personale', data.IdPersonaEsecutore)
    ).subscribe(
      ([verificaRes, domandaRes, assetRes, assetImpattiRes, headerFooterRes, categorieRes, esecutoreRes]) => {
        // CARICA DATI
        forkJoin(
          assetRes.data.Reference && assetRes.data.Reference.Sito?this.classService.getDettagliScheda('Holding', assetRes.data.Reference.Sito.Holding) : of({data: []}),
          assetRes.data.Reference && assetRes.data.Reference.Ubicazione?this.classService.getBreadcrumb('Ubicazione', assetRes.data.Ubicazione) : of({data: []}),
          data.OriginalId ? this.classService.getDomande(data.OriginalId) : of({data: []}),
          this.classService.getCardFiltered("VerificheUtenti","IdVerifica",data.Id)
        ).subscribe(([assetHoldingRes, assetUbicazioneRes,parentDomande,logRes]) => {
          headerFooterRes.data.map(x => {
            if (x.Valore.startsWith('{')) {
              x.Valore = JSON.parse(x.Valore);
            }
            return x;
          })
          console.log(domandaRes.data)
          var objLog:any = {
            Responsabile: {},
            Preposto: {},
            Esecutore: {}
          };
          if(logRes.data.list && logRes.data.list.length > 0) {
            logRes.data.list.sort(compare)
            logRes.data.list.forEach(log => {
              if(log.RuoloLabel.includes("Responsabile")) {
                switch(log.Action) {
                  case 'view':
                    objLog.Responsabile.view = log;
                    break;
                  case 'Salvataggio definitivo':
                    objLog.Responsabile.save = log;
                    break;
                  case 'Salvataggio bozza':
                    objLog.Responsabile.saveBozza = log;
                    break;
                  case 'Presa in carico':
                    objLog.Responsabile.presaInCarico = log;
                    break;
                }
              } else if(log.RuoloLabel.includes("Preposto")) {
                switch(log.Action) {
                  case 'view':
                    objLog.Preposto.view = log;
                    break;
                  case 'Salvataggio definitivo':
                    objLog.Preposto.save = log;
                    break;
                  case 'Salvataggio bozza':
                    objLog.Preposto.saveBozza = log;
                    break;
                  case 'Presa in carico':
                    objLog.Preposto.presaInCarico = log;
                    break;
                }
              } else if(log.RuoloLabel.includes("Esecutore")) {
                switch(log.Action) {
                  case 'view':
                    objLog.Esecutore.view = log;
                    break;
                  case 'Salvataggio definitivo':
                    objLog.Esecutore.save = log;
                    break;
                  case 'Salvataggio bozza':
                    objLog.Esecutore.saveBozza = log;
                    break;
                  case 'Presa in carico':
                    objLog.Esecutore.presaInCarico = log;
                    break;
                }
              }
            });
          }
          const obj = {
            scheda: verificaRes.data,
            verifica: data,
            domanda: this.classService.processDomande(domandaRes.data),
            header_footer: this.classService.convertArrayToObject(headerFooterRes.data, 'Chiave', 'Valore'),
            asset: assetRes.data,
            assetImpatti: assetImpattiRes.data,
            breadcrumb: assetUbicazioneRes.data.reverse(),
            holding: assetHoldingRes.data ? assetHoldingRes.data.Description : '',
            domandaParent:parentDomande.data,
            categorieAziende: categorieRes.data.list,
            esecutore: esecutoreRes.data,
            logVerifica: objLog
          };
          console.log(JSON.stringify(obj));
          this.stampaReport(data._Scheda_TipoBase, obj);
        });
      },
    );
  }

  stampaReport(report, data) {
    this.reportService.getReport(report, data).subscribe((res) => {
      this.loadingStampa  = false;
      this.reportService.openPDF(res, 'verifica.pdf');
      //this.reportService.saveAsPDF(res, 'invoice');

    }, (err) => {
      console.log(err);
      this.loadingStampa  = false;
    }, () => {
      this.loadingStampa  = false;
    });
  }
  clicked(data) {

    // TEST CREAZIONE WORKFLOW

   /*  this.processService.startWorkflow('Verifica', data, data.Description || data.ObjValue.Description).subscribe((res) => {

      notify('Avviato processo verifica', 'success');
    });

    return; */

    console.log(data);
    this.dialogService.open(ModalChecklistComponentComponent, {
      closeOnEsc: false,
      context: {
        titleModal: 'Anteprima scheda verifica',
        verificaId: data.Scheda || data.ObjValue.Scheda,
        schedaId: data.Scheda ? data.Id : data.ObjValue.Id,
        mode: 'view',
        isPresaIncarico: false,
      }, hasBackdrop: true,

    }).onClose.subscribe((res) => {

    });
  }

}

function compare( a, b ) {
  if ( a.BeginDate < b.BeginDate ){
    return -1;
  }
  if ( a.BeginDate > b.BeginDate ){
    return 1;
  }
  return 0;
}
