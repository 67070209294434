import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-month-picker',
  template: `<div>
  <div class="col-xs-2">
  <select class="form-control"  required (change)="onMonthChange($event.target.value)">
          <option  *ngFor="let p of months" [value]="p.val" [selected]="mm === p.val " >{{p.name}}</option>
  </select>
  </div>
  </div>`,
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  mm: string;
  months = [
    { val: '01', name: 'Gennaio' },
    { val: '02', name: 'Febbraio' },
    { val: '03', name: 'Marzo' },
    { val: '04', name: 'Aprile' },
    { val: '05', name: 'Maggio' },
    { val: '06', name: 'Giugno' },
    { val: '07', name: 'Luglio' },
    { val: '08', name: 'Agosto' },
    { val: '09', name: 'Settembre' },
    { val: '10', name: 'Ottobre' },
    { val: '11', name: 'Novembre' },
    { val: '12', name: 'Dicembre' },
  ];
  constructor() {
    console.log('START MONTH PICKER');
  }
  getMonth() {
    const today = new Date();
    const mm = today.getMonth() + 1;
    if (mm < 10) {
    this.mm = '0' + mm;
        }
    }
  ngOnInit() {
    this.getMonth();
    this.onChange.emit(this.mm);
  }
  onMonthChange(val) {

    this.onChange.emit(val);
  }
}
