import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Observable, of } from 'rxjs';
import { HttpService } from '../api/http.service';
import * as AspNetData from "devextreme-aspnet-data-nojquery";

@Injectable()
export class SchedulerService {
    private readonly apiController: string = 'class';


    constructor(private api: HttpService) { }



    getDataSourceDevExp(nameClass: string,idCard:number) {
        nameClass = nameClass.replace(/"/g, '');
        
        let url=`${this.api.apiUrl}/${this.apiController}/${nameClass}/scadenze/${idCard}`;
        return AspNetData.createStore({
            key: "Id",
            loadUrl: url ,
            insertUrl: url,
            updateUrl: url ,
            deleteUrl: url ,
            errorHandler:function(e) {
                console.log("ERRORE",e)
                return []
            },
            
        });
        return new CustomStore({
            key: "Id",
            
            load: (loadOptions: any)=> {     
                /* return this.api.post(url, loadOptions).toPromise().then(result=>{
                    
                    return result.data
                }); */
                return of([]).toPromise()
                 
            }
        });
        


    }
    

}
