import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';



@Injectable()
export class IstanceClassMockService {
    private readonly apiController: string = 'class';

    constructor(private api: HttpService) { }

    post(nameClass: string, item: any): Observable<any> {
        return this.api.post(`${this.apiController}/${nameClass}/cards`, item);
    }

    put(nameClass: string, item: any, id: number): Observable<any> {
        return this.api.post(`${this.apiController}/${nameClass}/cards/${id}`, item);
    }

    delete(nameClass: string, id: number): Observable<any> {
        return this.api.delete(`${this.apiController}}/${nameClass}/cards/${id}`);
    }


}
