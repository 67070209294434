import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-datepicker',
  template: `
  <div class="form-group" [class.has-error]="showError">
    <div *ngIf="to.label && to.hideLabel !== true" class="ui-widget">
      <label [for]="id">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
    </div>
    <dx-date-box id="{{key}}" dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                [formControl]="formControl" [formlyAttributes]="field"
                type="date" (onValueChanged)="onValueChanged($event)" [min]="to.minDate" [max]="to.maxDate">
            </dx-date-box>


    <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </div>
   `,
})


export class FormlyFieldDatepicker extends FieldType {

  constructor() {

    super();
  }
  onValueChanged(e: any) {
      console.log(e);

  }
}
