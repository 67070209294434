import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { SchedulerService } from '../../@core/backend/common/services/scheduler.service';

@Component({
  selector: 'ngx-schedular-class',
  templateUrl: './schedular-class.component.html',
  styleUrls: ['./schedular-class.component.scss']
})
export class SchedularClassComponent implements OnInit, OnChanges {

  @Input() className: string = '';
  @Input() cardId: number = 0;
  @Input() scadenzeNonInserite: any = [];
  @Output() endLoading = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();

  @ViewChild('scheduler',{static:true}) public scheduler: DxSchedulerComponent

  appointmentsData: any;
  currentDate: Date = new Date();
  test: []
  constructor(
    private schedulerService: SchedulerService

  ) { }
  ngOnChanges(changes: SimpleChanges): void {

    this.refresh();
  }

  ngOnInit() {
  }

  loadData() {
    this.appointmentsData = this.schedulerService.getDataSourceDevExp(this.className, this.cardId);

  }
  refresh() {
    if (this.className && this.cardId) {
      this.loadData()
    }
  }

  repaint(){
    setTimeout(() => {
      this.scheduler.instance.repaint();
    }, 1);
   
  }

  appointmentAdded(e){
    this.change.emit(true);
  }
  
  appointmentUpdated(e){
    this.change.emit(true);
  }
  appointmentDeleted(e){
    this.change.emit(true);
  }


  getTipologiaByKey(id) {
    return this.scadenzeNonInserite.find(x => x.Key === id)

  }
  onAppointmentFormOpening(data) {
    console.log("onAppointmentFormOpening")
    var that = this,
      form = data.form,
      isEdit = data.appointmentData.Id ? true : false

    let itemTipologia =
    {
      colSpan: 2,
      label: {
        text: "Tipologia"
      },
      editorType: "dxSelectBox",
      dataField: "Key",
      editorOptions: {
        items: that.scadenzeNonInserite,
        displayExpr: "Description",
        valueExpr: "Key",
        onValueChanged: function (args) {
          console.log(args);
          let tipologiaInfo = that.getTipologiaByKey(args.value);
          form.getEditor("Text")
            .option("value", tipologiaInfo.Description)
        }.bind(this)
      }
    }
    let itemTipologiaRead = {
      colSpan: 2,
      label: {
        text: "Tipologia"
      },
      name: "Key",
      dataField:"Key",
      editorType: "dxTextBox",
      editorOptions: {
        readOnly: true
      }
    }

    let mainGroupItems = form.itemOption('mainGroup').items;
    // rimuovo campo tipologia
    mainGroupItems = mainGroupItems.filter(x => x.dataField != "Key" && x.name != "Key")

    // Se è di tipo scadenza
    if ( data.appointmentData.Key) {
       
    }

    if (!isEdit) {
      if (this.scadenzeNonInserite && this.scadenzeNonInserite.length > 0) {
        mainGroupItems = [itemTipologia, ...mainGroupItems]
      } 
    } else {
      if (data.appointmentData.Key) {
        mainGroupItems = [itemTipologiaRead, ...mainGroupItems]}
    }

    form.itemOption('mainGroup', 'items', mainGroupItems);

  }

}
