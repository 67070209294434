import { Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ngx-call-action-links',
  styleUrls: ['./call-action-links.component.scss'],
  template: `
  <div class="card" >
    
    <div class="card-body">
      <h5 class="card-title">{{title}}</h5>
      <p class="card-text">{{msg}}</p>
      <ul *ngIf="links" class="list-group list-group-flush">
        <li *ngFor="let link of links " class="list-group-item pl-0 pr-0">
          <i class="fas fa-circle text-info"></i>&nbsp;&nbsp;{{link.title}}
        </li>
        
      </ul>
    </div>
  </div>
  `,
})
export class CallActionLinksComponent implements OnDestroy {

  private alive = true;

  @Input() title: string;
  @Input() type: string;
  @Input() links: any[];
  @Input() msg: string;

  currentTheme: string;

  constructor(private themeService: NbThemeService) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
