import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Injectable, Input, NgZone, HostBinding, ViewChild } from '@angular/core';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import { Console } from 'console';
import { DxTreeViewComponent } from 'devextreme-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { UserStore } from '../../@core/stores/user.store';
declare var bootbox: any;
@Component({
  selector: 'ngx-modal-select-mansione',
  templateUrl: './modal-select-mansione.component.html',
  styleUrls: ['./modal-select-mansione.component.scss'],
})

export class ModalSelectMansioneComponent implements OnInit {

  @Input('context.id') id: number;
  @Input('context.title') titleModal: string;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  mansioni: any;
  itemSelected: any;
  storeUbicazioni: any;
  rootValue: any;
  ubicazione: any;
  persona: any;
  oldState: any;
  @ViewChild(DxTreeViewComponent, { static: false }) tree: DxTreeViewComponent;

  constructor(
    public ref: NbDialogRef<ModalSelectMansioneComponent>,
    public iconsLibrary: NbIconLibraries,
    public ngZone: NgZone,
    public genericClassService: GenericClassService,
    public userStore: UserStore,
    private spinner: NgxSpinnerService,
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {
    this.spinner.show("mansioni")
    var user = this.userStore.getUser();
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadData(user);

  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.spinner.show("all");
      this.genericClassService.updateDettagliScheda("AccessiPersonale",{
        Id: this.oldState ? this.oldState[0].Id : null,
        UtenteInterno: this.persona,
        Mansione: this.itemSelected,
        Ubicazione: this.ubicazione
      }).subscribe((resUpdate:any)=>{
        console.log(resUpdate);
        this.spinner.hide("all");
        this.ref.close(true)
      });
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + "px", 'height': this.height + "px" };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + "px", 'height': (this.height * 0.8) + "px" };
  }

  loadData(user): void {
    this.persona = user.persona.Id;
    console.log(user);
    var filter = null
    if(user.roles[0].role.code == "admin") {
      filter = []
    } else if(user && user.persona && user.persona.Id) {
      filter = ["Persona","=",user.persona.Id];
    } else {
      this.spinner.show("error");
      return ;
    }
    this.genericClassService.getDataSourceDevExpNoFilter("Mansione",filter).load().then((res: any) => {
      console.log(res);
      this.mansioni = res.data;
      this.spinner.show("albero");
      this.genericClassService.getDataSourceDevExpNoFilter("AccessiPersonale",["UtenteInterno","=",this.persona]).load().then((res:any)=>{
        this.oldState = res.data.length > 0 ? res.data : null;
        if(this.oldState) {
          this.itemSelected = this.oldState[0].Mansione,
          this.ubicazione = this.oldState[0].Ubicazione
          this.mansioni.forEach(mansione => {
            if(mansione.Id == this.itemSelected) {
              this.itemSelected = mansione.Id;
              this.ubicazione = mansione.UnitaPr
              let rootValue =  mansione.UnitaPr ?  mansione.UnitaPr : null;
              this.genericClassService.getDataSourceDevExpNoFilter("Ubicazione", [], true, rootValue).load().then((res: any) => {
                this.storeUbicazioni = [res];
               
                setTimeout(() => {
                  this.spinner.hide("albero");
                  this.spinner.hide("mansioni")
                  this.tree.instance.expandAll();
                }, 200);
          
              });
            }
          });
        } else {
          this.spinner.hide("albero");
          this.spinner.hide("mansioni")
        }
      });
    });
  }

  selectUbicazione(e) {
    console.log(e);
    this.ubicazione = e.itemData.value.id;
  }

  selectMansione(mansione) {
    this.spinner.show("albero");
    this.itemSelected = mansione.itemData.Id;
    this.ubicazione = mansione.itemData.UnitaPr
    let rootValue =  mansione.itemData.UnitaPr ?  mansione.itemData.UnitaPr : null;
    this.genericClassService.getDataSourceDevExpNoFilter("Ubicazione", [], true, rootValue).load().then((res: any) => {
      this.storeUbicazioni = [res];
    
      setTimeout(() => {
        this.spinner.hide("albero")
        this.tree.instance.expandAll();
      }, 200);

    });
   
  }


  delete() {

    bootbox.confirm(
      'Confermi di voler eliminare la configurazione?',
      (r: any) => {
        if (r == true) {
          this.spinner.show("all");
          this.genericClassService.deleteScheda("AccessiPersonale",this.oldState[0].Id).subscribe((resDelete:any)=>{
            this.spinner.hide("all")
            this.ref.close(true);
          });
        }
      },
    );
  }

}
