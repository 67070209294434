import { Component, OnInit, Injectable, Input, NgZone, HostBinding, ViewChild } from '@angular/core';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import { FormClassComponent } from '../form-class/form-class.component';

@Component({
  selector: 'ngx-modal-class-edit',
  templateUrl: './modal-class-edit.component.html',
  styleUrls: ['./modal-class-edit.component.scss'],
})

export class ModalClassEditComponent implements OnInit {

  @Input('context.id') id: number;
  @Input('context.reference') reference: string;
  @Input('context.referenceId') referenceId: number;
  @Input('context.referenceModel') referenceModel: any;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;

  @Input() className: string = '';
  @Input() cardId: number = 0;

  @ViewChild(FormClassComponent, {static: true}) public cardComponent: FormClassComponent;

  constructor(
    protected ref: NbDialogRef<ModalClassEditComponent>,
    iconsLibrary: NbIconLibraries,
    private ngZone: NgZone,
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadData();
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.cardComponent.save(null).subscribe((savedCard) => {
      if (savedCard !== false) {
        this.ref.close(savedCard);
      }

    });
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + 'px', 'height': this.height + 'px' };
  }

  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + 'px', 'height': (this.height * 0.8) + 'px' };
  }
  loadData(): void {
  }


  refresh() {

  }

}
