import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';



@Injectable()
export class GruppiService {
    private readonly apiController: string = 'roles';
    private readonly apiControllerUser: string = 'users';

    constructor(private api: HttpService) { }
    get(id: number): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    delete(id: number): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    post(item: any): Observable<any> {
        return this.api.post(`${this.apiController}`, item);
    }

    put(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    getAll(): Observable<any> {
        return this.api.get(`${this.apiController}`);
    }

    assignRole(id: number, codeRole: string): Observable<any> {

      return this.api.post(`${this.apiControllerUser}/${id}/roles?roleCode=${codeRole}`, {});
    }

    removeRole(id: number, codeRole: string): Observable<any> {

      return this.api.delete(`${this.apiControllerUser}/${id}/roles?roleCode=${codeRole}`, {});
    }

}
