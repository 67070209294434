import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import { map } from 'rxjs/operators';
import { AppSettingsService } from './appsettings.service';

@Injectable()
export class ProcessNifiService {
    private readonly apiController: string = 'nifi';
    
    constructor(private api: HttpService, private appSettingsService: AppSettingsService) {
        
    }

    getDataSource() {
        return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
    }

    get(): Observable<any> {
        return this.api.get(`${this.apiController}`)
            .pipe(map(data => {
                return { ...data };
            }));
    }

    post(item: any): Observable<any> {
        return this.api.post(`${this.apiController}`, item);
    }
}
