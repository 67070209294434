import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NbDialogRef, NbIconLibraries, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import CustomStore from 'devextreme/data/custom_store';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';

@Component({
  selector: 'ngx-modal-cruscotto-list-asset',
  templateUrl: './modal-cruscotto-list-asset.component.html',
  styleUrls: ['./modal-cruscotto-list-asset.component.scss'],
})

export class ModalCruscottoListAssetComponent implements OnInit {

  @Input('context.title') title: any;
  @Input('context.classe') classe: any;

  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  store: CustomStore;
  baseFilter: any = null;
  currentClass: any;
  firstColumnName: any;
  itemsMenuClassAdd: any[];
  sortingFields: any[];
  elementSelected: any;

  settings = {
    mode: 'external',
    hideSubHeader: true,
    pager: {
      perPage: 25,
      display: true,
      // perPageSelect : [2,5,10]
    },
    actions: {
      columnTitle: '',
      position: 'right',
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'viewAction',
          title: '<i class="fas fa-file-alt iconAwesomeAction" title="Apri scheda" ></i>',
        },
        {
          name: 'editAction',
          title: '<i class="fas fa-edit iconAwesomeAction" title="Modifica"></i>',
        },
        {
          name: 'deleteAction',
          title: '<i class="fas fa-trash-alt iconAwesomeAction" title="Elimina"></i>',
        },
      ],
    },

    columns: {},

  };

  constructor(
    public ref: NbDialogRef<ModalCruscottoListAssetComponent>,
    public iconsLibrary: NbIconLibraries,
    public ngZone: NgZone,
    private classService: GenericClassService,
    private translate: TranslateService,
    private toasterService: NbToastrService,
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadData(this.classe);
  }

  loadData(nameClass) {
    this.title = '';
    this.store = null;
    // CARICA DATI
    this.store = this.classService.getDataSourceDevExp(nameClass, this.baseFilter);
    // CARICA COLONNE
    this.classService.getClass(nameClass).subscribe(res => {
      if (res.status === 'success') {
        this.currentClass = res.data.classData;

        this.currentClass.label = this.translate.instant(this.currentClass.className + '.className')
        || this.currentClass.label;

        this.title = res.data.classData.label;
        this.itemsMenuClassAdd = res.data.classData.listClass;
        // Sorting e Normalizza lookup e reference
        const newKeyValues = Object.keys(res.data.list).map(key => {
          const newKey = res.data.list[key].lookup || res.data.list[key].reference ? '_' + key + '_Description' : key;
          const newValue = res.data.list[key];
          if (this.sortingFields && this.sortingFields[key]) {
            newValue.sortOrder = this.sortingFields[key].direction;
            newValue.sortIndex = this.sortingFields[key].index;
          }


          newValue.title = this.translate.instant(this.currentClass.className + '.' + key) || newValue.title;

          const newObject = { [newKey]: newValue };
          return newObject;
        });
        res.data.list = Object.assign({}, ...newKeyValues);

        // Imposta colonne tabella
        this.settings.columns = res.data.list;
        this.settings['summary'] = res.data.summary;

        const kyz = Object.keys(this.settings.columns);
        this.firstColumnName = this.settings.columns[kyz[0]]['title'];


        if (nameClass === 'Verifica') {

          console.log('Aggiunta azione di verifica');

          const column = {
            title: '',
            type: 'buttons',
            allowFiltering: false,
            width: '110',
            alignment: 'center',
            fixed: true,
            fixedPosition: 'right',
            template: 'templateAvviaVerifica',
          };
          this.settings.columns['customColumn2'] = column;

        }



      } else {
        this.toasterService.danger('', `ERRORE: ${res.status} - ${res.data}`);
      }
    });
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const obj = {
      'columns': this.settings.columns,
      'data': this.elementSelected,
    };
    this.ref.close(obj);
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + 'px', 'height': this.height + 'px' };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + 'px', 'height': (this.height * 0.8) + 'px' };
  }

  onFocusedRowChanged(e) {
    this.elementSelected = e.data;
  }

  refresh() {

  }

}
