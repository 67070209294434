import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { CustomServerDataSource } from '../services/CustomServerDataSource';
import { EnvService } from '../services/env.service';
import moment from 'moment';


@Injectable()
export class HttpService {

  constructor(private http: HttpClient, private env: EnvService) { }

  get apiUrl(): string {
    return this.env.apiUrl;
  }


  getServerDataSource(uri: string): DataSource {
    return new CustomServerDataSource(this.http,
      {
        endPoint: uri,
        totalKey: 'data.count',
        dataKey: 'data.list',
        pagerPageKey: 'pageNumber',
        pagerLimitKey: 'pageSize',
        filterFieldKey: '#field#:#operator#:#search#',
        sortFieldKey: 'orderBy',
        sortDirKey: 'sortBy',
      });
  }

  getCard(nameClass: string)
  {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiUrl}/class/${nameClass}/cards`;
    return this.http.get(url);
  }
  postDir(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${endpoint}`, data, options);
  }
   
  baseFunction() {
    return {
      today: moment().format('YYYY-MM-DD'),
    };
  }

  getDataSourceDevExp(
    nameClass: string,
    baseFilter: any,
    isTree: boolean = false,
    treeRoot = null,
    sortField=null,
    getAll:boolean=false
  ) {
    nameClass = nameClass.replace(/"/g, '');
    let url = `${this.apiUrl}/class/${nameClass}/cardsExp`;
    const urlUpdate = `${this.apiUrl}/${nameClass}/cardsExpUpdate`;
    const urlDelete = `${this.apiUrl}/${nameClass}/cards`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions) => {
        if (getAll) {
          loadOptions.skip=0;
          loadOptions.take=1000;
        }
        if (baseFilter) {
          const baseFilterC = this.calculateExpression(
            baseFilter,
            this.baseFunction(),
          );

          if (loadOptions.filter) {
            loadOptions.filter = [baseFilterC, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilterC;
          }
        }
        
        if (!loadOptions.sort) {
          if (sortField) {
            loadOptions.sort = [{ selector: sortField, desc: false },{ selector: 'Description', desc: false }];
          } else {
            // ORDINAMENTO DI DEFAULT
            loadOptions.sort = [{ selector: 'Description', desc: false }];

          }

        }
        const queryParams = [];
        if (treeRoot) {
          queryParams.push('treeRoot=' + treeRoot);
        }
        if (isTree) queryParams.push('isTree=true');

        const queryParamString = queryParams.join('&');

        url = url + (queryParamString ? '?' + queryParamString : '');
        return this
          .postDir(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      update: (key, values) => {
        const params = {
          key: key,
          values: values,
        };
        return this
          .postDir(urlUpdate, params)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      remove: (key) => {
        return this
          .deleteDir(`${urlDelete}/${key}/`)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  get(endpoint: string, options?): Observable<any> {

    return this.http.get(`${this.apiUrl}/${endpoint}`, options);
  }
  
  calculateExpression(f, o) {
    'use strict';
    if (!f) return null;
    const copy = [...f];
    const m = o;
    copy.forEach((element, index) => {
      try {
        if (Array.isArray(element)) {
          copy[index] = this.calculateExpression(element, m);
        } else {
          copy[index] = eval(element);
        }
      } catch (error) {}
    });

    return copy;
  }

  deleteDir(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
  }

  post(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${this.apiUrl}/${endpoint}`, data, options);
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiUrl}/${endpoint}`, data, options);
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
  }
}
