import { Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ngx-call-action-card',
  styleUrls: ['./call-action-card.component.scss'],
  template: `
  <a  [routerLink]="myRLink" [queryParams]="queryParams" href="{{ link }}" style="text-decoration:none">

    <nb-card>
      <div class="icon-container hidden-sm-down">
        <span *ngIf="iconType=='font'" class="icon iconbg {{icon}}"></span>

        <span *ngIf="iconType=='badge'" class="icon iconbg fa-stack">

            <span class=" far fa-circle" style="font-size:1.5em"></span>

            <span class=" fa-stack-1x">
                {{icon}}
            </span>
        </span>
        <img *ngIf="iconType=='image'" src="assets/images/{{icon}}" class="img-fluid icon">
      </div>

      <div class="details">

          <div class="title">{{ title }}</div>
          <div *ngIf="msg" class="body">{{ msg }}</div>
      </div>

    </nb-card> </a>
  `,
})
export class CallActionCardComponent implements OnDestroy {

  private alive = true;

  @Input() title: string;
  @Input() iconType: string;
  @Input() icon: string;
  @Input() link: string;

  private _rLink: string;
  public get rLink(): string {
    return this._rLink;
  }
  @Input() public set rLink(value: string) {
    this._rLink = value;
    this.setRLink();
  }


  private _queryParams: any;
  public get queryParams(): any {
    return this._queryParams;
  }
  @Input() public set queryParams(value: any) {
    this._queryParams = value;
    if (typeof this._queryParams === 'string' ) {
      this._queryParams = JSON.parse(this._queryParams);
    }
    this.setRLink();
  }
  @Input() msg: string;

  currentTheme: string;
  myRLink: string[];

  constructor(private themeService: NbThemeService) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });
  }

setRLink() {
  this.myRLink = [this._rLink];
    if (this._rLink == '.' && this._queryParams && this._queryParams.parent) {
      this.myRLink = [this._rLink, this._queryParams.parent];
      this._queryParams = null;
    }
}
  ngOnDestroy() {
    this.alive = false;
  }
}
