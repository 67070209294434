import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-datetimepicker',
  template: `
  <div class="form-group" [class.has-error]="showError">
    <div *ngIf="to.label && to.hideLabel !== true" class="ui-widget">
      <label [for]="id">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
    </div>
    <dx-date-box id="{{key}}"
                [formControl]="formControl" [formlyAttributes]="field"
                type="datetime" [min]="to.minDate" [max]="to.maxDate">
            </dx-date-box>

    <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div>

  </div>
   `,
})


export class FormlyFieldDateTimepicker extends FieldType implements OnInit {

  constructor() {

    super();
  }
  ngOnInit(): void {

  }


}
