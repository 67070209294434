import { Observable } from 'rxjs';
import { User, UserData } from '../../@core/interfaces/common/users';
import { tap } from 'rxjs/operators';
import { UserStore } from '../../@core/stores/user.store';
import { Injectable } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Injectable()
export class InitUserService {
    constructor(protected userStore: UserStore,
        protected usersService: UserData,
        protected themeService: NbThemeService) { }

    initCurrentUser(): Observable<User> {
        return this.usersService.getCurrentUser()
            .pipe(tap((userData: any) => {
                if (userData.status == 'success') {
                    const user: User = userData.data;
                    if (user) {
                      console.log("USER STORE=",user);
                        this.userStore.setUser(user);
                        this.themeService.changeTheme('cmdb_operator');
                    }
                }
            }));
    }
}
