import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetStatisticheSettoriComponent } from './widget-statistiche-settori/widget-statistiche-settori.component';
import { WidgetStatisticheStatiComponent } from './widget-statistiche-stati/widget-statistiche-stati.component';
import { DxPieChartModule } from 'devextreme-angular';
import { DxDataGridModule } from 'devextreme-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WidgetStatisticheSituazioneComponent } from './widget-statistiche-situazione/widget-statistiche-situazione.component';


@NgModule({
  declarations: [WidgetStatisticheSettoriComponent, WidgetStatisticheStatiComponent, WidgetStatisticheSituazioneComponent],
  imports: [
    CommonModule,
    DxPieChartModule,
    DxDataGridModule,
    NgxSpinnerModule,

  ],
  exports: [WidgetStatisticheSettoriComponent,WidgetStatisticheStatiComponent,WidgetStatisticheSituazioneComponent]
})
export class StatisticheModule { }
