import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';
import * as AspNetData from "devextreme-aspnet-data-nojquery";

@Injectable()
export class WidgetService {
  private readonly apiController: string = 'widget';

  constructor(private api: HttpService) {}


  getCruscotti(): Observable<any> {

    return this.api.get(`${this.apiController}/cruscotti`);
  }

  deleteCruscotti(id: number): Observable<any> {

    return this.api.delete(`${this.apiController}/cruscotti/${id}`);
  }

  createCruscotti(data: any): Observable<any> {

    return this.api.post(`${this.apiController}/cruscotti`, data);
  }

  getHome(homeName: string = ''): Observable<any> {
    homeName = !homeName ? '' : homeName;
    return this.api.get(`${this.apiController}/getHome/${homeName}`);
  }

  getVerificheInCarico(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getVerificheInCarico`);
  }

  getSchedeNonConformi(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getSchedeNonConformi`);
  }

  getRigheNonConformi(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getRigheNonConformi`);
  }

  getRilevazioni(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getRilevazioni`);
  }

  getOpt()
  {
    const url = `${this.apiController}/getNotifiche`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {

            return data;
          });
      },
    });

    return dataSource;
  }

  getNotifiche(nameClass: string = '',opt:any) {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getNotifiche`,opt);
  }

  getVerificheEseguite(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getVerificheEseguite`);
  }

  getRiepilogoUbicazioni(): Observable<any> {

    return this.api.get(`${this.apiController}/location/getTypeCount`);
  }

  getAssetCountByAttrib(attribName: string, masterClass: string = ''): Observable<any> {

    return this.api.get(`${this.apiController}/asset/getCountByAttrib/${attribName}/${masterClass}`);
  }
  getUbicazioniTree(): Observable<any> {
    return this.api.get(`${this.apiController}/placement/tree`);
  }

  getPlacementAsset() {
    const url = `${this.apiController}/asset/getPlacement`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {

            return data;
          });
      },
    });

    return dataSource;
  }
  getPlacementAssetLinee() {
    const url = `${this.apiController}/asset/getLinePlacement`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getAssetImpatti() {
    const url = `${this.apiController}/asset/getImpatti`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getCompitiInScadenza(
    userId: number,
    filterDate: number,
  ): CustomStore {
    const url = `${this.apiController}/getCompitiInScadenza/${userId}/${filterDate}`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getMansione(idPersona) {
    return this.api.get(`${this.apiController}/getMansioni/${idPersona}`);
  }


  getCountCompitiScaduti(userId: number)
  {
    return this.api.get(`${this.apiController}/getCountCompitiScaduti/${userId}`);
  }

  getCompitiScaduti(
    userId: number,
    filterDate: number,
  ): CustomStore {
    const url = `${this.apiController}/getCompitiScaduti/${userId}/${filterDate}`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getSchedeVerifichePerAmbito(
    userMansioni: any
  ): CustomStore {
    var mansioni: any[] = [];
    const url = `${this.apiController}/getSchedeVerifichePerAmbito`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        userMansioni.forEach(element => {
          mansioni.push(element.IdRuolo);
        });
        loadOptions.filter = [];
        loadOptions.userData = mansioni;

        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      }
    });

    return dataSource;
  }

  getResponsabileAssetInManutenzione(
    userMansioni: any
  ): CustomStore {
    var mansioni: any[] = [];
    const url = `${this.apiController}/getResponsabileAssetInManutenzione`;
    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        userMansioni.forEach(element => {
          mansioni.push(element.IdRuolo);
        });
        loadOptions.filter = [];
        loadOptions.userData = mansioni;

        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      }
    });

    return dataSource;
  }

  getResponsabilePercentualiPersonale(mansioniArray: any): Observable<any> {
    const url = `${this.apiController}/getResponsabilePercentualiPersonale`;
    return this.api.post(url, mansioniArray);
  }

  getResponsabilePercentualiDocScaduti( userMansioni: any): Observable<any> {
    var mansioni: any[] = [];
    const url = `${this.apiController}/getResponsabilePercentualiDocScaduti`;
    // const dataSource = new CustomStore({

      // let loadOptions: any;
      // userMansioni.forEach(element => {
      //   mansioni.push(element.IdRuolo);
      // });
      // loadOptions = mansioni;

      return this.api.post(url, userMansioni);
    // });
    // return dataSource;
  }

}
