import { Component, OnDestroy, OnInit,Input, Inject  } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { filter, map, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { Router } from '@angular/router';
import { ROLES } from '../../../@auth/roles';
import { NbAuthService, NbAuthOAuth2JWTToken, NbAuthToken } from '@nebular/auth';
import { AppSettingsService } from '../../../@core/backend/common/services/appsettings.service';
import { NbAccessChecker } from '@nebular/security';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../@core/backend/common/services/users.service';
import { ModalSelectMansioneComponent } from '../../../@components/modal-select-mansione/modal-select-mansione.component';
import { GenericClassService } from '../../../@core/backend/common/services/genericClass.service';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{name}}</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-4">
          <img src="../../../../assets/images/{{logo}}"  class="img-thumbnail">
        </div>
        <div class="col-8">
          <p style="font-size: 12px;"><i class="far fa-caret-square-right"></i>&nbsp;Versione:&nbsp;{{version}}</p>
          <p style="font-size: 12px;"><i class="far fa-copyright"></i>&nbsp;GIS International 2021</p>
           </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Chiudi</button>
    </div>
  `
})

export class NgbdModalContent {

  @Input() name;
  @Input() version;
  @Input() logo;
  @Input() subtitle;

  constructor(public activeModal: NgbActiveModal) {}

}



@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'cmdb_operator',
      name: 'Operatore',
    },
    {
      value: 'cmdb_admin',
      name: 'Administrator',
    },
  ];

  currentTheme = 'cmdb_admin';

  userMenu = this.getMenuItems();
  isAdmin: boolean = false;
  isAdminPage: boolean = false;
  isCruscottiPage: boolean = false;
  currentRole: string = '';
  buttonLabel: string = '';
  buttonIcon: string = '';
  appName: string = '';
  appLogo: string = '';
  appVersion: any;
  appSubTitle: any;
  appHeaderName: any;
  confAccesso: any;
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userStore: UserStore,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private authService: NbAuthService,
    private appSettingsService: AppSettingsService,
    public accessChecker: NbAccessChecker,
    private modalService: NgbModal,
    private usersService: UsersService,
    private dialogService: NbDialogService,
    private service: GenericClassService,
  ) {
    var user:any = this.userStore.getUser();
    if(user && user.persona && user.persona.Id) {
      this.service.getDataSourceDevExp("AccessiPersonale",["UtenteInterno","=",user.persona.Id]).load().then((res:any)=>{
        if(res.data.length > 0) {
          this.confAccesso = res.data[0];
        }
      });
    }
  }

  openModal()
  {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = this.appName;
    modalRef.componentInstance.version = this.appVersion;
    modalRef.componentInstance.logo = this.appLogo;
    modalRef.componentInstance.subtitle = this.appSubTitle;
  }


  getMenuItems() {
    const userLink = this.user ? '/pages/users/current/' : '';
    return [
      { title: 'Profilo', link: userLink, queryParams: { profile: true } },
      { title: 'Seleziona mansione',code:"selectMansione"},
      { title: 'Esci', link: '/auth/logout' },
    ];
  }

  openModalSelectMansione() {
    this.dialogService.open(ModalSelectMansioneComponent, {
      context: {
        //toDo?
      },
    }).onClose.subscribe(res => {
      if(res){
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.menuService.onItemClick()
    .subscribe((item:any) => {
      if(item.item.code == "selectMansione") {
        this.openModalSelectMansione();
      }
    });
    this.appSettingsService.getSettings()
      .subscribe(settings => {
        console.log('Settings loaded.');
        this.appName = settings.applicationName;
        this.appVersion = settings.applicationVerision || '';
        this.appLogo = settings.applicationLogo || 'logo.png';
        this.appSubTitle = settings.applicationSubTitle || '';
        this.appHeaderName = settings.applicationHeaderName;

      });

    this.authService.getToken().subscribe((token: NbAuthOAuth2JWTToken) => {
      this.usersService.getCurrentUser().subscribe((user: any) => {
        console.log('STORE USER',user.data);
        this.user = user.data;
        this.isAdmin = false;
        const payload = token.getAccessTokenPayload();
        this.isAdmin = (payload.role === ROLES.ADMIN);
        this.currentRole = payload.role;
        this.userMenu = this.getMenuItems();
      });
    });
    this.isAdminPage = this.router.url.split('/')[1] == 'admin';
    this.isCruscottiPage =  this.router.url.split('/')[1] == 'cruscotti';

    this.currentTheme = this.themeService.currentTheme;


    // this.user = this.userStore.getUser();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {

    this.userStore.setSetting(themeName)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

      console.log(themeName);
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  nevigateToAdmin() {
    this.router.navigate(['admin/dashboard']);
  }
  nevigateToUser() {
    this.router.navigate(['pages/']);
  }
}
