import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';


@Component({
  selector: 'ngx-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent {
  public readMode = ReadMode.dataURL;

  public status: string;
  @Input() public picked: ReadFile;
  @Output() onFileRead: EventEmitter<any> = new EventEmitter();
  @Output() onStartRead: EventEmitter<any> = new EventEmitter();
  choose:boolean = false;
  @ViewChild('filePicker', { 'static': true })  filePicker: FilePickerDirective;

  onReadStart(fileCount: number) {
    this.status = `Reading ${fileCount} file(s)...`;
  }

  onFilePicked(file: ReadFile) {
    this.picked = file;
    this.choose = true;
    this.onStartRead.emit(true);
  }

  onReadEnd(fileCount: number) {
    this.status = `Read ${fileCount} file(s) on ${new Date().toLocaleTimeString()}.`;
    this.onFileRead.emit(this.picked);
  }
}
