import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-year-picker',
  template: ` <div>
    <div class="col-xs-2">
      <select
        class="form-control"
        required
        (change)="onYearChange($event.target.value)"
      >
        <option *ngFor="let y of years" [selected]="yy === y">{{ y }}</option>
      </select>
    </div>
  </div>`,
  styleUrls: ['./year-picker.component.scss'],
})
export class YearPickerComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  years: number[] = [];
  yy: number;
  constructor() {}

  ngOnInit() {
    this.getYear();
    this.onChange.emit(this.yy);
  }
  getYear() {
    const today = new Date();
    this.yy = today.getFullYear();
    for (let i = this.yy - 1; i <= this.yy; i++) {
      this.years.push(i);
    }
  }
  onYearChange(val) {
    this.onChange.emit(val);
  }
}
