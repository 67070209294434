import { Injectable } from '@angular/core';
import { HttpService } from '../api/http.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import { HttpClient } from '@angular/common/http';

import { AppSettings } from '../../../stores/appsettings';

const SETTINGS_LOCATION =
         'assets/appsettings.json';

@Injectable()
export class AppSettingsService {
    public url;
    constructor(private http: HttpService, private httpJson: HttpClient) {
    }

  getSettings(): Observable<any> {

    return this.http.get('settings/app')
      .map(response =>  {
        if (response.status != 'success') return {};
        return response.data;
      } )
      .catch(this.handleErrors);
  }

  setUrl(url) {
    this.url = url;
  }

  getUrl() {
    return this.url;
  }

  getMenu(): Observable<any> {

    return this.http.get('settings/menu')
      .map(response =>  {
        if (response.status != 'success') return {};
        return response.data;
      } )
      .catch(this.handleErrors);
  }

  getMenuDash(parent): Observable<any> {

    return this.http.get('settings/menudash' + (parent ? '/' + parent : ''))
      .map(response =>  {
        if (response.status != 'success') return {};
        return response.data;
      } )
      .catch(this.handleErrors);
  }

  getMenuDashAdmin(parent): Observable<any> {

    return this.http.get('settings/menudash_admin' + (parent ? '/' + parent : ''))
      .map(response =>  {
        if (response.status != 'success') return {};
        return response.data;
      } )
      .catch(this.handleErrors);
  }

  private handleErrors(error: any):
   Observable<AppSettings> {

    // Return default configuration values
    return Observable.of<AppSettings>(
      new AppSettings());
  }


}
