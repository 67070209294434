import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';

@Component({
  selector: 'ngx-modal-generic-datagrid',
  templateUrl: './modal-generic-datagrid.component.html',
  styleUrls: ['./modal-generic-datagrid.component.scss']
})
export class ModalGenericDatagridComponent implements OnInit {

  @Input('context.id') id: number;
  @Input('context.title') titleModal: string;
  @Input('context.showDataGridNominativiGesione') showDataGridNominativiGesione: boolean;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  dataSourceGrid: any;
  captionGruppo: string;
  clearSubscription: Subscription;

  constructor(
      private classService: GenericClassService,
      public ref: NbDialogRef<ModalGenericComponent>,
      public iconsLibrary: NbIconLibraries,
      public ngZone: NgZone
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadData();
  }

  cancel() {
    this.showDataGridNominativiGesione = false;
    if(this.clearSubscription != undefined) {
      this.clearSubscription.unsubscribe()
    }
    this.ref.close();
  }

  submit() {

  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + "px", 'height': this.height + "px" };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width*0.8) + "px", 'height': (this.height*0.8) + "px" };
  }

  loadData(): void {
    if(this.showDataGridNominativiGesione) {
      this.clearSubscription = this.classService.getNominativiGestione(this.id).subscribe((result) => {
        this.dataSourceGrid = result.data;
      });
    }
  }

  refresh() {

  }

}
