import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>
      <nb-layout-column  [ngStyle]="{'background':background}" >
        <ng-content select="router-outlet" style="height:100%"></ng-content>
      </nb-layout-column>
      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <nb-card  style="border: 0px;margin-bottom: 0px!important;padding-bottom: 0px!important;border-right: 1px solid #fff;"><nb-card-header class="cardHeaderThemeLayout">Navigazione</nb-card-header></nb-card>
        <ng-content select="div"></ng-content>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
    </nb-layout>
    <!-- <div><ngx-footer></ngx-footer></div> -->
  `,
})
export class OneColumnLayoutComponent {
  @Input() background: any;
}
