// DevExtreme Globalize integration
import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';

// DevExtreme messages (en messages already included)
import itMessages from 'devextreme/localization/messages/it.json';

// CLDR data
import deCldrData from 'devextreme-cldr-data/it.json';
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json';

import * as Globalize from 'globalize';

Globalize.load(
    deCldrData,
    supplementalCldrData
);

Globalize.loadMessages(itMessages);

Globalize.locale('it');