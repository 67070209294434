import { Component, OnInit, Input, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbIconLibraries, NbDialogService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterService } from '../../@core/backend/common/services/filter.service';
import { DxListComponent, DxFilterBuilderComponent } from 'devextreme-angular';

@Component({
  selector: 'ngx-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss'],
})

export class ModalFilterComponent implements OnInit {
  @ViewChild('dialogConferma', { static: false }) dialogConferma: TemplateRef<any>;
  @Input('context.className') className: any;
  @Input('context.fields') fields: any;

  @Input('context.filter') private _filter: any;

  public get filter(): any {
    return this._filter;
  }
  public set filter(value: any) {
    this.filterBuilderChanged = true;
    this._filter = value;
  }

  @Input('context.isAdmin') isAdmin: boolean; // gestisce creazione filtro condiviso da parte admin
  filterList: any;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;
  form: FormGroup;
  formHeader: FormGroup;
  messageError: string = '';
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  @ViewChild(DxFilterBuilderComponent, { static: false }) dxfilter: DxFilterBuilderComponent;
  selectedItems: any;
  filterBuilderChanged: boolean = false;

  constructor(
    protected ref: NbDialogRef<ModalFilterComponent>,
    iconsLibrary: NbIconLibraries,
    private ngZone: NgZone,
    private filterService: FilterService,
    private toasterService: NbToastrService,
    private dialogService: NbDialogService,

    private fb: FormBuilder,
  ) {

    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {

    this.titleModal = "Gestione filtri > " + this.className.label;
    this.filterList = [];
    this.loadFilter();

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = () => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };

  }
  loadFilter(selectList = null) {
    this.filterService.getAllForClass(this.className.className).subscribe((resp) => {
      this.filterList = resp.data;
      if (selectList) {
        this.selectedItems = resp.data.filter(x => x.Id == selectList.id);
        setTimeout(() => {
          this.list.instance.option("selectedItems", this.selectedItems);
        }, 1);

      }

    });
  }

  applica() {
    this.ref.close(this.filter);
  }
  cancel() {
    this.ref.close();
  }
  salva() {
    // filtro selezionato;
    let filtro = this.selectedItems[0];
    filtro.Filter = this.filter;
    this.filterService.put(this.className.className, filtro).subscribe((res) => {
      if (res.status == "success") {
        this.toasterService.success("Salvataggio effettuato.")
      } else {
        this.toasterService.danger('', `ERRORE: ${res.status} - ${res.data}`);
      }
    });
  }

  salvaConNome() {
    this.formHeader = this.fb.group({
      nome: this.fb.control(null, [Validators.required]),
      descrizione: this.fb.control(null)
    });
    this.dialogService.open(this.dialogConferma).onClose.subscribe((res) => {
      if (res) {
        this.filterService.post(this.className.className,
          // tslint:disable-next-line: max-line-length
          { 'code': this.formHeader.value.nome, 'description': this.formHeader.value.descrizione, 'filter': JSON.stringify(this.filter) }).subscribe(res => {
            if (res.status == "success") {
              this.toasterService.success('', `Il filtro è stato creato!`);
              this.loadFilter(res.data)
            }
          });
      }
    });
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + 'px', 'height': this.height + 'px' };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + "px", 'height': (this.height * 0.8) + "px" };
  }

  onSelectionChanged(e) {
    //this.selectedItems = this.list.instance.option("selectedItems");
    if (e.addedItems && e.addedItems.length > 0) {
      this.selectedItems = e.addedItems;
      this.filter = JSON.parse(this.selectedItems[0].Filter)

      let removedItems = e.removedItems;
    }

  }
  onItemDeleting(e) {
    console.log(e);

    let element=e.itemData;
    this.filterService.delete(this.className.className,element.Id).subscribe(
      (res)=>{

      },
      (error)=>{
        e.cancel=true;
      }
    )
    
  }
}
