import { NbAccessChecker } from '@nebular/security';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { deepExtend } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailRequest } from '../../@core/interfaces/common/emailRequest';
import { EmailService } from '../../@core/backend/common/services/email.service';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'jquery';
import moment from 'moment';

@Component({
  selector: 'ngx-form-class',
  templateUrl: './form-class.component.html',
  styleUrls: ['./form-class.component.scss'],
})

export class FormClassComponent implements OnInit, OnChanges {

  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = null;
  @Input() mode: string = 'VIEW';
  @Input() className: string = '';
  @Input() cardId: number = 0;
  @Input() modelData: any = null;

  @Input('reference') reference: string;
  @Input('referenceId') referenceId: number;
  @Input('referenceModel') referenceModel: any;


  @Output() cardChange = new EventEmitter<any>();
  @Output() endLoading = new EventEmitter<any>();

  createform = new FormGroup({});
  referenceFields: any;
  classData: any;

  constructor(
    private classService: GenericClassService,
    private toasterService: NbToastrService,
    private spinner: NgxSpinnerService,
    private serviceEmail: EmailService,
    private translate: TranslateService,
    private accessChecker: NbAccessChecker,

  ) {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.className = this.className ? this.className.replace(/\"/gi, '') : null;
    this.refresh();
  }

  ngOnInit() {
    // NON USARE PARTE DOPO  ngOnChanges
  }

  loadData(): Observable<any[]> {
    const request: any[] = [];
    request.push(this.classService.getClassForm(this.className));


    if (this.mode != 'CREATE' && this.cardId) {
      request.push(this.classService.getDettagliScheda(this.className, this.cardId));
    }

    return forkJoin(request);
  }

  refresh() {
    this.options = {  formState: {selectOptionsData: [], disabled: this.mode == 'VIEW' } };
    this.accessChecker.isGranted('view', 'class_' + this.className).subscribe((grantedView) => {
      if (!grantedView) {
        this.options.formState.disabled = true;
      }
    });
    if (this.modelData) {
      this.model = this.modelData;
      console.log('NO LOADING MODELLO PRESENTE');
      return;
    }
    if (this.className) {
      this.spinner.show('cardSpinner');
      this.loadData().subscribe(([fields, model]) => {
        console.log('LOADED DATA **************');
        if (fields) {
          this.preParsing(this.className, fields.data.form);
          this.fields = fields.data.form;
          this.fields.push({
            'type': 'input',
            'key': 'className',
            'defaultValue': this.className,
            'hide': true,
            'templateOptions': {
              'label': 'Classe',
            },
          });
          this.referenceFields = fields.data.referenceFields;
          this.classData = fields.data.classData;

        }
        // this.model=null;
        if (this.referenceId && this.reference) {


          fields.data.referenceFields.forEach(element => {
            const fieldName = element.code;
            if (element.reference == this.reference) {

              this.model[fieldName] = this.referenceId;
              this.options.formState['disable' + fieldName] = true;

              if (this.referenceModel) {

                if (!this.model['Reference']) {
                  this.model['Reference'] = {};
                }
                this.model['Reference'][fieldName] = this.referenceModel;
              }
            }
          });
        }
        console.log('MODEL CARD=', this.model);
        // Controllo permesso
        fields.data.referenceFields.forEach((element) => {
          const fieldName = element.code;
          this.accessChecker.isGranted('view', 'class_' + element.classReference).subscribe((grantedView) => {
            if (!grantedView) {
              this.options.formState['hidden' + fieldName] = true;
            } else {
              this.accessChecker.isGranted('edit', 'class_' + element.classReference).subscribe((granted) => {
                if (!granted) this.options.formState['disable' + fieldName] = true;
              });
            }

          });
        });

        if (model) {
          this.model = model.data;
        }
        this.endLoading.emit({ classData: this.classData, model: this.model, referenceFields: this.referenceFields });

        this.spinner.hide('cardSpinner');
      });
    }

  }
  preParsing(className: string, form: any) {
    if (isArray(form)) {
      form.forEach(element => {
        this.preParsing(className, element);
      });
    } else {
      if (form.fieldGroup && form.fieldGroup.length > 0) {
        form.fieldGroup.forEach(element => {
          this.preParsing(className, element);
        });
      } else {
        form.templateOptions.label = this.translate.instant(className + '.' + form.key) || form.templateOptions.label;
        form.templateOptions.placeholder = ('inserire ' + form.templateOptions.label ).toLowerCase();
      }
    }

  }
  addSummary(data) {
    if (!this.model['Summary']) this.model['Summary'] = {};
    this.model.Summary[data.name] = data.value;
  }
  save(data): Observable<any> {

    return new Observable((observer) => {


      const myModel = deepExtend({}, this.model, (data || {}));

      delete myModel.Reference;
      delete myModel.Lookup;
      delete myModel.Summary;
      this.classService.updateDettagliScheda(this.className, myModel).subscribe((response) => {
        if (response.status != 'success') {
          this.toasterService.danger('', `ERRORE: ${response.status} - ${response.data}`);
        } else {
          this.model.Id = response.data.Id;
          if (this.mode == 'CREATE') {

            this.mode = 'EDIT';
            this.cardChange.emit(this.model);
          }

          observer.next(this.model);
        }
      }, (error) => {

        this.toasterService.danger('', `ERRORE: ${error.error.status} - ${error.error.data}`);
        observer.next(false);
      });
    });


  }

  isValid(): boolean {
    return this.createform.valid;
  }
  hasFields(): boolean {
    return this.fields.length > 0;
  }
  changeModel(event: { key: string, value: any }) {
    this.cardChange.emit(this.model);
  }
}
