import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../api/http.service';
import moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  private readonly apiController: string = 'class';
  constructor(private api: HttpService) { }

  addClassAllegato(objId: number, idFile: number, code: string, description: string, categoria: string, file: any, dataRiferimento: any, dataScadenza: any,requiredSigned=false): Observable<any> {

    const formData: any = new FormData();
    if (requiredSigned) {
      formData.append('requireSigned', true);
    }
    formData.append('idFile', idFile ? idFile : 0);
    formData.append('code', code);
    formData.append('description', description);
    formData.append('categoria', categoria);
    formData.append('dataRiferimento', moment(dataRiferimento ? dataRiferimento : undefined).format('YYYY-MM-DD'));
    formData.append('dataScadenza', moment(dataScadenza ? dataScadenza : undefined).format('YYYY-MM-DD'));
    console.log(dataScadenza);
    if (file ) {
      formData.append('fileName', file.Name ? file.Name : file.name);
      formData.append('fileSize', file.Size ? file.Size : file.size);
      formData.append('file64', file.FilePicked.content);
      return this.api.post(`${this.apiController}/uploadAllegato/${objId}`, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        catchError(this.errorMgmt),
      );

    }


  }

  checkSignature(file: any,): Observable<any> {
    const formData: any = new FormData();
    if (file ) {
      formData.append('fileName', file.Name ? file.Name : file.name);
      formData.append('fileSize', file.Size ? file.Size : file.size);
      formData.append('file64', file.FilePicked.content);
      return this.api.post(`${this.apiController}/checkSignature`, formData);
    }
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
