import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericClassService } from '../../../../../@core/backend/common/services/genericClass.service';

@Component({
  selector: 'ngx-widget-statistiche-situazione',
  templateUrl: './widget-statistiche-situazione.component.html',
  styleUrls: ['./widget-statistiche-situazione.component.scss']
})
export class WidgetStatisticheSituazioneComponent implements OnInit {
  showGrid=false;
  filter:any = [];
  dataSource:any;
  store:any;
  situazione:any = [];
  categorie:any = [];
  constructor(private classService:GenericClassService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.classService.getCard("Segnalazioni_Settori").subscribe((res:any)=>{
      res.data.list.forEach(element => {
        this.categorie.push({
          categoria: element.Description,
          gruppo: element.Gruppo ? element.Gruppo : "Altro",
          code: element.Code,
          complete:0,
          rigettate:0,
          lavorazione:0,
          inserite:0,
          accettate:0

        });
      });
      console.log(this.categorie)
      this.store=this.classService.getDataSourceDevExp("Segnalazioni",["Status","=","A"],false,false,false);
      this.store.on("loaded",this.loaded.bind(this));
      this.store.load();
    });
  }

  totale(rowData) {
    return rowData.complete + rowData.rigettate + rowData.lavorazione
  }

  loaded(res)
  {
    res.data.forEach(element => {
      if (element.Conclusa) {
        element.stato="Risolta"
        element.idStato = 1
      } else {
        if (element.Valid===null) {
          element.stato="Inserita" 
          element.idStato = 2;  
        } else if (element.TipoEsecutore) {
          element.stato= "In lavorazione"
          element.idStato = 3;  
        } else if (!element.TipoEsecutore){
          element.stato= element.Valid?"Accettata":"Non di competenza"
          element.idStato = element.Valid?4:5;
        }
      }
    });
    this.check(res.data);
  }

  check(data)
  {
    data.forEach(segnalazione => {
      this.categorie.forEach(categoria => {
        if(segnalazione._Categoria_Description == categoria.categoria && segnalazione._Categoria_Gruppo == categoria.gruppo){
          switch(segnalazione.idStato){
            case 1:
              categoria.complete++;
              break;
            case 3:
              categoria.lavorazione++;
              break;
            case 4:
              categoria.accettate++;
            case 5:
                categoria.rigettate++;
                break;
            default:
              categoria.inserite++;
              break;
          }
        }
      });
    });
    this.showGrid=true;
    this.spinner.hide();
  }
}
