import { ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject, fromEvent, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngxAppDelayedInput]',
})
export class DelayedInputDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() delayTime = 500;
  @Output() delayedInput = new EventEmitter<Event>();
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
  }

  ngOnInit() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        debounce(() => timer(this.delayTime)),
        distinctUntilChanged(
          null,
          (event: Event) => (event.target as HTMLInputElement).value,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => { console.log(e); this.delayedInput.emit(e) });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}

@Component({
  selector: 'ngx-formly-field-map',
  template: `
  <div class="form-group" [class.has-error]="showError">
    <div *ngIf="to.label && to.hideLabel !== true" class="ui-widget">
      <label [for]="id">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
    </div>
    <input class="form-control py-2" type="text" id="{{key}}" [formControl]="formControl"  [formlyAttributes]="field" >

    <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div><br/>
    <div style="height:500px">
    <ngx-map-field  [mapPoint]="mapPoint" (locationSelected)="setAddress($event)"></ngx-map-field>
    </div>

  </div>

   `,
})

export class FormlyFieldMap extends FieldType implements OnInit{
  mapPoint: { name: string; latitude: number; longitude: number; epsg: string; address: any; };
  constructor(protected cd: ChangeDetectorRef,) {
    super();

  }

  ngOnInit() {
    console.log(this.model);
    console.log(this.formControl);
    this.mapPoint = {
      name:this.formControl.value,
      latitude : parseFloat(this.model[this.to.mapfield_lat].toString()),
      longitude : parseFloat(this.model[this.to.mapfield_lon].toString()),
      epsg: "4326",
      address:null
    }
  }

  setAddress(e) {
    this.form.get(this.field.key.toString()).setValue(e.name)

    this.form.get(this.to.mapfield_lat) && this.form.get(this.to.mapfield_lat).setValue(parseFloat(e.latitude));
    this.form.get(this.to.mapfield_lon) && this.form.get(this.to.mapfield_lon).setValue(parseFloat(e.longitude));
    this.form.get("epsg") && this.form.get("epsg").setValue(e.epsg);
    if (e.address) {
      Object.keys(e.address).forEach((key) => {
        this.form.get(key) && this.form.get(key).setValue(e.address[key]);
      });
    }

    this.cd.markForCheck();
  }

}
