import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-checkbox',
  template: `
      <nb-checkbox (checkedChange)="changeBoolean($event)" [checked]="this.checked" ></nb-checkbox>
  `
})
export class SmartTableCheckboxComponent implements ViewCell, OnInit {
 
  @Input() value: any;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  checked: boolean;
  originalCheched:boolean;

  constructor() { }

  ngOnInit() {
    this.checked = this.value||false;
    this.originalCheched = this.value||false;
  }

  changeBoolean(event) {
    this.checked = !this.checked;
    this.value = this.checked;
    if (this.rowData) {
      this.rowData.selected = this.value;
      this.rowData.isChangedSelected=this.value!=this.originalCheched;
      this.save.emit(this.rowData);
    }
  }

}