import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { DxTreeListModule } from 'devextreme-angular';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
@Component({
  selector: 'ngx-relations-list',
  templateUrl: './relations-list.component.html',
  styleUrls: ['./relations-list.component.scss']
})
export class RelationsListComponent implements OnInit {
  dataSource: any;
  idCard: number;
  classCard: string;
  selectedRow: any;
  cache: any[] = [];
  focusedRowKey: string;
  constructor(http: HttpClient, classService: GenericClassService) {
    this.idCard = 684573;
    this.classCard = "ProduzioneLotti"
    this.dataSource = {

      load: (loadOptions) => {
        console.log(this.selectedRow)
        let nameClass;
        let idClass;
        let idClassSearch;
        if (loadOptions.parentIds && loadOptions.parentIds.length > 0 && loadOptions.parentIds[0] != "0") {
          if (!this.selectedRow) return;
          nameClass = this.selectedRow.objClass;
          idClass = loadOptions.parentIds[0];
          idClassSearch = idClass.split("_");
          idClassSearch=idClassSearch[0];
        } else {
          nameClass = this.classCard
          idClass =  idClassSearch = this.idCard
        }
        console.log(loadOptions);
        return classService.getDomains(nameClass, idClassSearch).toPromise().then((response) => {
          console.log(nameClass, idClass);
          let key =  '_' + Math.random().toString(36).substr(2, 9);
          let returnList = [];
          response.data.forEach(relation => {
            let relCount = 0;

            relCount = relation.relationsData.length;
            // aggiunge relazione
            if (relCount > 0) {
              let tmp = {
                'Id': relation.name + idClass+key,
                'Description': relation.viewTitle,
                'Type': 'Relazione',
                'hasItems': relCount > 0,
                'parentId': idClass == this.idCard ? "0" : idClass
              }
              if (!this.focusedRowKey) this.focusedRowKey=relation.name + idClass+key;
              returnList.push(tmp);
              

              // aggiunge data 
              relation.relationsData.forEach(item => {

                let tmp = {
                  'Id': item.ObjValue.Id+key,
                  'Description': item.ObjValue.Description,
                  'Codice':item.ObjValue.Code,
                  'Type': item.ObjClass,
                  'hasItems': true,
                  'parentId': relation.name + idClass+key,
                  'objClass': item.ObjClass
                }
         
                returnList.push(tmp);


              });
            }

          });

          return returnList;
        });
      }
    }
  }

  onFocusedRowChanged(e) {
    this.selectedRow = e && e.data

  }
  ngOnInit() {
  }

}
