import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../api/http.service';

@Injectable()
export class FilterService {
    private readonly apiController: string = 'class';
    private readonly paramUrl: string[] = ['filters','filtersSystem'];


    constructor(private api: HttpService) { }

    post(className: string, item: any): Observable<any> {
        return this.api.post(`${this.apiController}/${className}/${this.paramUrl[0]}`, item);
    }

    postFromAdmin(item: any): Observable<any> {
        return this.api.post(`${this.paramUrl[0]}`, item);
    }

    getForAdmin(id: number): Observable<any> {
        return this.api.get(`${this.paramUrl[0]}/${id}`);
    }

    putFromAdmin(item: any): Observable<any> {
        return this.api.put(`${this.paramUrl[0]}/${item.id}`, item);
    }

    getAllForClass(className: string): Observable<any> {
        return this.api.get(`${this.apiController}/${className}/${this.paramUrl[0]}`);
    }
    getAllForClassSystem(className: string,filterCode:string): Observable<any> {
        return this.api.get(`${this.apiController}/${className}/${this.paramUrl[1]}/${filterCode}`);
    }

    getAll(): Observable<any> {
        return this.api.get(`${this.paramUrl[0]}`);
    }

    delete(className: string, id: Number): Observable<any> {
        return this.api.delete(`${this.apiController}/${className}/${this.paramUrl[0]}/${id}`);
    }

    put(className: string, item: any): Observable<any> {
        let data={
            Id:item.Id,
            Code:item.Code,
            Filter:JSON.stringify(item.Filter),
            ClassId:item.ClassId
        }
        return this.api.put(`${this.apiController}/${className}/${this.paramUrl[0]}/${item.Id}`, data);
    }

}
