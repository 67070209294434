/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'ngx-modal-generic',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
})

export class ModalImageComponent implements OnInit {

  @Input('context.id') id: number;
  @Input('context.title') titleModal: string;
  @Input('context.src') src:any;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;


  constructor(
     public ref: NbDialogRef<ModalImageComponent>,
     public iconsLibrary: NbIconLibraries,
     public ngZone: NgZone,
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadData();
  }

  cancel() {
    this.ref.close();
  }

  submit() {

  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + 'px', 'height': this.height + 'px' };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + 'px', 'height': (this.height * 0.8) + 'px' };
  }

  loadData(): void {
  }


  refresh() {

  }

}
