import { ModalDetailComponent } from './../modal-detail/modal-detail.component';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxPopupComponent, DxTreeListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { GenericClassService } from '../../../../@core/backend/common/services/genericClass.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ModalConfirmComponent } from '../../../../@components/modal-confirm/modal-confirm.component';
import { ModalFilterComponent } from '../../../../@components/modal-filter/modal-filter.component';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  @ViewChild(DxTreeListComponent, { static: false })
  treeGrid: DxTreeListComponent;

  @ViewChild('dialogType', { static: false }) private dialog: TemplateRef<any>;

  spinner: boolean = true;
  title: string;
  currentClass: any;
  filter: any = null;
  baseFilter: any = null;
  store: CustomStore;
  firstColumnName: any;
  itemsMenuClassAdd: any[];
  sortingFields: any[];
  popupChiusuraVisible: boolean;
  maximizeButtonOptions: any;
  minimizeButtonOptions: any;
  isPopupMaximize: boolean;
  @ViewChild('popup1', { static: false }) popup: DxPopupComponent;
  settings: any;
  canEdit: boolean;
  mode: string;
  isAsset: boolean;
  hasGeometry: any;
  mapVisible: any;
  mapSearchId: any;
  mapEditId: any;
  selectCard: any;
  storeArray: any;
  isAzienda: boolean;
  segnalazioneList:any;



  constructor(
    private classService: GenericClassService,private dialogService:NbDialogService, private toasterService: NbToastrService,) { }

  ngOnInit() {
    var store=this.classService.getDataSourceDevExp("Segnalazioni",[]);
    store.on("loaded",this.loading.bind(this));
    store.load();

  }

  loading(res){
    this.spinner=false;
    res.data.forEach(element => {
      if (element.Conclusa) {
        element.stato="Risolta"
        element.icona="fas fa-check"
        element.color="#40C254"
      } else {
        if (element.Valid===null) {
          element.stato="Inserita"
          element.icona="fas fa-folder"
          element.color="#40C254"
        }else if (element.Lavorazione) {
          element.stato= "In lavorazione"
          element.icona="fa fa-cogs"
          element.color="#0f9"
        } else  {
          element.stato= element.Valid?"Accettata":"Non di competenza"
          element.icona=element.Valid? "fas fa-thumbs-up":"fas fa-ban"
          element.color=element.Valid?"#40C254":"#D21313"
        }
      }
    });
    this.segnalazioneList=res.data;
  }



  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === 'searchPanel') {
        item.location = 'before';
      }
    });
    e.toolbarOptions.items.unshift(

      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "filter",
          hint: "crea filtro",
          onClick: this.addFilterDataGrid.bind(this),
        },
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "clear",
          hint: "azzera filtro",
          onClick: this.clearFilterDataGrid.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          hint: "aggiorna",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  onRowPrepared(row) {
    if(row.rowType == 'data' && row.data.ChiusuraForzata) {
      row.rowElement.style.backgroundColor = '#ffffb4';
      row.rowElement.style.color = 'black';
    }
  }

  refreshDataGrid() {
    this.ngOnInit();
  }
  repaintDataGrid() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    instance.repaint();
  }
  clearFilterDataGrid() {
    this.filter = [];
  }

  actionDelete(e) {
    this.deleteRecord(e.row.data);
  }

  addFilterDataGrid() {
    this.apriModalFilter();
  }

  exportGrid() {
    const doc = new jsPDF();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid.instance,
    }).then(() => {
      doc.save("TabellaEsportata.pdf");
    });
  }

  apriModalFilter() {
    // crea fields
    const fields: Array<any> = [];

    // tslint:disable-next-line: forin
    for (const key in this.settings.columns) {
      const element = this.settings.columns[key];
      fields.push({
        caption: element.title,
        dataField: key,
        dataType: element.type,
      });
    }

    this.dialogService
      .open(ModalFilterComponent, {
        context: {
          fields: fields,
          className: this.currentClass,
          filter: this.filter,
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          // this.dataGrid.instance.filter(res);
          this.filter = res;
        }
      });
  }

  deleteRecord(event) {
    this.dialogService
      .open(ModalConfirmComponent, {
        context: { titleModal: 'Confermi la cancellazione della segnalazione?' },
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          this.spinner = true;
          this.classService
            .deleteScheda(
              event.NameClass ? event.NameClass : event.IdClass,
              event.Id,
            )
            .subscribe(
              (res) => {
                if (res.status === 'success') {
                  this.toasterService.success(
                    '',
                    `L'oggetto è stato eliminato con successo.`,
                  );
                  this.refreshDataGrid();
                } else {
                  this.toasterService.danger('', `ERRORE!`);
                }
                this.spinner = false;
              },
              (error) => {
                this.toasterService.danger(
                  '',
                  `ERRORE! ${error.status}  ${error.statusText}`,
                );
                this.spinner = false;
              },
            );
        }
      });


  }

modalOpen(data)
{
  console.log(data);
  this.dialogService
  .open(ModalDetailComponent, {
    context: {
      id:data.data.Id
    },
  })
  .onClose.subscribe((res) => {
    if(res) this.refreshDataGrid();
  });
}

  ngOnDestroy() { }
  // Preserve original property order
  originalOrder = (): number => {
    return 0;
  }





}
