declare var $: any;
declare var configFile: string;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const urlPermissions = window['__env']['apiUrl'] + '/settings/permissions';


$('#nb-global-spinner').addClass('spinner2');
$.ajax({
  url : urlPermissions,
  tryCount : 0,
  retryLimit : 50,
  success : function(res) {
    const data = res.data;

    Object.keys(data).forEach(ruoloEnv => {
      if (data[ruoloEnv]) {

        if (! environment.authSettings[ruoloEnv]) {
          environment.authSettings[ruoloEnv] = {
            'parent': 'base',
          };
        }

        if (data[ruoloEnv].view) {
          environment.authSettings[ruoloEnv].view = Object.assign( data[ruoloEnv].view, environment.authSettings[ruoloEnv].view);
        }
        if (data[ruoloEnv].edit) {
          environment.authSettings[ruoloEnv].edit = Object.assign( data[ruoloEnv].edit, environment.authSettings[ruoloEnv].edit);
        }
        if (data[ruoloEnv].other) {
          environment.authSettings[ruoloEnv].other = Object.assign( data[ruoloEnv].other, environment.authSettings[ruoloEnv].other);
        }
      }


    });


    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  },
  error : function(xhr, textStatus, errorThrown ) {
    this.tryCount++;
    if (this.tryCount <= this.retryLimit) {

        // try again
        $.ajax(this);
        return;
    }
    $('#nb-global-spinner').addClass('spinner3');
    return;
  },
});
