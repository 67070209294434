import { UsersService } from './../../@core/backend/common/services/users.service';
import { FileUploadService } from './../../@core/backend/common/services/file-upload.service';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import { RelationsService } from '../../@core/backend/common/services/relations.service';
import { forkJoin } from 'rxjs';
import  moment from 'moment';
import { AuthService } from '../../@core/backend/common/services/auth.services';
import { NbAuthService } from '@nebular/auth';
declare var bootbox: any;

@Component({
  selector: 'ngx-modal-checklist-component',
  templateUrl: './modal-checklist-component.component.html',
  styleUrls: ['./modal-checklist-component.component.scss'],
})
export class ModalChecklistComponentComponent
  extends ModalGenericComponent
  implements OnInit {
  verificaId: number;
  schedaId: number;
  private _mode: string = 'view';
  public get mode(): string {
    return this._mode;
  }
  public set mode(value: string) {
    this._mode = value;
    this.isAnteprima = (value == 'view');
    if (value == 'create') this.maximize = true;
  }



  isAnteprima: boolean = false;
  isPresaIncarico:boolean=false;
  id: number;
  @ViewChild('checklist', { static: true }) private checklist: any;
  currentUser: any;

  ngOnInit() {

    super.ngOnInit();

    this.serviceUser.getCurrentUser().subscribe((currentUser) => {


      this.currentUser=currentUser;
    });


  }
  constructor(
    ref: NbDialogRef<ModalGenericComponent>,
    iconsLibrary: NbIconLibraries,
    ngZone: NgZone,
    protected service: AuthService,
    private serviceRelation: RelationsService,
    private fileUploadService: FileUploadService,
    private serviceUser:UsersService
  ) {
    super(ref, iconsLibrary, ngZone);
  }
  submitBozza() {

    const data = { ...this.checklist.checklistForm };
    console.log(data);
    const allegati = this.checklist.allegati;
    data.bozza=true;
    if(this.checklist.config.verifica.Code != "06-Chiusura NC" && this.currentUser.data.pwdVerify) {

      bootbox.prompt({
        title: "Salva bozza",
        message: "<p>Inserisci la tua password :</p>",
        inputType: "password",
        locale: "it",
        callback: (result) => {
          if (result) {
            const body = { email: this.currentUser.data.email, password:result,simple:true };
            this.service.login(body).subscribe((res:any)=>{
              if(res.token) {
                this.do_submit(data,allegati,true);
              } else {
                bootbox.alert("La password inserita non è corretta");
              }
            },(error)=>{
              bootbox.alert("La password inserita non è corretta");
            });
          }
        }
      });

    } else this.do_submit(data,allegati,true);
  }
  submit() {
    if(this.checklist.config.verifica.Code == "06-Chiusura NC")
    {
      this.serviceUser.getOTP().subscribe((res: any) => {
        if(res.status=='success')
        {
        bootbox.prompt({
          title: "Salva e firma",
          message: "<p>Inserisci il codice OTP :</p>",
          inputType: "text",
          locale: "it",
          callback: (result) => {
            if (result) {
              const body = { otp: result };
              this.serviceUser.checkOTP(body).subscribe((res: any) => {
                if (res.data) {
                  const data = { ...this.checklist.checklistForm };
                  const allegati = this.checklist.allegati;
                  this.do_submit(data, allegati);
                } else {
                  bootbox.alert("Il codice OTP inserito non è corretto");
                }
              },
              (error)=>{
                bootbox.alert("Errore procedura di verifica");
              }
              );
            }
          },
        });
       } else {
        bootbox.alert("Errore nella generazione del codice OTP");
       }
      });
    } else {
      if(this.currentUser.data.pwdVerify) {
        bootbox.prompt({
          title: "Salva e firma",
          message: "<p>Inserisci la tua password per salvare definitivamente la scheda di verifica</p>",
          inputType: "password",
          locale: "it",
          callback: (result) => {
            if (result) {
              const body = { email: this.currentUser.data.email, password:result,simple:true  };
              this.service.login(body).subscribe((res:any)=>{
                if(res.token) {
                  const data = { ...this.checklist.checklistForm };
                  const allegati = this.checklist.allegati;
                  this.do_submit(data,allegati);
                } else {
                  bootbox.alert("La password inserita non è corretta");
                }
              },(error)=>{
                bootbox.alert("La password inserita non è corretta");
              });
            }
          }
        });
      } else {
        bootbox.confirm(
          'Confermi il salvataggio definitivo della scheda di verifica?',
          (r: any) => {
            if (r == true) {
              const data = { ...this.checklist.checklistForm };
              const allegati = this.checklist.allegati;
              this.do_submit(data, allegati);
            }
          },
        );
      }
    }
  }
  presaIncarico(){
    bootbox.confirm(
      'Confermi la presa in carico della scheda di verifica?',
      (r: any) => {
        if (r == true) {
          const data = { ...this.checklist.checklistForm };
          const allegati = this.checklist.allegati;
          console.log(data);
          data.presaIncarico={
            'date':moment().format('DD/MM/YYYY'),
            'user':this.currentUser
          }
          this.do_submit(data, allegati);
        }
      },
    );
  }

  public do_submit(data: any, allegati: any,isBozza:boolean=false) {
    this.loading = true;

    data.Id = this.id || null;
    data.verificaId = this.verificaId || null;
    data.scheda = data.config.scheda;
    console.log(data);
    console.log('START SALVATAGGIO CHECKLIST....');

    this.serviceRelation.updateResponseChecklist(data).subscribe((res) => {
      const saveAllegati = [];
      if (isBozza) {
        res.status="bozza";
        this.checklist.sendLog("Salvataggio bozza");
      } else if(data.presaIncarico) {
        this.checklist.sendLog("Presa in carico");
      } else {
        this.checklist.sendLog("Salvataggio definitivo");
      }
      if (allegati && allegati.length > 0) {
        allegati.forEach((allegato) => {
          console.log('START UPLOAD ALLEGATO....');
          if(allegato.FilePicked && allegato.FilePicked.content)
          {
          saveAllegati.push(
            this.fileUploadService
              .addClassAllegato(
                data.scheda.Id,
                0,
                'FREE',
                'allegato alla verifica',
                'FREE',
                allegato, null, null,
              ),
          );
          }
        });

        forkJoin(saveAllegati).subscribe(() => {
          console.log('START UPLOAD ALLEGATI');
          this.loading = false;
          this.ref.close(res);
        });
      } else {
        this.ref.close(res);
      }
    });
  }
}
