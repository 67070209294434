import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpService } from '../api/http.service';
import moment from 'moment';
import Utils from '../../../utils/utils';

@Injectable()
export class GenericClassService {

  convertArrayToObject = (array, key, value) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: value ? item[value] : item,
      };
    }, initialValue);
  }

  lookupPesi()
  {
    return this.api.get("lookup/values/Peso");
  }

  savePermission(roleCode,model) {
    return this.api.post(`class/${roleCode}/savePermissions`,model)
  }
  saveSpecialPermission(roleCode,model) {
    return this.api.post(`class/${roleCode}/saveSpecialPermissions`,model)
  }

  getRoles() {
    return this.api.get("roles");
  }

  getClassi(roleCode) {
    return this.api.get( `class/${roleCode}/permissions`);
  }

  getSpecialPermission(roleCode) {
    return this.api.get( `class/${roleCode}/specialPermissions`)
  }

  processDomande(data: any) {
    data.forEach((element) => {
      if (element._Peso_Code) {
        element.Peso = parseInt(element._Peso_Code, 10);
      }
      const risposteNew = {};
      if (element.risposte) {
        element.risposte.forEach((risposta) => {


          if (risposta.Chiave.startsWith('valori_')) {
            let x = null;
            if (risposta.Valore.startsWith('{')) {
              x = JSON.parse(risposta.Valore);
              Object.keys(x).forEach((itm) => {
                risposteNew[risposta.Chiave.replace('valori_', '') + '_' + itm] =
                  x[itm];
              });
            } else {
              // NON E' UN OBJECT
              risposteNew[
                risposta.Chiave.replace('valori_', '') + '_' + risposta.Valore
              ] = risposta.Valore;
              risposteNew[risposta.Chiave.replace('valori_', '')] =
                risposta.Valore;
            }
          }
        });
      }
      element.risposte = risposteNew;
    });
    // domande -> tree
    const treeRoot = Utils.unflatten(data);

    const preorder = Utils.traverseDFS(treeRoot, 'pre');
    preorder.shift();
    data = preorder;
    // Array contiene minimo 10 domande
    const c = data.length;
    const numDomande = 15;
    if (c < numDomande) {
      for (let index = 0; index < numDomande - c; index++) {
        data.push({});
      }
    }
    return data;
  }

  calculateExpression(f, o) {
    'use strict';
    if (!f) return null;
    const copy = [...f];
    const m = o;
    copy.forEach((element, index) => {
      try {
        if (Array.isArray(element)) {
          copy[index] = this.calculateExpression(element, m);
        } else {
          copy[index] = eval(element);
        }
      } catch (error) {}
    });

    return copy;
  }
  duplicate(nameClass: string, idCard: any, data: any): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.post(
      `${this.apiController}/${nameClass}/cardDuplicate/${idCard}`,
      data,
    );
  }

  getDataSourceDevExpFromView(
    nameClass: string,
    nameView: string,
    baseFilter: any,
    orderBy: any = null,
  ): CustomStore {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiController}/${nameClass}/cardsExp`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          if (loadOptions.filter) {
            loadOptions.filter = [baseFilter, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilter;
          }
        }
        if (orderBy) {
          loadOptions.sort = orderBy;
        }
        return this.api
          .post(url + (nameView ? '?viewName=' + nameView : ''), loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }
  getDataSourceDevExpUbicazioniMappe(
    idMappa: number,
  ): CustomStore {

    const url = `${this.apiController}/getUbicazioniMappe/${idMappa}`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {

        return this.api
          .get(url)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }
  getDataSourceForecastDocumenti(
    baseFilter: any,
    orderBy: any = null,
  ): CustomStore {
    const url = `${this.apiController}/getForecastAziendeDocumenti`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          if (loadOptions.filter) {
            loadOptions.filter = [baseFilter, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilter;
          }
        }
        if (orderBy) {
          loadOptions.sort = orderBy;
        }
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getDataSourceForecastVerifiche(
    baseFilter: any,
    orderBy: any = null,
  ): CustomStore {
    const url = `${this.apiController}/getForecastVerifiche`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          if (loadOptions.filter) {
            loadOptions.filter = [baseFilter, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilter;
          }
        }
        if (orderBy) {
          loadOptions.sort = orderBy;
        }
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }
  getDataSourceForecastPersonale(
    baseFilter: any,
    orderBy: any = null,
  ): CustomStore {
    const url = `${this.apiController}/getForecastPersonale`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          if (loadOptions.filter) {
            loadOptions.filter = [baseFilter, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilter;
          }
        }
        if (orderBy) {
          loadOptions.sort = orderBy;
        }
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getDataSourceForecastAssetDoc(
    baseFilter: any,
    orderBy: any = null,
  ): CustomStore {
    const url = `${this.apiController}/getForecastAssetDoc`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          if (loadOptions.filter) {
            loadOptions.filter = [baseFilter, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilter;
          }
        }
        if (orderBy) {
          loadOptions.sort = orderBy;
        }
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  private readonly apiController: string = 'class';
  constructor(private api: HttpService) {}

  getIntegrity(data): Observable<any>
  {
    return this.api.post( `${this.apiController}/report-asset-elementare`,data);
  }

  changeCantiere(idCantiere:number,tipo:string)
  {
    const data = {
      idCantiere:idCantiere,
      tipo:tipo
    }
    return this.api.post( `${this.apiController}/changeCantiere`,data);
  }

  getData(nameClass: string) {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/${nameClass}/cards`).toPromise();
  }
  getBreadcrumb(
    nameClass: string,
    idCard: number,
    campoLookupTipo: string = '',
  ): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    const params = {
      campoLookupTipo: campoLookupTipo,
    };
    return this.api.get(
      `${this.apiController}/${nameClass}/getBreadcrumb/${idCard}`,
      { params: params },
    );
  }
  getDataSourceDevExp(
    nameClass: string,
    baseFilter: any,
    isTree: boolean = false,
    treeRoot = null,
    sortField=null,
  ) {
    nameClass = nameClass.replace(/"/g, '');
    let url = `${this.apiController}/${nameClass}/cardsExp`;
    const urlUpdate = `${this.apiController}/${nameClass}/cardsExpUpdate`;
    const urlDelete = `${this.apiController}/${nameClass}/cards`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          const baseFilterC = this.calculateExpression(
            baseFilter,
            this.baseFunction(),
          );

          if (loadOptions.filter) {
            loadOptions.filter = [baseFilterC, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilterC;
          }
        }
        if (!loadOptions.sort) {
          if (sortField) {
            loadOptions.sort = [{ selector: sortField, desc: false },{ selector: 'Description', desc: false }];
          } else {
            // ORDINAMENTO DI DEFAULT
            loadOptions.sort = [{ selector: 'Description', desc: false }];

          }

        }
        const queryParams = [];
        if (treeRoot) {
          queryParams.push('treeRoot=' + treeRoot);
        }
        if (isTree) queryParams.push('isTree=true');

        const queryParamString = queryParams.join('&');

        url = url + (queryParamString ? '?' + queryParamString : '');
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      update: (key, values) => {
        const params = {
          key: key,
          values: values,
        };
        return this.api
          .post(urlUpdate, params)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      remove: (key) => {
        return this.api
          .delete(`${urlDelete}/${key}/`)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  getDataSourceDevExpNoFilter(
    nameClass: string,
    baseFilter: any,
    isTree: boolean = false,
    treeRoot = null,
    sortField=null,
  ) {
    nameClass = nameClass.replace(/"/g, '');
    let url = `${this.apiController}/${nameClass}/cardsExpNoFilter`;
    const urlUpdate = `${this.apiController}/${nameClass}/cardsExpUpdate`;
    const urlDelete = `${this.apiController}/${nameClass}/cards`;

    const dataSource = new CustomStore({
      key: 'Id',
      load: (loadOptions: any) => {
        if (baseFilter) {
          const baseFilterC = this.calculateExpression(
            baseFilter,
            this.baseFunction(),
          );

          if (loadOptions.filter) {
            loadOptions.filter = [baseFilterC, 'and', loadOptions.filter];
          } else {
            loadOptions.filter = baseFilterC;
          }
        }
        if (!loadOptions.sort) {
          if (sortField) {
            loadOptions.sort = [{ selector: sortField, desc: false },{ selector: 'Description', desc: false }];
          } else {
            // ORDINAMENTO DI DEFAULT
            loadOptions.sort = [{ selector: 'Description', desc: false }];

          }

        }
        const queryParams = [];
        if (treeRoot) {
          queryParams.push('treeRoot=' + treeRoot);
        }
        if (isTree) queryParams.push('isTree=true');

        const queryParamString = queryParams.join('&');

        url = url + (queryParamString ? '?' + queryParamString : '');
        return this.api
          .post(url, loadOptions)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      update: (key, values) => {
        const params = {
          key: key,
          values: values,
        };
        return this.api
          .post(urlUpdate, params)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
      remove: (key) => {
        return this.api
          .delete(`${urlDelete}/${key}/`)
          .toPromise()
          .then((data) => {
            return data;
          });
      },
    });

    return dataSource;
  }

  deleteWizardData(id,className)
  {
    className = className.replace(/"/g, '');
    const url = `${this.apiController}/${className}/cards/${id}`;
    return this.api.delete(url);
  }

  getAttributeList(nameClass: string)
  {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiController}/getAttributeForm/${nameClass}`;
    return this.api.get(url);
  }

  getCard(nameClass: string)
  {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiController}/${nameClass}/cards`;
    return this.api.get(url);
  }

  getCardFiltered(nameClass: string,filterField:string,filterValue:any)
  {
    var filter = "?filter="+filterField+"%3Aeq%3A"+ filterValue
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiController}/${nameClass}/cards${filter}`;
    return this.api.get(url);
  }

  getDataSourceAsItem(nameClass: string, baseFilter: any): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    const url = `${this.apiController}/${nameClass}/cardsExp`;
    return this.api.post(url, { filter: baseFilter });
  }
  getDataSource(nameClass: string) {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/${nameClass}/cards`,
    );
  }

  getDataReference(domainName: string, currentValue: any, filter: any) {
    domainName = domainName.replace(/"/g, '');
    const loadOptions: any = {};
    if (filter) loadOptions.filter = filter;
    return this.api
      .post(
        `${this.apiController}/reference/${domainName}/${currentValue}`,
        loadOptions,
      )
      .toPromise()
      .then((res) => {
        return res.status == 'success' ? res.data : null;
      });
  }

  getInverseClass(otherClass, domainName) {
    domainName = domainName.replace(/"/g, '');

    return this.api.get(
      `${this.apiController}/${otherClass}/inverse/${domainName}`,
    );
  }

  getClass(nameClass: string): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');

    return this.api.get(`${this.apiController}/getAttributeList/${nameClass}`);
  }
  getAttribute(nameClass: string): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/getAttributeListAll/${nameClass}`,
    );
  }
  getAllClass(): Observable<any> {
    return this.api.get(`${this.apiController}`);
  }
  getClassTree(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getClassTree/${nameClass}`);
  }

  getClassTreeMany(listClass: string[]): Observable<any> {
    const request = [];
    listClass.forEach((element) => {
      request.push(
        this.api.get(`${this.apiController}/getClassTree/${element}`),
      );
    });
    return forkJoin(request);
  }

  getClassForm(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getAttributeForm/${nameClass}`);
  }

  // CRUD SCHEDA
  getDettagliScheda(nameClass: string = '', id: number): Observable<any> {
    if (!id) return of({data: {}})
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/${nameClass}/cards/${id}`);
  }

  getComputed(
    nameClass: string = '',
    referenceValue: any,
    referenceDomain: string,
    initClass: string,
  ): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/computed/${referenceValue}/${referenceDomain}/${initClass}`,
    );
  }

  updateDettagliScheda(nameClass: string = '', model: any): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');

    return this.api.post(`${this.apiController}/${nameClass}/cards`, model);
  }
  deleteScheda(nameClass: string = '', id: number): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.delete(`${this.apiController}/${nameClass}/cards/${id}`);
  }

  // RELAZIONI
  getDomains(nameClass: string = '', idCard: number = 0): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/domains` +
        (idCard > 0 ? '?idCard=' + idCard : ''),
    );
  }
  getRelations(
    nameClass: string = '',
    id: number,
    domainName: string = '',
  ): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    domainName = domainName.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/cards/${id}/relation/${domainName}`,
    );
  }

  getCardsForDomain(
    nameClass: string = '',
    originId: number,
    domainName: string = '',
    direction: string = 'direct',
    type:string='N:N',
  ): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    domainName = domainName.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/cardsForDomain/${domainName}/${direction}/${originId}/${type}`,
    );
  }

  getAssetTarget(assetId, targetId): Observable<any> {
    return this.api.get(
      `${this.apiController}/AssetTarget/cards?filter=Asset:eq:${assetId}&filter=Target:eq:${targetId}`,
    );
  }
  getAssetTargetAzienda(assetId, targetId): Observable<any> {
    return this.api.get(
      `${this.apiController}/AssetTarget/cards?filter=Azienda:eq:${assetId}&filter=Target:eq:${targetId}`,
    );
  }
  getAssetGestione(assetId: any): Observable<any> {
    return this.api.get(`asset/${assetId}/gestione`);
  }
  getAssetGestioneAzienda(assetId: any): Observable<any> {
    return this.api.get(`asset/${assetId}/gestioneAzienda`);
  }

  // REPORT
  getReportClass(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/${nameClass}/report`);
  }
  getDomandeVerifica(idVerifica: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/getDomandeVerifica/${idVerifica}`,
    );
  }
  getDomande(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/getDomande/${id}`);
  }
  getHeaderFooterScheda(idScheda: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/getHeaderFooterScheda/${idScheda}`,
    );
  }
  getRuoliFromFunzione(idFunzione: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/getRuoliFromFunzione/${idFunzione}`,
    );
  }
  // ALLEGATI
  getAllegatiClass(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/${nameClass}/allegati`);
  }
  getAllegati2Class(
    nameClass: string = '',
    model: any,
    baseFilter: any,
  ): Observable<any> {
    let baseFilterC = null;
    if (baseFilter) {
      baseFilterC = this.calculateExpression(baseFilter, model);
    }
    const loadOptions: any = {};
    if (baseFilterC) loadOptions.filter = baseFilterC;

    nameClass = nameClass.replace(/"/g, '');
    return this.api.post(
      `${this.apiController}/${nameClass}/allegati2`,
      loadOptions,
    );
  }

  getAllegati(nameClass: string = '', idCard: number): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/getAllegati/${idCard}`,
    );
  }
  downloadAllegato(key: string): Observable<any> {
    return this.api.get(`${this.apiController}/downloadAllegato/${key}`, {
      responseType: 'blob',
    });
  }
  previewAllegato(key: string): Observable<any> {
    return this.api.get(`${this.apiController}/previewAllegato/${key}`);
  }

  // VERIFICA SCHEDA
  verificaScheda(nameClass: string = '', idCard: number): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(
      `${this.apiController}/${nameClass}/verificaCard/${idCard}`,
    );
  }

  getService(url: any, data: any): Observable<any> {
    return this.api.post(`${this.apiController}/${url}`, data);
  }

  // WIDGET DASHBOARD
  getElementClass(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/${nameClass}/cards`);
  }

  baseFunction() {
    return {
      today: moment().format('YYYY-MM-DD'),
    };
  }

  // --- Ciccio Path Mansione ---
  getRecursiveFunction(ruoloId: number): Observable<any> {
    return this.api.get(`${this.apiController}/getAlberoDaRuolo/${ruoloId}`)
  }
  associaRifiuti( model: any): Observable<any> {

    return this.api.post(`${this.apiController}/associaRifiuti`, model);
  }
  // --- Ciccio Aggiorno in campo ChiusuraForzata ---
  updateChiusuraForzata(idVerifica: number) : Observable<any>{
    return this.api.post(`${this.apiController}/updateChiusuraForzata/${idVerifica}`, true);
  }

  //--- Ciccio Get Asset idClass
  getAssetClassDistinct() {
    return this.api.get(`${this.apiController}/getAssetClassDistinct`);;
  }

  //--- Ciccio Get Nominativi Gestione
  getNominativiGestione(idGestione: number) {
    return this.api.get(`${this.apiController}/getNominativiGestione/${idGestione}`)
  }
}
