import { Component, OnInit, Injectable, Input, NgZone, HostBinding } from '@angular/core';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'ngx-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})

export class ModalConfirmComponent implements OnInit {

  @Input('context.id') id: number;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;

  constructor(
    protected ref: NbDialogRef<ModalConfirmComponent>,
    iconsLibrary: NbIconLibraries,
    private ngZone: NgZone
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit() {
    this.titleModal = this.titleModal || 'Sei sicuro di voler procedere?';
    this.loadData();
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.ref.close(1);
  }


  loadData(): void {
  }


  refresh() {

  }

}
