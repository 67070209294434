import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import {
  NbDialogRef,
  NbIconLibraries,
  NbDialogService,
  NbToastrService,
} from '@nebular/theme';
import { GenericClassService } from '../../@core/backend/common/services/genericClass.service';
import { FormClassComponent } from '../form-class/form-class.component';
import { ModalClassEditComponent } from '../modal-class-edit/modal-class-edit.component';
import { RelationsService } from '../../@core/backend/common/services/relations.service';
import { DxDataGridComponent, DxTreeListComponent } from 'devextreme-angular';
import { isEmpty, filter } from 'rxjs/operators';
import Utils from '../../@core/utils/utils';
import CheckBox from 'devextreme/ui/check_box';

@Component({
  selector: 'ngx-modal-generic',
  templateUrl: './modal-relation-select.component.html',
  styleUrls: ['./modal-relation-select.component.scss'],
})
export class ModalRelationSelectComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })  dataGrid: DxDataGridComponent;

  @ViewChild(DxTreeListComponent, { static: false })  treeGrid: DxTreeListComponent;
  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;
  searchText: string;

  selectDomain: any;
  columns: any;
  className: string;
  originId: number;
  currentCardinality: string;

  reference: string;
  referenceId: number;

  settings = {
    columns: {},
  };

  data = [];
  itemsMenuClassAdd = [];
  currentSelectLine: any = null;
  selectedRowKeys: any;
  rowLocked: boolean;
  filterSelectVisible: boolean;
  isTree: any;
  selectFilter: any;
  selctable: any;
  constructor(
    protected ref: NbDialogRef<ModalRelationSelectComponent>,
    iconsLibrary: NbIconLibraries,
    private ngZone: NgZone,
    private classService: GenericClassService,
    private relationService: RelationsService,
    private dialogService: NbDialogService,
    private toasterService: NbToastrService,
  ) {
    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', {
      packClass: 'far',
      iconClassPrefix: 'fa',
    });
    iconsLibrary.registerFontPack('font-awesome', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });
  }

  ngOnInit() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = () => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
    this.loadColumns();
    this.loadData();
    this.filterSelectVisible = true;
  }

  canShowForm() {
    return this.currentSelectLine != null;
  }
  cardChange(formData) {
    this.currentSelectLine.isChangedSelected = true;
    this.currentSelectLine.Model = formData;
  }
  cancel() {
    this.ref.close();
  }

  submit() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    const request = [];
    instance.saveEditData().then((res) => {
      this.data.forEach((element) => {
        if (element.selected != element.originalSelected) {
          let reqElement = {
            Id: element.IdMap || 0,
            IdDomain: this.selectDomain.name,
            IdClass1:
              this.selectDomain.direction == 'direct'
                ? this.selectDomain.source
                : element.IdClass,
            IdObj1:
              this.selectDomain.direction == 'direct'
                ? this.originId
                : element.Id,
            IdClass2:
              this.selectDomain.direction == 'direct'
                ? element.IdClass
                : this.selectDomain.destination,
            IdObj2:
              this.selectDomain.direction == 'direct'
                ? element.Id
                : this.originId,
            Status: element.selected ? 'A' : 'N',
          };
          const model = {};
          for (const key of Object.keys(element)) {
            if (key.startsWith('Model_')) {
              const item = element[key];
              model[key.replace('Model_', '')] = item;
            }
          }
          reqElement = { ...reqElement, ...model };
          request.push(reqElement);
        }
      });

      this.relationService
        .updatebatch(this.selectDomain.name, request)
        .subscribe(
          (response) => {
            if (response.status != 'success') {
              this.toasterService.danger(
                '',
                `ERRORE: ${response.status} - ${response.data}`,
              );
            } else {
              // SUCCESS CLOSE MODAL
              this.ref.close(true);
            }
          },
          (error) => {
            this.toasterService.danger(
              '',
              `ERRORE: ${error.error.status} - ${error.error.data}`,
            );
          },
        );
    });
  }

  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return (this.style = {
      width: this.width + 'px',
      height: this.height + 'px',
    });
  }
  styleNormal(): object {
    return (this.style = {
      width: this.width * 0.8 + 'px',
      height: this.height * 0.8 + 'px',
    });
  }

  loadData(): void {
    let direction: string = 'inverse';

    let classOrigin = this.selectDomain.source;
    if (this.className == classOrigin) {
      classOrigin = this.selectDomain.destination;
      direction = 'direct';
    }
    this.data = [];
    this.itemsMenuClassAdd = [];
    this.loading = true;
    this.selectedRowKeys = [];
    this.classService
      .getCardsForDomain(
        classOrigin,
        this.originId,
        this.selectDomain.name,
        direction,
        this.selectDomain.type
      )
      .subscribe((res) => {
        if (res.status == 'success') {
          this.data = res.data.cards.list;
          if(res.data.classDescendants[0].className == "MansioneTarget")
          {
            this.data = this.data.filter(element => element.Mansione == null || element.selected == true )
          }
          this.isTree = res.data.isTree;
          this.selectFilter = null;
          try {
            this.selectFilter = JSON.parse(res.data.selectFilter);
          } catch (error) {

          }

          if (this.data && this.data.length) {
            this.selectedRowKeys = this.data
              .filter((z) => z.selected)
              .map((x) => x.IdMap);
            this.data = this.data.map((x) => {
              x.IdMapForGrid = x.IdMap || x.Id;
              x.originalSelected = x.selected;
              return x;
            });
          }

          if (this.selectFilter && this.selectFilter.length > 0) {
            const selectableFilter = this.selectFilter;
            const storeSelectFilter = this.classService.getDataSourceDevExp(
              classOrigin,
              selectableFilter,
            );
            storeSelectFilter.on('loaded', this.loadedSelectable.bind(this));
            storeSelectFilter.load();
          }

          this.itemsMenuClassAdd = res.data.classDescendants.sort((a, b) =>
            a.labelName.toLowerCase() > b.labelName.toLowerCase() ? 1 : -1,
          );
        }
        this.loading = false;
      });
  }
  loadColumns(): void {
    console.log('LOAD COLUMNS...');
    let foundObject = false;
    // AGGIUNGO CAMPI DELLA CLASSE
    for (const key of Object.keys(this.columns.viewColumns)) {
      const item = this.columns.viewColumns[key];
      if (key.startsWith('ObjValue.')) {
        item.allowEditing = false;
        this.settings.columns[key.replace('ObjValue.', '')] = item;
        foundObject = true;
      }
    }
    if (!foundObject) {
      // Se non ci sono colonne dell'oggetto aggiungo la descrizione
      this.settings.columns['Description'] = {
        title: 'Descrizione',
        type: 'string',
        key: 'Description',
        index: -1,
        allowEditing: false,
      };
    }
    // AGGIUNGO CAMPI DELLA RELAZIONE
    for (const key of Object.keys(this.columns.viewColumns)) {
      const item = this.columns.viewColumns[key];
      if (!key.startsWith('ObjValue.') && item.class != 'autosize150') {
        item.key = 'Model_' + key;
        item.allowEditing = true;
        this.settings.columns[item.key] = item;
      }
    }

    const cardPart = this.selectDomain.cardinality.split(':');
    if (this.selectDomain.direction == 'direct') {
      this.currentCardinality = cardPart[1];
    } else {
      this.currentCardinality = cardPart[0];
    }
    console.log('VERSO --->' + this.currentCardinality);
  }
  loadedSelectable(res) {

    const selectObj = res.data.map((x) => x.Id);
    const selectableMap = this.data.filter(x => selectObj.includes( x.Id ));
    this.selctable = selectableMap.map((x) => x.IdMapForGrid);
  }
  refresh() {
    this.loadData();
  }

  createClass(event) {
    const selectedClass = event.itemData ? event.itemData : event;
    console.log(event);
    this.dialogService
      .open(ModalClassEditComponent, {
        context: {
          titleModal: 'Modifica scheda',
          className: selectedClass.className,
          cardId: null,
          reference: this.reference,
          referenceId: this.referenceId,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((resModal) => {
        if (resModal) {
          this.refresh();
        }
      });
  }

  // NEW DATAGRID
  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === 'searchPanel') {
        item.location = 'before';
      }
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'selectall',
        hint: 'Visualizza selezionati',
        onClick: this.showSelectedDataGrid.bind(this),
      },
    }, {
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'unselectall',
        hint: 'Azzera filtri',
        onClick: this.clearFilterDataGrid.bind(this),
      },
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        hint: 'aggiorna',
        onClick: this.refreshDataGrid.bind(this),
      },
    });
  }
  refreshDataGrid() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    instance.refresh();
  }
  showSelectedDataGrid() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    const selected = instance.getSelectedRowKeys();


    const selectFilter = [];
    selectFilter.push(['1', '=', '1']);
    selected.forEach(element => {
      selectFilter.push('or');
      selectFilter.push(['IdMapForGrid', '=', element]);
    });


    instance.filter(selectFilter);
    this.filterSelectVisible = false;
    instance.repaint();
  }
  clearFilterDataGrid() {
    const instance = this.dataGrid
      ? this.dataGrid.instance
      : this.treeGrid.instance;
    instance.clearFilter();
    this.filterSelectVisible = true;
    instance.repaint();
  }
  // Preserve original property order
  originalOrder = (): number => {
    return 0;
  }
  lookup_calculateCellValue(row) {
    const column = this as any;
    const dataField = column.dataField;

    const fieldName = dataField.replace('_Description', '').replace('_', '');
    let label = '';
    if (row[dataField]) {
      if (row['_' + fieldName + '_IconFont']) {
        if (row['_' + fieldName + '_IconColor']) {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" style="color:` +
            row['_' + fieldName + '_IconColor'] +
            `"></span>&nbsp;&nbsp;`;
        } else {
          label +=
            `<span class="` +
            row['_' + fieldName + '_IconFont'] +
            `" ></span>&nbsp;&nbsp;`;
        }
      }
      if (row['_' + fieldName + '_TextColor']) {
        label +=
          `<span style="color:` +
          row['_' + fieldName + '_TextColor'] +
          `" >${row[dataField]}</span>`;
      } else {
        label += `<span>${row[dataField]}</span>`;
      }
      column.encodeHtml = false;
    }
    return label;
  }
  reference_calculateCellValue(row) {
    const column = this as any;
    const dataField = column.dataField;

    const fieldName = dataField.replace('_Description', '').replace('_', '');

    let label = '';
    if (row[dataField]) {
      label = `<span class="">${row[dataField]}</span>`;
      column.encodeHtml = false;
    }
    return label;
  }
  calculateCellValueDefault(row) {
    const column = this as any;
    return column.defaultCalculateCellValue(row);
  }
  onSelectionChanged(e) {
    if (e.currentDeselectedRowKeys && e.currentDeselectedRowKeys.length > 0) {
      this.data
        .filter((x) => e.currentDeselectedRowKeys.includes(x.IdMapForGrid))
        .forEach((element) => {
          element.selected = false;
        });
    }

    let selected = e.currentSelectedRowKeys;
    if (this.selectFilter) selected = selected.filter(x => this.selctable.includes(x));
    if (selected && selected.length > 0) {

      this.data
        .filter((x) => selected.includes(x.IdMapForGrid))
        .forEach((element) => {
          element.selected = true;
        });
    }
    // VISIBILITA

      this.selectedRowKeys = this.data
      .filter((z) => z.selected)
      .map((x) => x.IdMapForGrid);
  }
  onEditingStart(e) {
    if (!e.data.selected) {
      e.cancel = true;
    }
  }
  onSaved(e) {
    if(e && e.data) e.data.originalSelected = null;
  }
}
