import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpService } from '../api/http.service';
import { EnvService } from './env.service';

@Injectable()
export class ProcessService {
  private readonly apiController: string = 'process';
  private readonly urlConductor: string = '';

  constructor(private api: HttpService,  private http: HttpClient, private env: EnvService) {
    this.urlConductor = this.env.conductorUrl;
    console.log(`CONNECTED TO ${this.urlConductor}`);
  }

  getProcessTree(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getProcessTree`);
  }

  getDataSource(nameClass: string) {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/${nameClass}/cards`,
    );
  }

  getDataReference(domainName: string): Observable<any> {
    domainName = domainName.replace(/"/g, '');
    return this.api.get(`${this.apiController}/reference/${domainName}`);
  }

  getClass(nameClass: string): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getAttributeList/${nameClass}`);
  }
  getAllClass(): Observable<any> {
    return this.api.get(`${this.apiController}`);
  }

  getClassForm(nameClass: string = ''): Observable<any> {
    nameClass = nameClass.replace(/"/g, '');
    return this.api.get(`${this.apiController}/getAttributeForm/${nameClass}`);
  }

  // Conductor

  getStatusLookup() {
    return [
      { Id: 'Runnable', Description: 'Esecuzione' , 'color': 'info', colorCircle: '#007bff'},
      { Id: 'Complete', Description: 'Completo' , 'color': 'success', colorCircle: '#78C000'},
      { Id: 'Suspended', Description: 'Sospeso' , 'color': 'warning', colorCircle: '#ffc107'},
      { Id: 'Terminated', Description: 'Cancellato', 'color': 'danger', colorCircle: '#dc3545' },
    ];
  }
  getStatusArray() {
    const data = this.getStatusLookup();
    return Object.assign({}, ...data.map(({Id, Description, color, colorCircle}) => ({[Id]: {'Description': Description, 'color': color, 'colorCircle': colorCircle}})));
  }

  getStatusStepLookup() {
    return [
      { Id: 0, Description: 'Legacy' },
      { Id: 1, Description: 'Pending' },
      { Id: 2, Description: 'Running' },
      { Id: 3, Description: 'Complete' },
      { Id: 4, Description: 'Sleeping' },
      { Id: 5, Description: 'WaitingForEvent' },
      { Id: 6, Description: 'Failed' },
      { Id: 7, Description: 'Compensated' },
      { Id: 8, Description: 'Cancellato' },
    ];
  }


  gettWorkflow(definitionId): Observable<any> {
    return this.http.get(
      `${this.urlConductor}workflow/${definitionId}`, {
        headers: {'Accept': 'application/json'},
      },

      );
  }

  getWorkflows() {
    const ds = AspNetData.createStore({
      key: 'workflowId',
      loadUrl: this.urlConductor + 'Workflow',
      loadMethod: 'GET',
      deleteUrl: this.urlConductor + 'Workflow',
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.headers = { Accept: 'application/json' };
      },

    });

    return ds;
  }
  startWorkflow(definitionId, data: any, instanceDecription): Observable<any> {
    data.instanceDecription = instanceDecription;
    return this.http.post(`${this.urlConductor}workflow/${definitionId}`, data, {
      headers: {'Accept': 'application/json'},
    });
  }
  suspendWorkflow(workflowId): Observable<any> {
    return this.http.put(`${this.urlConductor}workflow/${workflowId}/suspend`, {});
  }
  resumeWorkflows(workflowId): Observable<any> {
    return this.http.put(`${this.urlConductor}workflow/${workflowId}/resume`, {});
  }


  getToken(workflowId): Observable<any> {
    return this.api.get(`${this.apiController}/getByWorkflowId/${workflowId}`, {});
  }

  getActivity(name, workflowId): Observable<any> {
    return this.http.get(`${this.urlConductor}Activity/${name}`, {
      headers: {'Accept': 'application/json'},
    });
  }
  deleteActivity(token): Observable<any> {
    return this.http.delete(`${this.urlConductor}Activity/${token}`, {
      headers: {'Accept': 'application/json'},
    });
  }
  updateProcess(workflowId, data): Observable<any> {
    return this.api.post(`${this.apiController}/updateByWorkflowId/${workflowId}`, data);
  }
  sendActivity(token, data): Observable<any> {
    return this.http.post(`${this.urlConductor}Activity/success/${token}`, data , {
      headers: {'Accept': 'application/json'}, responseType: "arraybuffer"
    });
  }
}
