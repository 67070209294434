import { ModalDetailComponent } from './modal-detail/modal-detail.component';
import { ListComponent } from './list/list.component';
import { SegnalazioniComponent } from './segnalazioni.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WidgetStatisticheStatiComponent } from './statistiche/widget-statistiche-stati/widget-statistiche-stati.component';


const routes: Routes = [{
  path: '',
  component: SegnalazioniComponent,
  children: [
      {
          path: 'list',
          component: ListComponent
      },
      {
        path: 'modal-detail',
        component: ModalDetailComponent
    },
  ],

}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegnalazioniRoutingModule { }
