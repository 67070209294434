import { LookupService } from './../../@core/backend/common/services/lookup.service';
import { GenericClassService } from './../../@core/backend/common/services/genericClass.service';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppSettingsService } from '../../@core/backend/common/services/appsettings.service';

@Component({
  selector: 'ngx-modal-idoneita',
  templateUrl: './modal-idoneita.component.html',
  styleUrls: ['./modal-idoneita.component.scss']
})
export class ModalIdoneitaComponent implements OnInit {

  @Input('context.id') idCard: number;
  mansioni: any;
  lookupIdoneita: any[];
  utente:any;
  constructor(
    private spinner: NgxSpinnerService,
    public ref: NbDialogRef<ModalIdoneitaComponent>,
    public classService: GenericClassService,
    public lookupService: LookupService,
    public settingService: AppSettingsService
  ) { }
  //class/PersonaleInterno/cards/8956/relation/Map_Personale chiamata per le sue mansioni
  ngOnInit() {
    this.spinner.show('loadingIdoneita');
    this.lookupService.getValoriLookup("Idoneita").subscribe((res) => {
      this.lookupIdoneita = [];
      res.data.list.forEach(element => {
        this.lookupIdoneita.push(element)
      });
      //chiamata a servizio
      this.classService.getRelations("PersonaleInterno", this.idCard, "Personale").subscribe((res) => {

        res.data.forEach(element => {

          element.ObjValue.Idoneita = !element.ObjValue.Idoneita ? 'W' : element.ObjValue.Idoneita
          element.ObjValue.originalIdoneita = element.ObjValue.Idoneita;
        });
        this.mansioni = res.data.map(x => { return x.ObjValue });
        this.utente = res.data;
        this.spinner.hide('loadingIdoneita');
      })
    });

  }
  cancel() {
    this.ref.close();
  }

  submit() {
    let toSave = [];
    this.mansioni.forEach(element => {
      if (element.originalIdoneita != element.Idoneita) {
        toSave.push(element);
      }
    });
    if (toSave && toSave.length > 0) {
      let updateList = [];
      let nonIdoneeList=[];
      toSave.forEach(element => {
        if (element.Idoneita==1006110) {
          // non idonea
          nonIdoneeList.push(element);
        }
        updateList.push(this.classService.updateDettagliScheda(element.IdClass, element))
      });
      if (nonIdoneeList && nonIdoneeList.length > 0) {
        // creazione scheda 06
        console.log("CREAZIONE SCHEDA 06 - NC")
        this.generaTemplate(nonIdoneeList)
      }

      if (updateList && updateList.length > 0) {
        forkJoin(updateList).subscribe(() => {
          this.ref.close(1);
        });
      } else {
        this.ref.close();
      }

    } else {
      this.ref.close();
    }

  }


  generaTemplate(nonIdonee) {
    const domande = nonIdonee.map((mansione:any,i:any)=>{
      const item = {
        'Code': '',
        'Description': mansione.Description,
        'Status': 'A',
        'Peso' : 1,
        'Target': null,
        'Scheda': 0,
        'Order': i+1
      }
      return item;
    });
    const data = {
      'data': {
        'Description': "Non idoneità generata per l'utente " +nonIdonee[0]._Persona_Description,
        'TipoTemplate': 2,
        'TipoDisplay': 'Template non idoneità',
        'AssetTipologia': null,
        'AssetTipologiaSub': null,
        'TipoBase': "06 - Scheda Tipo 6 - Chiusura NC",
        'Ambito': null,
      },
      'relations_insert': {
        'Verifica_Domande': domande,

      },
      'relations_duplicate': {
        'Verifica_Domande': {},
      },
    };

    this.classService.duplicate('VerificaScheda', 301319, data).subscribe((res) => {
      if (res.status == 'success') {
        this.getGestioneNonIdonee(res,nonIdonee[0]._Persona_Id)
      }
    });
  
  }

  getGestioneNonIdonee(oldRes,idPersona) {
    this.settingService.getSettings().subscribe((res:any)=>{
      this.classService.getCardFiltered("Gestione","Id",res.gestioneInidoneita).subscribe((resGest:any)=>{
        if(resGest.data.list && resGest.data.list.length > 0) {
          let _slot = resGest.data.list[0];
          const gestione = {
            Preposto: _slot.Ruolo1,
            Responsabile: _slot.Ruolo2,
            Esecutore: _slot.Ruolo3
          }
          this.generaVerifica(oldRes,gestione,idPersona)
        }
      })
    })
  }

  generaVerifica(data,gestione,idPersona) {
    const obj = {
      Description: data.data.Description,
      Asset: null,
      Azienda: null,
      Scheda: data.data.Id,
      Data: new Date(),
      periodicity_type: null,
      periodicity: null,
      TipoEsecutore: 366914,
      EsecutoreEsterno: null,
      Responsabile: gestione.Responsabile,
      Vigilanza:  gestione.Preposto,
      Esecutore: gestione.Esecutore,
      AssetGestione: null,
      Tipo: data.data.Tipo,
      Ambito: null,
      Cantiere:null,
      Persona: idPersona
    };
    this.classService
      .updateDettagliScheda('Verifica', obj)
      .subscribe((response) => {
        if (response.status == 'success') {
          console.log("Scheda di verifica creata con successo")
        }
      });
  }

}
