import { Component, OnInit, EventEmitter, NgZone, ViewChild } from '@angular/core';

import { concat } from 'rxjs';
import { NbDialogRef, NbIconLibraries } from '@nebular/theme';
import {  MineTypeEnum,  HttpClientUploadService, FileItem, InputFileOptions, DropTargetOptions } from '@wkoza/ngx-upload';
import { Method } from '@wkoza/ngx-upload/utils/configuration.model';


@Component({
  selector: 'ngx-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {


  loading: boolean;
  innerWidth: number;
  maximize: boolean = false;
  width: any;
  height: any;
  style: Object;
  titleModal: string;
  multiple:boolean = false;
  accept:MineTypeEnum[]=[];
  

  serviceMethod: Method="POST";
  serviceUrl: string="ngx_upload_mock";
  serviceParams: string="";

  @ViewChild('ourForm',{static:true}) ourForm;
  
  optionsInput: InputFileOptions;
  optionsDrop: DropTargetOptions;

  constructor(protected ref: NbDialogRef<ModalUploadComponent>,iconsLibrary: NbIconLibraries,
    private ngZone: NgZone,public uploader: HttpClientUploadService) {
   

    this.loading = false;
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'far', iconClassPrefix: 'fa' });
    iconsLibrary.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }
  
  ngOnInit() {
    this.optionsInput= {
      multiple: this.multiple,
      accept: this.accept
    };
    this.optionsDrop= {
      color: 'dropZoneColor',
      colorDrag: 'dropZoneColorDrApplicationag',
      colorDrop: 'dropZoneColorDrop',
      multiple: this.multiple,
      accept:this.accept
    };
    
    this.uploader.queue = [];
    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
        console.log('file canceled: ' + data.file.name);

    });

    this.uploader.onDropError$.subscribe(
      (err) => {
        console.log('error during drop action: ', err);
      });

   

    this.uploader.onSuccess$.subscribe(
      (data: any) => {
        console.log(`upload file successful`,data);
      }
    );

    this.uploader.onAddToQueue$.subscribe(
      () => {
        console.log(`reset of our form`);
        this.ourForm.reset();
        

      }
    )

    this.titleModal = 'Caricamento file';
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      });
    };
  }
  cancel() {
    this.ref.close();
  }
  resize() {
    if (this.maximize) {
      this.maximize = false;
    } else {
      this.maximize = true;
    }
  }

  styleMax(): object {
    return this.style = { 'width': this.width + "px", 'height': this.height + "px" };
  }
  styleNormal(): object {

    return this.style = { 'width': (this.width * 0.8) + "px", 'height': (this.height * 0.8) + "px" };
  }


  upload(item: FileItem) {
    item.upload({
      method: this.serviceMethod,
      url: this.serviceUrl
    },
    {
      params:this.serviceParams
    });
  }

  uploadAll() {
    this.uploader.uploadAll({
      method: this.serviceMethod,
      url: this.serviceUrl
    },
    {
      params:this.serviceParams
    })  
  }


  makeThumbnail(item: FileItem) {
    const reader = new FileReader();
    // read the image file as a data URL.
    reader.readAsDataURL(item.file);

  }

  activeRemoveAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.uploader.queue.some((item: FileItem) => item.uploadInProgress);
  }
 

   

    

}
